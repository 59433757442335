
const token_name = 'sb-tkn';
const user_name = 'sb-user';
const user_data_name = 'sb-user-data';

var Storage = (function () {

  var getToken = function () {
    var tkn = localStorage.getItem(token_name);
    return tkn
  };

  var getUser = function () {
    var user = JSON.parse(localStorage.getItem(user_name));
    return user
  };

  var getUserData = function () {
    var user_data = JSON.parse(localStorage.getItem(user_data_name));
    return user_data
  };

  
  var setToken = function (data) {
    localStorage.setItem(token_name, data);
  };
  var setUser= function (data) {
    localStorage.setItem(user_name, JSON.stringify(data));
  };
  var setUserData = function (data) {
    localStorage.setItem(user_data_name, JSON.stringify(data));
  };

  var logout = function() {
    localStorage.removeItem(token_name);
    localStorage.removeItem(user_name);
    localStorage.removeItem(user_data_name);
  };




  return {
    setToken: setToken,
    getToken: getToken,

    getUser: getUser,
    setUser: setUser,

    getUserData: getUserData,
    setUserData: setUserData,

    logout: logout,
    
  }

})();

export default Storage;