import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from '../../utils/helpers/date.helper';

const OfferItem = ({ data, onItemDetailsClick }) => {


    const itemClicked = () => {
        onItemDetailsClick(data)
    }

    return (

        <div className="col-md-12 ps-3 pe-3">
            <div className="row no-marg bg-wt item_box pt-1 pb-1">

                <div className="col-md-1">
                    <h3 className="icon"><FontAwesomeIcon icon={['fas', 'briefcase']} /></h3>
                </div>

                <div className="col-md-8">
                    <b className="d-block fnt-larg">{data.titre}</b>
                    <b className="fnt-w4 fnt-sm txt-gray">de {getStringDate(data.date_debut)} jusqu'à {getStringDate(data.date_fin)}</b>
                    {/* <b className="float-end fnt-w5">{data.budget} €</b> */}
                    <div className="d-block">
                    {data && data.tech.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}
                    </div>
                </div>

                <div className="col-md-2  dvdr-l-gray ">
                    <b className="d-block fnt-sm">Etat  </b>
                    <b className="d-block "><span class="badge bg-success fnt-w5">{data.etat}</span> </b>


                </div>

                <div className="col-md-1">
                    <button className="box_item_btn fnt-med btn_eff_purple" onClick={itemClicked}>
                        voir plus
                            </button>
                </div>
            </div>

        </div>


    )

}

export default OfferItem;