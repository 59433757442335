import axios from "axios";
import api_url  from '../Config';
import  storage from '../Storage';

class ChatService {

    

    getMyMsg() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/chat/getMyMsg`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getUsersChat() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/chat/getUserChat`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getMsgByUser(id_user) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/chat/getMsgByUser`, {id_user : id_user} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    sendMsg(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/chat/sendit`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }
   

   

  

 
}

export default new ChatService();