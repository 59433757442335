import {
    LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
    CHECKUSER, CHECKUSER_SUCCESS, CHECKUSER_FAIL,
    REGISTER, REGISTER_SUCCESS, REGISTER_FAIL,
    RESEND_MAIL, RESEND_MAIL_SUCCESS, RESEND_MAIL_FAIL,
    VERIF_MAIL, VERIF_MAIL_SUCCESS, VERIF_MAIL_FAIL,
    CHECK_PROFILE, CHECK_PROFILE_SUCCESS, CHECK_PROFILE_FAIL,
    REGISTER_PROFILE, REGISTER_PROFILE_SUCCESS, REGISTER_PROFILE_FAIL,
    REGISTER_FRM , REGISTER_FRM_SUCCESS , REGISTER_FRM_FAIL ,
    REGISTER_EXP , REGISTER_EXP_SUCCESS , REGISTER_EXP_FAIL ,
    UPLOAD_IMG, UPLOAD_IMG_SUCCESS, UPLOAD_IMG_FAIL,
    LOGOUT, CLEAR_AUTH
} from "../actions/ActionTypes";

const initialState = {
    loading: false,
    user: null,
    error: '',
    success_login: false,
    success_register: false,
    success_resend_mail: false,
    success_register_profile: false,
    success_upload_img: false,
    success_check_user: false,
    success_register_frm: false,
    success_register_exp: false,
    success_valid_mail: false,
    success_check_profile: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:

            return {
                ...state,
                loading: true,
                success_login: false
            }
        case LOGIN_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_login: true
            }
        case LOGIN_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_login: false
            }
        case CHECKUSER:

            return {
                ...state,
                loading: true,
                success_check_user: false
            }
        case CHECKUSER_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_check_user: true
            }
        case CHECKUSER_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_check_user: false
            }
        case CLEAR_AUTH:

            return {
                loading: false,
                user: null,
                error: '',
                success_login: false,
                success_register: false,
                success_resend_mail: false,
                success_register_profile: false,
                success_upload_img: false,
                success_check_user: false,
                success_register_frm: false,
                success_register_exp: false,
                success_valid_mail: false,
                success_check_profile: false,
            }
        case REGISTER:

            return {
                ...state,
                loading: true,
                success_register: false,
            }
        case REGISTER_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_register: true,
            }
        case REGISTER_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_register: false,
            }
        case VERIF_MAIL:

            return {
                ...state,
                loading: true,
                success_valid_mail: false,
            }
        case VERIF_MAIL_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_valid_mail: true,
            }
        case VERIF_MAIL_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_valid_mail: false,
            }
        case CHECK_PROFILE:

            return {
                ...state,
                loading: true,
                success_check_profile: false,
            }
        case CHECK_PROFILE_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_check_profile: true,
            }
        case CHECK_PROFILE_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_check_profile: false,
            }
        case RESEND_MAIL:

            return {
                ...state,
                loading: true,
                success_resend_mail: false,
            }
        case RESEND_MAIL_SUCCESS:

            return {
                loading: false,
                error: '',
                success_resend_mail: true,
            }
        case RESEND_MAIL_FAIL:

            return {
                loading: false,
                error: action.payload,
                success_resend_mail: false,
            }
        case REGISTER_PROFILE:

            return {
                ...state,
                loading: true,
                success_register_profile: false,
            }
        case REGISTER_PROFILE_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_register_profile: true,
            }
        case REGISTER_PROFILE_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_register_profile: false,
            }
        case REGISTER_FRM:

            return {
                ...state,
                loading: true,
                success_register_frm: false,
            }
        case REGISTER_FRM_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_register_frm: true,
            }
        case REGISTER_FRM_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_register_frm: false,
            }
        case REGISTER_EXP:

            return {
                ...state,
                loading: true,
                success_register_exp: false,
            }
        case REGISTER_EXP_SUCCESS:

            return {
                loading: false,
                user: action.payload,
                error: '',
                success_register_exp: true,
            }
        case REGISTER_EXP_FAIL:

            return {
                loading: false,
                user: null,
                error: action.payload,
                success_register_exp: false,
            }
        case UPLOAD_IMG:

            return {
                ...state,
                loading: true,
                success_upload_img: false,
            }
        case UPLOAD_IMG_SUCCESS:

            return {
                loading: false,
                error: '',
                success_upload_img: true,
            }
        case UPLOAD_IMG_FAIL:

            return {
                loading: false,
                error: action.payload,
                success_upload_img: false,
            }

        default:
            return state
    }
}
export default reducer;