import React from 'react';
import img_default from '../../assets/img/img_default.png'

const ChatBoxHeader = ({sel_disscution , loading }) => {
    return (
        <div className="col-md-12 chat_dissc_header">
            <div className="row  chat_dissc_user" >
                {sel_disscution &&
                    <>
                        <div className="col-md-4">
                            {sel_disscution.img ?
                                <img src={sel_disscution.img} className="user_img chat_user_item_img" alt="" />
                                :
                                <img src={img_default} className="user_img chat_user_item_img" alt="" />
                            }
                            {sel_disscution.isConnected == true ? <span className="sp_connected"></span> : <span className="sp_deco"></span>}
                        </div>
                        <div className="col-md-7">
                            <b className="d-block txt-purple fnt-w5">{sel_disscution.nom}  {sel_disscution.prenom}</b>
                            <b className="d-block txt-gray fnt-w4">{sel_disscution.role} - {sel_disscution.etat}</b>

                        </div>
                    </>
                }

                {loading &&
                    <div className="col-md-1 txt-ctr">
                        <div className="spinner-border spinner-border-sm ms-auto" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }



            </div>
        </div>
    );
};

export default ChatBoxHeader;