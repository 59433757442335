import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import img_default from '../../../assets/img/img_default.png';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import FormationItem from '../../../ui/items/FormationItem';
import ExperienceItem from '../../../ui/items/ExperienceItem';



const ClientDetails = ({ showP, setShowP, data }) => {


    const renderEtat = (etat) => {
        if (etat == "active")
            return <span class="badge bg-success">Active</span>;
        else if (etat == "pending")
            return <span class="badge bg-warning text-dark">En attente</span>;
        else
            return <span class="badge bg-secondary">{etat}</span>
    }


    return (
        <>
            <div className={showP ? "col-md-5 bg-gray inf_box_pop_r" : "col-md-5 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
                <div className="row no-padd no-marg bg-gray">
                    <button className="btn_close" style={{ textAlign: "left", background: "#212b60" }} onClick={() => setShowP(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
                </div>

                <div className="row no-marg">
                    <div className="col-md-12 bg-wt p-0">
                        <div className="row no-marg pt-5 pb-3">


                            <div className="col-md-2 text-end">
                                {data && data.user && data.user.img ?
                                    <img className="btn_img_pick_src position-relative" src={data.user.img} style={{ width: "100px", height: "100px" }}  alt="" />
                                    :
                                    <img className="btn_img_pick_src position-relative" src={img_default} style={{ width: "100px", height: "100px" }} alt="" />

                                }

                            </div>

                            <div className="col-md-10 ps-5">
                                <h5 className="txt-purple mb-0 mt-2 ">{data && data.user.nom} {data && data.user.prenom}</h5>
                                <h5 className="txt-purple mb-0 mt-0 txt-purple2">{data && data.titre}</h5>
                                <b>Etat : {data && data.user && renderEtat(data.user.etat)} </b>

                            </div>

                            

                           


                            <div className="col-md-12  dvdr-t-gray pt-4 pb-4 mt-2 ">

                                <div className="row no-marg">
                                    <div className="col-md-12 p-0 mb-1">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Condidat </b>
                                        <b className="d-block fnt-w5 txt-gray">date de naissance <span className="txt_blk ps-3">{data && data.date_naissance}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Sex <span className="txt_blk ps-3">{data && data.sex}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Niveau d'étude <span className="txt_blk ps-3">{data && data.niv_etude}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Année(s) d'expérience <span className="txt_blk ps-3">{data && data.niv_exp} an(s)</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Disponible depuis le <span className="txt_blk ps-3">{data && data.dispo}</span> </b>


                                    </div>
                                    <div className="col-md-12 p-0">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Compte </b>
                                        <b className="d-block fnt-w5 txt-gray">Nom <span className="txt_blk ps-3">{data && data.user && data.user.nom}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Prénom <span className="txt_blk ps-3">{data && data.user && data.user.prenom}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">E-mail <span className="txt_blk ps-3">{data && data.user && data.user.email}</span> </b>

                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4 ps-0 pe-1">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Présentation </b>
                                        <p className="d-block txt_blk">{data && data.resume} </p>
                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4 ps-0 pe-0">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Technologies et compétences </b>
                                        {data && data.skills.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}

                                    </div>

                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4 ">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Formation(s) </b>
                                        {data && data.formations &&
                                            data.formations.map(f => <FormationItem data={f} />)
                                        }
                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Experience(s) </b>
                                        {data && data.experiences &&
                                            data.experiences.map(e => <ExperienceItem data={e} />)
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </>

    );
};

export default ClientDetails;