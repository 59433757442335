import axios from "axios";
import api_url  from '../Config';
import  storage from '../Storage';

class UserService {

    
    login(email, password) {
        return axios.post(`${api_url}/api/users/login`, { email: email, password: password }).then(res => {
            if (res.data.success) {
                storage.setUser(res.data.user);
                storage.setToken(res.data.token);
            }

            return res.data ;
        })
    }

    registerClient(user) {
        return axios.post(`${api_url}/api/users/newClient`, user).then(res => {
            if(res.data.done){
                storage.setToken(res.data.token);
            }

            return res ;
        })
    }

    registerCondidat(user) {
        return axios.post(`${api_url}/api/users/newCondidat`, user).then(res => {
            if(res.data.done){
                storage.setToken(res.data.token);
            }

            return res ;
        })
    }

    resendVerifMail() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/rsvmail`, {}, {
            headers: {
                'sb-tkn': token
            }
        })
    }
    verifMail() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/vrfmail`, {}, {
            headers: {
                'sb-tkn': token
            }
        })
    }
    checkProfile() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/checkprofile`, {}, {
            headers: {
                'sb-tkn': token
            }
        })
    }

    checkUser(token) {
        return axios.post(`${api_url}/api/users/checkuser`, {},{headers:{'sb-tkn' : token}});
    }
    checkCv() {
        let token = storage.getToken();
        return axios.get(`${api_url}/api/users/checkcv`,{headers:{'sb-tkn' : token}});
    }

    uploadUserImg(data ) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/upload`, data, {
            headers: {
                'sb-tkn': token,
                'content-type': 'multipart/form-data'
            }
        })
    }

    uploadUserCv(data ) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/upload/cv`, data, {
            headers: {
                'sb-tkn': token,
                'content-type': 'multipart/form-data'
            }
        })
    }

    editPass(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/changepass`, data, {
            headers: {
                'sb-tkn': token
            }
        })
    }


    logout() {
        storage.logout();
    }


    refreshToken() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/refreshToken`,{} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    resetPass(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/users/resetPass`, data , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    forgetPass(data) {
        return axios.post(`${api_url}/api/users/forgetPass`,data , {
        });
    }

 
}

export default new UserService();