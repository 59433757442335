/* eslint-disable */
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ExperienceItem from '../items/ExperienceItemForm';
import SkillsPicker from './Skills.from';

const Register = ({ onDataSubmit, loading, error }) => {




    const [lst_exp, set_lst_exp] = useState([]);
    const [lst_skills, set_lst_skills] = useState([]);
    const [step2_err, set_step2_err] = useState("");




    const validationSchema2 = Yup.object().shape({
        domaine: Yup.string()
            .required('Vous devez saisir le domaine  !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        fonction: Yup.string()
            .required('Vous devez saisir l\'intitulé du poste !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        entreprise: Yup.string()
            .required('Vous devez saisir le nom de l\'entreprise !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        lieu: Yup.string()
            .required('Vous devez saisir le lieu !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        description: Yup.string()
            .required('Vous devez vous décrire !')
            .min(10, 'non valid( min 50 caractères ) !'),
        date_debut: Yup.string()
            .required('Vous devez saisir la date de debut!'),
        date_fin: Yup.string()
            .required('Vous devez saisir la date de fin!'),
    });


    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register: register2, unregister: unregister2, handleSubmit: handleSubmit2, reset: reset2, formState: formState2 } = useForm(formOptions2);
    const { errors: errors2 } = formState2;



    const onSubmitStep2 = data => {
        set_step2_err("");
        set_lst_exp([{ ...data, tech: [...lst_skills] }, ...lst_exp]);
        //console.log(JSON.stringify(lst_exp, null, 4));
        //document.getElementById("form_exp").reset();
        reset2()
        set_lst_skills([]);
        return false;

    };

    const saveData = () => {
        if (lst_exp.length > 0) {
            onDataSubmit({ exp: lst_exp })
        } else
            set_step2_err("vous devez saisir au moins une experience !")

    }

    const removeItem = (indx) => {
        let array = [...lst_exp];
        if (indx !== -1) {
            array.splice(indx, 1);
            set_lst_exp(array);
        }
    }




    return (
        <div className="row ">


            <div className="col-md-12 h-100 pt-4">
                {error && <p className="text-danger txt-ctr"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}
                {step2_err && <p className="text-danger txt-ctr"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {step2_err}</p>}




                <div className="col-md-8 offset-md-2 " >



                    <form key={2} id="form_exp" onSubmit={handleSubmit2(onSubmitStep2)} className="col-md-12">

                        <div className="row">
                            <div className="col-md-8 " >
                                <h3><FontAwesomeIcon icon={['fas', 'flask']} /> Exprerience(s)</h3>
                            </div>
                            {loading ?
                                <div class="col-md-4 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className="col-md-4 txt-rt" >
                                    <button type="button" className="btn btn-primary btn-login mb-4" onClick={saveData}>Suivant</button>
                                </div>
                            }
                        </div>



                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="text" placeholder="Entreprise ..."  {...register2("entreprise")} className={`form-control ${errors2.entreprise ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.entreprise?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="text" placeholder="Lieu  ..."  {...register2("lieu")} className={`form-control ${errors2.lieu ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.lieu?.message}</p>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-md-6 form-group">
                                <label >Date de debut</label>
                                <input type="date" {...register2("date_debut")} className={`form-control ${errors2.date_debut ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.date_debut?.message}</p>
                            </div>
                            <div className="col-md-6 form-group">
                                <label >Date de fin</label>
                                <input type="date" {...register2("date_fin")} className={`form-control ${errors2.date_fin ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.date_fin?.message}</p>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="text" placeholder="Domaine de l'experience ..."  {...register2("domaine")} className={`form-control ${errors2.domaine ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.domaine?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="text" placeholder="Fonctions ..."  {...register2("fonction")} className={`form-control ${errors2.fonction ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors2.fonction?.message}</p>
                            </div>
                        </div>

                        <SkillsPicker lst_skills={lst_skills} set_lst_skills={set_lst_skills} />

                        <div class="alert alert-secondary pt-1 pb-1 mt-2 mb-2" role="alert">
                            <b>Astuce :</b> Tapez <b>Entrer</b> après la saisie de chaque technologie.
                        </div>

                        <div className="form-group col-md-12">
                            <label >Description</label>
                            <textarea class="form-control" {...register2("description")} className={`form-control ${errors2.description ? 'is-invalid' : 'force-apparence'}`} placeholder="Décrivez cette experience en quelque mots ..." rows="2"></textarea>
                            <p className="text-danger">{errors2.description?.message}</p>
                        </div>

                        {loading ?
                            <div class="col-md-12 p-4 txt-ctr">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <div className="col-md-12 txt-ctr" style={{ height: "60px" }}>
                                <button type="submit" className="btn btn-primary btn-login" style={{ width: "15%", float: "right" }}>Ajouter</button>
                            </div>
                        }

                        <div className="col-md-12 txt-ctr frm_box" >
                            {lst_exp.length ?
                                lst_exp.map((s, index) => <ExperienceItem key={index} data={s} id={index} onRemove={removeItem} />)
                                :
                                <b className="frm_box_txt_emty" >Aucune experience ajouté</b>
                            }

                        </div>


                    </form>




                </div>

            </div>
        </div>
    )
}

export default Register;


