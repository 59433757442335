/* eslint-disable */
import React, { useState , useEffect} from 'react';
import ForgetPassForm from '../../../ui/forms/ForgetPass.form';
import { useHistory } from "react-router-dom";
import UserApiCall from '../../../services/api/UserApiCall';

const ForgetPass = (props) => {

    const history = useHistory();
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [send_done, set_send_done] = useState(false);
    const [email, set_email] = useState(null);

    const doForgetPass = (data) => {
        set_loading(true);
        set_email(data)
        UserApiCall.forgetPass(data).then(
            (res) => {
                set_loading(false)
                if(res.data.done){
                    set_send_done(true)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const OnResendMail = () => {
  
        UserApiCall.forgetPass(email).then(
            (res) => {
                set_loading(false)
                if(res.data.done){
                    set_send_done(true)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }


    // useEffect(()=>{

    //     return ()=>{
    //         props.doClear()
    //     }
    // },[])

   


    return (
        <ForgetPassForm onDataSubmit={doForgetPass}  loading={loading} error={error} send_done={send_done} OnResendMail={OnResendMail}/>
    )
}




export default ForgetPass ;






