export const  routes = {
    home_public :"/",
    login :"/login",
    register :"/register",
    dashb : "/dashb",
    register_profile_client :"/register/cl/inf/",
    register_profile_cond :"/register/cd/inf/",
    register_cond_fne :"/register/cd/fne",

}



