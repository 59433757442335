import React, { useState, useEffect } from 'react';
import CondidatList from '../../../ui/Lists/Condidats.list';
import CondidatToolBox from '../../../ui/Lists/Condidats.toolbox';
import { connect } from 'react-redux';
import { clear_conds, getCondidatList } from '../../../redux/actions/CondsAction';
import CondDetails from './CondDetails.container';
import AdmApiCall from '../../../services/api/AdmApiCall';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import TjmModal from '../../../ui/modals/TjmModal';

/* eslint-disable */

const CondidatsUsers = (props) => {



    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [sel_item_data, set_sel_item_data] = useState(null);
    const [showP, setShowP] = useState(false);
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);


     //Modal valider confirm
     const [md_title_val, setMd_title_val] = useState("");
     const [md_msg_val, setMd_msg_val] = useState("");
     const [modalShow_val, setModalShow_val] = useState(false);
     const handleClose_val = () => setModalShow_val(false);
 
     //Modal invalider confirm
     const [md_title_inv, setMd_title_inv] = useState("");
     const [md_msg_inv, setMd_msg_inv] = useState("");
     const [modalShow_inv, setModalShow_inv] = useState(false);
     const handleClose_inv = () => setModalShow_inv(false);

     //Modal invalider confirm
     const [modalShow_tjm, setModalShow_tjm] = useState(false);
     const handleClose_tjm = () => setModalShow_tjm(false);
     const handleShow_tjm = () => setModalShow_tjm(true);
 

    const onItemDetailsClick = (data) => {
        set_sel_item_data(data);
        setShowP(true);
    }

    useEffect(() => {
        props.doGetConds()

        return () => {
            props.doClearConds()
        }
    }, [])

    useEffect(() => {
        set_lst_data(props.lst_data)
        set_lst_data_src(props.lst_data)

    }, [props.lst_data])

    const onAskValider = () => {
        setMd_title_val("Validation du compte condidat");
        setMd_msg_val("Voulez-vous valider ce condidat ?")
        setModalShow_val(true)
    }

    const onAskInvalider = () => {
        setMd_title_inv("Invalider le compte condidat");
        setMd_msg_inv("Voulez-vous invalider ce condidat ?")
        setModalShow_inv(true)
    }

    const doValidateUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.validateUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetConds()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }
    const doInValidateUser = () => {
        handleClose_inv()
        set_loading(true);
        AdmApiCall.invalidateUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetConds()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const onSearch = (s)=>{
        console.log("search for "+s)
        if(s == ""){
            set_lst_data(lst_data_src)
        }else{
            
            set_lst_data(lst_data_src.filter(elem => (elem.titre.toLowerCase().includes(s.toLowerCase()) || elem.user.nom.toLowerCase().includes(s.toLowerCase()) || elem.user.prenom.toLowerCase().includes(s.toLowerCase()) ) ))
        }

    }

    const doBanUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.banUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetConds()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doUnBanUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.unBanUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetConds()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }





    return (
        <>
            <div className="row no-marg">
                <CondidatToolBox dataCount={lst_data.length} onSearch={onSearch} />
                <CondidatList lst_data={lst_data} loading={props.loading} onItemDetailsClick={onItemDetailsClick} />


            </div>

            <CondDetails showP={showP} setShowP={setShowP} data={sel_item_data} loading={loading} onValider={onAskValider} onInvalider={onAskInvalider} onTjm={handleShow_tjm} onBan={doBanUser} onUnban={doUnBanUser} />
            <ConfirmModal title={md_title_val} msg={md_msg_val} onOk={doValidateUser} onHide={handleClose_val} show={modalShow_val} />
            <ConfirmModal title={md_title_inv} msg={md_msg_inv} onOk={doInValidateUser} onHide={handleClose_inv} show={modalShow_inv} />
            <TjmModal  onHide={handleClose_tjm} show={modalShow_tjm} condidat={sel_item_data} />
        </>
    )

}

const mapStateToProps = state => {
    return {
        loading: state.conds.loading,
        error: state.conds.error,
        lst_data: state.conds.lst_data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doClearConds: () => dispatch(clear_conds()),
        doGetConds: () => dispatch(getCondidatList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CondidatsUsers)

