import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientApiCall from '../../../services/api/ClientApiCall';
import MissionClientList from '../../../ui/Lists/MissionClient.list';
import MissionToolBox from '../../../ui/Lists/Mission.toolbox';

const MesOffres = (props) => {

    const history = useHistory();
    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);


    const [search, setSearch] = useState("")

    const [sel_item_data, set_sel_item_data] = useState(null);


 




 



    const loadData = () => {
        set_loading(true);
        ClientApiCall.getMissions().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_lst_data_src(res.data)
                    set_lst_data(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

  





    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])

  







    return (
        <>
            <div className="row no-marg ">


                {/* -------------------Header--------------------------------------- */}
                <MissionToolBox dataCount={lst_data.length}  />



                {/* -------------------body--------------------------------------- */}

                <MissionClientList lst_data={lst_data} loading={loading}  />




            </div>

           
        </>

    );
};

export default MesOffres;