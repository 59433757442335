import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from '../../utils/helpers/date.helper';

const MissionItem = ({ data, onItemDetailsClick  }) => {


    const itemClicked = () => {
        onItemDetailsClick(data)
    }

    const renderEtat = (etat) => {
        if (etat == "en_cours")
            return <span class="badge bg-success">En cours</span>;
        else if (etat == "termine")
            return <span class="badge bg-secondary">Terminé </span>;
        else if (etat == "en_attente")
            return <span class="badge bg-warning text-dark">En attente</span>;

    }

    return (

        <>
            <div className="col-md-12 p-2">
                <div className="row cond_item p-2" >

                    <div className="col-md-6 text-start">
                        <div className="col-md-12 ">
                            <img src={data.condidat.user.img} className="user_img mt-1" alt="" />
                        </div>


                        <div className="col-md-12">

                            <div className="col-md-12   txt-purple2 " data-toggle="tooltip" title={data.condidat.titre} >
                                <b className="d-block fnt-w5 txt_blk text-capitalize fs-6">{data.condidat.user.nom} {data.condidat.user.prenom} ({data.condidat.titre})</b>
                                <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{data.condidat.niv_etude}</span> -  </b>
                                <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{data.condidat.niv_exp} an(s)</span> </b>
                                <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {data.condidat.dispo ? data.condidat.dispo : '-'}</b>

                            </div>

                        </div>


                    </div>

                    <div className="col-md-6 text-end">
                        <b className="d-block txt-gray fnt-w5 fnt-sm">{getStringDate(data.createdAt)} </b>
                        {renderEtat(data.etat)}
                        <b className="d-block txt-dark mt-2 fnt-w5 fnt-sm">Durée</b>
                        <b className="d-block txt-purple2 mt-2 fnt-w5 fnt-sm">{data.date_debut} <span className="txt_blk"> jusqu'a </span> {data.date_fin}</b>
                        <b className="d-block txt-purple2 mt-2 fnt-w5 fnt-sm">TJM : {data.tjm} €</b>


                    </div>

                  







                </div>

            </div>

        </>
    )

}

export default MissionItem;