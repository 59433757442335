/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import lottie from 'lottie-web';
import wave2 from '../../assets/img/wave2.svg';
import wave3 from '../../assets/img/wave3.svg';
import test_d from '../../assets/img/test_d.png';
import client from '../../assets/img/client.svg';
import coding from '../../assets/img/coding.svg';
import medal from '../../assets/img/medal.svg';
import low_price from '../../assets/img/low_price.svg';
import visa from '../../assets/img/visa.svg';
import collaborate from '../../assets/img/collaborate.svg';
import mission from '../../assets/img/mission2.svg';
import Rocket from '../../assets/img/Rocket-pana.svg';
import partner from '../../assets/img/partner.svg';
import team_goals from '../../assets/img/team_goals.svg';
import diag_divd from '../../assets/img/diag_divd.svg';
import diag_divd2 from '../../assets/img/diag_divd2.svg';
import slide1 from '../../assets/img/slide1.png';
import slide2 from '../../assets/img/slide2.png';
import figure_candidat from '../../assets/img/figure_candidat.png';
import figure_esn from '../../assets/img/figure_esn.png';
import bridge from '../../assets/img/bridge.png';
import vdo from '../../assets/img/vdo.mp4';
import figure_contact from '../../assets/img/figure_contact.png';

import Api from '../../services/api/AdmApiCall';


const HomePage = () => {


    // const anim_container = useRef(null);
    const history = useHistory();
    const [seconds, setSeconds] = useState(0);
    const [slide, setSlide] = useState(1);
    const [nom, set_nom] = useState("");
    const [sujet, set_sujet] = useState("");
    const [message, set_message] = useState("");
    const [email, set_email] = useState("");
    const [succ_message, set_succ_message] = useState("");
    const [err_message, set_err_message] = useState("");

    const goRegisterClient = () => {
        history.push("/register?sel=client");
    }
    const goRegisterCandidat = () => {
        history.push("/register?sel=candidat");
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            setSlide(s => s < 3 ? s + 1 : 1);
        }, 10000);


        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log("slide change :: " + slide)
        if (slide == 3) {
            var vid = document.getElementById("vdp");
            vid.playbackRate = 0.75;
        }

    }, [slide]);


    const sendMail = () => {
        set_succ_message("")
        set_err_message("")
        if (nom && sujet && message) {
            //window.open('mailto:contact@bridge-tech.fr?subject=' + sujet + 'subject&body=' + message);
            //window.location = 'mailto:contact@bridge-tech.fr?subject=' + sujet + 'subject&body=' + message;
            Api.addContactus({ fullname: nom, subject: sujet, content: message, email: email }).then(res => {
                set_succ_message("votre message a été envoyé avec succès .")
                set_nom("")
                set_sujet("")
                set_message("")
                set_email("")
            })
        }else{
            set_err_message("tous les champs sont obligatoires")
        }

    }


    return (
        <>


            <div className={slide == 3 ? "row no-marg  position-relative box_overlay_home bg-wt" : "row no-marg  position-relative box_overlay_home"} >

                {slide == 1 &&
                    <>
                        <div className="col-md-6 mt-5 pt-2 text-center " style={{ zIndex: "9" }}>

                            <div className="col-md-12 text-start ps-5 elem-anim-si2">
                                <h2 className="txt_anim_h1 mt-3">Vous êtes une entreprise ? </h2>
                                <h2 className="txt_anim_h1 ">Vous êtes à la recherche de profils qualifiés et disponibles ? </h2>
                                <h4 className="txt_anim_h1 mt-3 fnt-w4">Trouvez le bon profil en 4 clics et moins d'une minute ! </h4>
                            </div>

                            <div className="col-md-12 text-start ps-5 elem-anim-si3">
                                <button className="btn btn-primary  btn_slider" onClick={goRegisterClient} >Commencer maintenant</button>
                            </div>

                        </div>

                        <div className="col-md-6 text-center box_anim_img pt-5 ">
                            <img src={slide1} width="50%" alt="" className="elem-anim-si" />
                        </div>

                    </>
                }



                {slide == 2 &&
                    <>
                        <div className="col-md-6 mt-5 pt-2 text-center " style={{ zIndex: "9" }}>

                            <div className="col-md-12 text-start ps-5 elem-anim-si2">
                                <h2 className="txt_anim_h1 mt-3">Vous êtes un candidat  ?</h2>
                                <h2 className="txt_anim_h1 ">Vous êtes à la recherche d'une mission 100% à distance ?</h2>
                                <h4 className="txt_anim_h1 mt-3 fnt-w4">Vous bénéficiez d'un large choix de formation sur les nouvelles technologies ?</h4>
                            </div>

                            <div className="col-md-12 text-start ps-5 elem-anim-si3">
                                <button className="btn btn-primary  btn_slider" onClick={goRegisterCandidat} >Commencer maintenant</button>
                            </div>

                        </div>

                        <div className="col-md-6 text-center box_anim_img pt-5 ">
                            <img src={slide2} width="50%" alt="" className="elem-anim-si" />
                        </div>

                    </>
                }



                {slide == 3 &&


                    <video src={vdo} id="vdp" type="video/mp4" width="100%" autoPlay muted className="slider-vdo" ></video>


                }





                <div className="col-md-12 bg-overlay no-padd" style={{ overflowX: "clip" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ transform: "scale(1.5)" }}><path fill="#ffffff" fill-opacity="1" d="M0,0L48,32C96,64,192,128,288,165.3C384,203,480,213,576,202.7C672,192,768,160,864,160C960,160,1056,192,1152,176C1248,160,1344,96,1392,64L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                </div>

            </div>









            <div className="row no-marg   box-presentation">

                <div className="col-md-10 offset-md-1 text-center txt-dark-bl pb-5">
                    <h4 style={{ color: "#555fca" }}>Une plateforme 100% dédiée au télétravail</h4>
                    <b className="txt_blk fnt-w5">Nous supprimons les distances .. Nous réduisons les obstacles .. Nous optimisons la productivité</b>
                </div>


                <div className="col-md-6 txt-ctr">

                    <img src={figure_esn} alt="" style={{ height: "200px", width: "auto" }} />
                    <h5 className="mt-5 mb-3" style={{ color: "#555fca" }}>Vous êtes un Client ? </h5>
                    {/* <h4 className="txt-blue-dark" style={{ color: "#4143e5" }}>Trouvez un profil qualifié et disponible</h4> */}
                    <p className="txt_blk fnt-w5">Sélectionnez un profil  <br />Nous nous occupons du reste !</p>
                    <button className="btn btn-primary  btn-register-prest" onClick={goRegisterClient} >Inscrivez vous</button>
                </div>


                <div className="col-md-6 txt-ctr">
                    <img src={figure_candidat} alt="" style={{ height: "200px", width: "auto" }} />
                    <h5 className="mt-5 mb-3" style={{ color: "#555fca" }}>Vous êtes un candidat  ? </h5>
                    {/* <h4 className="txt-blue-dark" style={{ color: "#4143e5" }}>Trouvez une bonne aportunité </h4> */}
                    <p className="txt_blk fnt-w5">Trouvez la bonne opportunité <br />La maîtrise c'est vous , votre mission c'est nous !</p>
                    <button className="btn btn-primary  btn-register-prest" onClick={goRegisterCandidat} >Inscrivez vous</button>
                </div>





            </div>



            <div className="row   ps-2 pe-2 pb-5 box-why" id="box_why">
                <div className="col-md-3 text-center mt-5 mb-5">
                    {/* <hr width="100%" /> */}
                </div>
                <div className="col-md-6 text-center mt-5 mb-5 txt-dark-bl">
                    <h4 style={{ color: "#555fca" }}>Pourquoi choisir SMART BRIDGE ?</h4>
                </div>
                <div className="col-md-3 text-center mt-5 mb-5">
                    {/* <hr width="100%" /> */}
                </div>

                <div className="col-md-10 offset-md-1">
                    <div className="row">

                        <div className="col-md-5 offset-md-1  service-itm">
                            <div className="col-md-3 d-inline-block float-start pt-3">
                                <img src={medal} className="item_srvice_img" alt="" />
                            </div>

                            <div className="col-md-9 d-inline-block">
                                <b className="d-block " style={{ color: "#555fca" }}>Proposition de valeur</b>
                                <p style={{ textAlign: "justify", fontSize: "smaller" }}>
                                    Des compétences qualifiées, validées et accessibles en 4 clics.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 service-itm ">
                            <div className="col-md-3 d-inline-block float-start pt-3">
                                <img src={low_price} className="item_srvice_img" alt="" />
                            </div>
                            <div className="col-md-9 d-inline-block">
                                <b className="d-block " style={{ color: "#555fca" }}>Des tarifs attractifs</b>
                                <p style={{ textAlign: "justify", fontSize: "smaller" }}>
                                    Nous vous proposons le bon profil, au bon tarif, au bon moment .
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1 service-itm">
                            <div className="col-md-3 d-inline-block float-start pt-3">
                                <img src={visa} className="item_srvice_img" alt="" />
                            </div>
                            <div className="col-md-9 d-inline-block">
                                <b className="d-block m-1 " style={{ color: "#555fca" }}>Sans frontières</b>
                                <p style={{ textAlign: "justify", fontSize: "smaller" }}>
                                    À l'ère du cloud, la mobilité n'est plus un obstacle. <br />
                                    Réservez vite votre place sur le cloud des compétences !
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5  service-itm">
                            <div className="col-md-3 d-inline-block float-start pt-3">
                                <img src={collaborate} className="item_srvice_img" alt="" />
                            </div>
                            <div className="col-md-9 d-inline-block">
                                <b className="d-block m-1 " style={{ color: "#555fca" }}>'CC' un nouveau concept</b>
                                <p style={{ textAlign: "left", fontSize: "smaller" }}>
                                    Nous avons mis en place un système de collaboration et d'échange entre nos consultants afin d'accumuler une Compétence Collective 'CC' inégalée !
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>







            <div className="row no-marg   bg-wt  box_mision pb-5 " style={{ boxShadow: "none" }} id="box_service">
                <div className="col-md-12 text-center mb-5">
                    <h4 style={{ color: "#555fca" }}>Notre mission</h4>
                    <p className="fnt-w5">Smart Bridge est une plateforme 100% dédiée  au télétravail. Nous supprimons les frontières au profit de nos entreprises clientes.</p>
                </div>



                <div className="col-md-12 no-padd mt-5">
                    <img src={bridge} width="100%" alt="" />
                </div>

                <div className="col-md-4" style={{ marginTop: "-50px" }}>
                    <div className="col-md-2 offset-md-2 d-inline-block float-start pt-3">
                        <h1 style={{ color: "#555fca" }}>01.</h1>
                    </div>
                    <div className="col-md-7 d-inline-block">
                        <p style={{ textAlign: "justify" }}>Nous offrons une large gamme de compétences du quatre coins du monde, aux entreprises européennes au bon moment.</p>
                    </div>
                </div>

                <div className="col-md-4" style={{ marginTop: "-50px" }}>
                    <div className="col-md-2 offset-md-1 d-inline-block float-start pt-3">
                        <h1 style={{ color: "#555fca" }}>02.</h1>
                    </div>
                    <div className="col-md-7 d-inline-block">
                        <p style={{ textAlign: "justify" }}>Nous assurons la sécurité des données de nos clients, ainsi que l’environnement technique de nos collaborateurs.</p>
                    </div>
                </div>

                <div className="col-md-4" style={{ marginTop: "-50px" }}>
                    <div className="col-md-2  d-inline-block float-start pt-3">
                        <h1 style={{ color: "#555fca" }}>03.</h1>
                    </div>
                    <div className="col-md-7 d-inline-block">
                        <p style={{ textAlign: "justify" }}>Nous nous engageons à réussir les missions de nos collaborateurs, nous mettons notre Compétence Collective “CC” à leurs service, tout au long de leurs missions.</p>
                    </div>
                </div>



                {/* <div className="col-md-5 p-3 box_mision_txt txt-dark-bl" style={{ boxShadow: "none" }}>
                    <div className="col-md-12 text-center ps-4 pe-4 pt-3">
                        <div className="row no-marg">
                            <h1>Notre mission</h1>
                            <div className="col-md-12 text-center">
                                <hr width="30%" className="d-inline-block" />
                                <b className="d-inline-block" style={{ color: "#fff", background: "#4143e5", width: "40px" }}>1</b>
                                <hr width="30%" className="d-inline-block" />

                            </div>
                            <div className="col-md-12 pt-4">


                            </div>
                        </div>
                    </div>




                    <div className="col-md-12 text-center ps-4 pe-4">
                        <div className="row no-marg">
                            <div className="col-md-12 text-center">
                                <hr width="30%" className="d-inline-block" />
                                <b className="d-inline-block" style={{ color: "#fff", background: "#4143e5", width: "40px" }}>2</b>
                                <hr width="30%" className="d-inline-block" />

                            </div>
                            <div className="col-md-12 pt-4">
                                <p style={{ textAlign: "justify" }}>
                                    Nous assurons la mise en place et la sécurisation de l'environnement technique du collaborateur.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12  mt-4 text-center ps-4 pe-4 pb-5 mb-5">
                        <div className="row no-marg">
                            <div className="col-md-12 text-center">
                                <hr width="30%" className="d-inline-block" />
                                <b className="d-inline-block" style={{ color: "#fff", background: "#4143e5", width: "40px" }}>3</b>
                                <hr width="30%" className="d-inline-block" />

                            </div>
                            <div className="col-md-12 pt-4">
                                <p style={{ textAlign: "justify" }}>
                                    Nous nous engageons à réussir les missions de nos collaborateurs, nous mettons notre Compétence Collective "CC" à leurs profit tout au long de leurs missions.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                */}

            </div>


            <div className="row no-marg pb-5 ps-2 pe-2 box-contact">

                <div className="col-md-12 text-center mb-5">
                    <h4 style={{ color: "#555fca" }}>Avez-vous des questions ?</h4>
                    <p className="fnt-w5">Vous avez des questions ? N’hésitez pas à nous contacter. On est là pour vous.</p>
                </div>

                <div className="col-md-6 text-center">
                    <img src={figure_contact} alt="" width="70%" style={{ marginTop: '-20px' }} />
                </div>
                <div className="col-md-6 pt-1">
                    {
                        succ_message &&

                        <div class="alert alert-success" role="alert">
                            {succ_message}
                        </div>
                    }
                    {
                        err_message &&

                        <div class="alert alert-danger" role="alert">
                            {err_message}
                        </div>
                    }
                    <div className="col-md-12 form-group ">
                        <label className="fnt-w5" >Nom & prénom</label>
                        <input type="text" placeholder="nom et prénom ..." className='form-control' value={nom} onChange={e => set_nom(e.target.value)} required />
                    </div>
                    <div className="col-md-12 form-group ">
                        <label className="fnt-w5" >E-mail</label>
                        <input type="email" placeholder="E-mail ..." className='form-control' value={email} onChange={e => set_email(e.target.value)} required />
                    </div>
                    <div className="col-md-12 form-group mt-2">
                        <label className="fnt-w5" >Sujet</label>
                        <input type="text" placeholder="sujet ..." className='form-control' value={sujet} onChange={e => set_sujet(e.target.value)} required />
                    </div>
                    <div className="col-md-12 form-group mt-2">
                        <label className="fnt-w5" >Message</label>
                        <textarea type="text" placeholder="Message ..." className='form-control' rows="6" value={message} onChange={e => set_message(e.target.value)} required />
                    </div>
                    <div className="col-md-12 form-group text-center mt-2">
                        <button className="btn btn-primary btn-login "  onClick={sendMail} target="_parent">Envoyer</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomePage;