/* eslint-disable */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_wt from '../../assets/img/logo_bl.png'
import bg from '../../assets/img/programmer2.png'
import { useHistory , useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { routes } from '../../utils/helpers/routing.helper';

const Register = ({ onDataSubmit, loading, error, register_done, OnResendMail , ...props }) => {


    const history = useHistory();
    const {sel} = useParams();

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }

    const validationSchema = Yup.object().shape({
        type_cmpt: Yup.string()
            .required('Vous devez choisir un type de compte !'),
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),
        password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir votre mot de passe !'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !')
            .required('Vous devez confirmez votre mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState , setValue } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data)
    }

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        if(params.get("sel") == "client")
        setValue('type_cmpt','client')

        else if(params.get("sel") == "candidat")
        setValue('type_cmpt','condidat')
    },[])



    return (
        <div className="row no-marg login-container bg-wt">


            <div className="col-md-8 h-100">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>


                </div>

                <div className="row no-marg">
                    <div className="col-md-12">
                        <nav className="k-stepper">
                            <ol className="k-step-list">
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="0" aria-current="step">
                                        <span className="k-step-indic">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'user-plus']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">inscription</b>
                                        </span>
                                    </a>

                                </li>
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="-1" >
                                        <span className="k-step-indic not-done">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">validation email</b>
                                        </span>
                                    </a>

                                </li>
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="-1" >
                                        <span className="k-step-indic not-done">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'user']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">profil</b>
                                        </span>
                                    </a>

                                </li>
                                <li className="k-step">
                                    <a href="" className="k-step-link" tabindex="-1" >
                                        <span className="k-step-indic not-done">
                                            <b className="k-icon"><FontAwesomeIcon icon={['fas', 'check']} /></b>
                                        </span>
                                        <span className="k-label">
                                            <b className="fnt-w5">commencer</b>
                                        </span>
                                    </a>

                                </li>
                            </ol>

                            <div className="k-stepper-line"></div>
                        </nav>
                    </div>


                </div>

                {!register_done ?

                    <div className="col-md-8 offset-md-2 " >
                        <br /> <br />
                        <h2 className="padd-10">Créer un compte</h2>
                        {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">

                            <div className="form-group col-md-12">
                                <label >Vous êtes</label>
                                <Form.Control as="select"  {...register("type_cmpt")} className={`form-control ${errors.type_cmpt ? 'is-invalid' : 'force-apparence'}`}  >
                                    <option value="">Choisissez un type de compte …</option>
                                    <option value="client">Client </option>
                                    <option value="condidat">Candidat  </option>
                                </Form.Control>
                                <p className="text-danger">{errors.type_cmpt?.message}</p>
                            </div>
                            <div className="form-group col-md-12">
                                <label > </label>
                                <input placeholder="E-mail"  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.email?.message}</p>
                            </div>

                            <div className="row">

                                <div className="col-md-6 form-group ">
                                    <input type="password" placeholder="Mot de passe"  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.password?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                    <input type="password" placeholder="Confirmez mot de passe"  {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.confirmPassword?.message}</p>
                                </div>
                            </div>
                            <div className="col-md-12 form-group form-check">
                                <input type="checkbox" class="form-check-input" />
                                <span className="fnt-w5 fnt-smlr" >J'accepte <a href="#" className="btn_forget_mdp pb-4 d-inline-block"> les termes et conditions d'utilisation.</a></span>
                            </div>
                            <div className="col-md-12 txt-ctr" >
                                {loading ?
                                    <div class="col-md-12 p-4 txt-ctr">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                }
                            </div>
                            <br /> <br />

                            <span className="fnt-w5 d-block txt-rt" >Je suis déjà membre <a href="#" onClick={(e) => goLogin()} className="btn_forget_mdp pb-4 d-inline-block"> Se connecter</a></span>




                            <br />
                        </form>




                    </div>

                    :

                    (loading ?
                        <div class="col-md-12 p-4 txt-ctr">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="col-md-8 offset-md-2 txt-ctr p-4" >
                            <h1 style={{ fontSize: "xxx-large" }} className="txt-purple pt-4 barcode_icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></h1>
                            <h3>Un email de confirmation à été envoyé</h3>
                            <h4 className="txt-gray">veuillez vérifier votre boîte de réception</h4>
                            <p className="txt-gray">Je n'ai pas reçu le mail vérification</p>
                            <button type="button" className="btn btn-primary btn-login mt-4" onClick={OnResendMail} >Renvoyer le mail</button>
                        </div>
                    )
                }
            </div>

            <div className="col-md-4 bg-grad-purple1 h-100" style={{ position: "relative" }}>
                <img src={logo_wt} className="avatar_logo" alt="" style={{width : '50%' , top : '25px' , left:'25%'}} />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>
    )
}


export default Register;



