function zerofill(i) {
    return (i < 10 ? '0' : '') + i;
}

export const getStringDate = (d) => {
    if (d) {
        const date = new Date(d);
        const year = date.getFullYear();
        const month = zerofill(date.getMonth() + 1);
        const day = zerofill(date.getDate());
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return day + '/' + month + '/' + year + '-' + hours + ':' + minutes;
    }else{
        return '_';
    }
}

export const getStringOnlyDate = (d) => {
    if (d) {
        const date = new Date(d);
        const year = date.getFullYear();
        const month = zerofill(date.getMonth() + 1);
        const day = zerofill(date.getDate());
        const minutes = date.getMinutes();
        return day + '/' + month + '/' + year ;
    }else{
        return '_';
    }
}