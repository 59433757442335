import React from 'react';
import banner_client from '../../../assets/img/banner_client.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Storage from '../../../services/Storage';
import { Doughnut, Bar } from 'react-chartjs-2';

const Home = ({ match }) => {


    const user = Storage.getUserData();
    const data = {
        labels: ['React Js', 'Angular', 'Rails', 'Flutter', 'Node Js', 'TensorFlow'],
        datasets: [
            {
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: 
                [
                    '#3f51b5',
                    '#3679f3',
                    '#9774f9',
                    '#4c3884',
                    '#3f51b5',
                    '#9774f9',
                ]
                ,
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverOffset: 8,
            },
        ],
    };

    function getGradient(ctx, chartArea) {
        let width, height, gradient;
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (gradient === null || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
            gradient.addColorStop(0, "#3f51b5");
            // gradient.addColorStop(0.4, "#03a9f4");
            gradient.addColorStop(0.4, "#3679f3");
            gradient.addColorStop(1, "#9774f9");
        }

        return gradient;
    }

    function getGradient2(ctx, chartArea) {
        let width, height, gradient;
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (gradient === null || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0,chartArea.top, 0,chartArea.bottom);
            gradient.addColorStop(0, "#3f51b5");
            // gradient.addColorStop(0.4, "#03a9f4");
            gradient.addColorStop(0.4, "#3679f3");
            gradient.addColorStop(1, "#9774f9");
        }

        return gradient;
    }



    const data2 = {
        labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'],
        datasets: [{

            label: 'CA',
            data: [600, 300, 480, 760, 280, 930, 400],
            backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                return getGradient(ctx, chartArea);
            }
            // [
            //     '#281e46',
            //     '#201e46',
            //     '#403c8a',
            //     '#4c3884',
            //     '#3f51b5',
            //     '#9774f9',
            //     '#9774f9',
            // ]
            ,
            borderColor: '#fff',
            borderWidth: 1,
            barThickness: 20,
            borderRadius: 20,
        }]
    };



    const data3 = {
        labels: ['Kwin', 'Wewater', 'Billcom', 'FSTE'],
        datasets: [{

            label: 'CA',
            data: [800, 300, 480, 520],
            backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                return getGradient(ctx, chartArea);
            }
            // [
            //     '#281e46',
            //     '#201e46',
            //     '#403c8a',
            //     '#4c3884',
            //     '#3f51b5',
            //     '#9774f9',
            //     '#9774f9',
            // ]
            ,
            borderColor: '#fff',
            borderWidth: 1,
            barThickness: 20,
            borderRadius: 20,
        }]
    };

    const data4 = {
        labels: ['Kwin', 'Wewater', 'Billcom', 'FSTE'],
        datasets: [{

            label: 'Contrat(s)',
            data: [62, 81, 15, 48],
            backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                return getGradient2(ctx, chartArea);
            }
            // [
            //     '#281e46',
            //     '#201e46',
            //     '#403c8a',
            //     '#4c3884',
            //     '#3f51b5',
            //     '#9774f9',
            //     '#9774f9',
            // ]
            ,
            borderColor: '#fff',
            borderWidth: 1,
            barThickness: 20,
            borderRadius: 20,
        }]
    };




    return (

        <>
            <div className="row no-marg bg-wt">
                <div className="col-md-12 banner_box no-padd" style={{marginTop:"-4%"}}>
                    <img src={banner_client} className="banner_box_img" alt="" />
                    <div className="col-md-12 banner_box_effect"></div>
                    <div className="col-md-12 banner_box_content">
                        <b className="fs-5 fnt-w5 d-block pb-4 txt_blk">Bonjour , Admin {user && user.user.prenom}  </b>
                    </div>
                </div>


                

                <div className="col-md-6 text-ctr">
                    <b className="fnt-w5 stat_box_title"> <span style={{color:"#ffffff73"}}><FontAwesomeIcon icon={['fas', 'bullseye']} /></span> Chiffre d'affaire par mois</b>
                    <Bar data={data2} options={{
                        indexAxis: 'y',
                        plugins: {
                            legend: {
                                display: false,
                                position: 'left',
                                labels: {
                                    color: '#000',
                                    borderRadius: 50,
                                    usePointStyle: true
                                },

                            }
                        },
                        scales: {
                            x: {

                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "CA(K€)",
                                    align: 'end'
                                },

                                ticks: {
                                    color: '#3f51b5',
                                    font: {
                                        weight: 'bold'
                                    },
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, values) {
                                        return value + 'K€';
                                    }
                                }
                            },

                            y: {
                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "",
                                    align: 'end'
                                },
                                ticks: {
                                    color: '#000',
                                    align: 'end',
                                    font: {
                                        weight: 'bold'
                                    },

                                }


                            },
                        }
                    }} />
                </div>


                <div className="col-md-6 text-ctr">
                    <b className="fnt-w5 stat_box_title"> <span style={{color:"#ffffff73"}}><FontAwesomeIcon icon={['fas', 'bullseye']} /></span> Chiffre d'affaire par consultant</b>
                    <Bar data={data3} options={{
                        indexAxis: 'y',
                        plugins: {
                            legend: {
                                display: false,
                                position: 'left',
                                labels: {
                                    color: '#000',
                                    borderRadius: 50,
                                    usePointStyle: true
                                },

                            }
                        },
                        scales: {
                            x: {

                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "CA(K€)",
                                    align: 'end'
                                },

                                ticks: {
                                    color: '#3f51b5',
                                    font: {
                                        weight: 'bold'
                                    },
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, values) {
                                        return value + 'K€';
                                    }
                                }
                            },

                            y: {
                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "",
                                    align: 'end'
                                },
                                ticks: {
                                    color: '#000',
                                    align: 'end',
                                    font: {
                                        weight: 'bold'
                                    },

                                }


                            },
                        }
                    }} />
                </div>




                <div className="col-md-6 " >
                    <b className="fnt-w5 stat_box_title"><span style={{color:"#ffffff73"}}><FontAwesomeIcon icon={['fas', 'bullseye']} /></span> Les technologies les plus recherchés</b>
                    <div className="col-md-6 ps-2">

                        <Doughnut data={data} options={{

                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'right',
                                    labels: {
                                        color: '#000',
                                        borderRadius: 50,
                                        usePointStyle: true,
                                        font: {
                                            weight: 'bold'
                                        },
                                    },
                                    datalabels: {
                                        display: true,
                                        align: 'bottom',
                                        backgroundColor: '#ccc',
                                        borderRadius: 3,
                                        font: {
                                            size: 18,
                                        }
                                    },
                                }
                            },

                        }} />
                    </div>
                </div>

                <div className="col-md-6 text-ctr">
                    <b className="fnt-w5 stat_box_title"> <span style={{color:"#ffffff73"}}><FontAwesomeIcon icon={['fas', 'bullseye']} /></span> Nombre de contrat signés par client</b>
                    <Bar data={data4}  options={{
                        
                        plugins: {
                            legend: {
                                display: false,
                                position: 'left',
                                labels: {
                                    color: '#000',
                                    borderRadius: 50,
                                    usePointStyle: true
                                },

                            }
                        },
                        scales: {
                            x: {

                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "Contrat(s)",
                                    align: 'end'
                                },

                                ticks: {
                                    color: '#3f51b5',
                                    font: {
                                        weight: 'bold'
                                    },
                                    // Include a dollar sign in the ticks
                                    
                                }
                            },

                            y: {
                                grid: {
                                    display: true,

                                },
                                title: {
                                    display: true,
                                    color: '#000',
                                    text: "",
                                    align: 'end'
                                },
                                ticks: {
                                    color: '#000',
                                    align: 'end',
                                    font: {
                                        weight: 'bold'
                                    },

                                }


                            },
                        }
                    }} />
                </div>






            </div>

        </>
    )

}

export default Home;