import React, { useState, useEffect } from 'react';
import DmdCondidatList from '../../../ui/Lists/DmdCondidats.list';
import DmdCondidatToolBox from '../../../ui/Lists/DmdCondidats.toolbox';
import {connect} from 'react-redux';
import {clear_dmd_conds , getDmdCondidatList} from '../../../redux/actions/DmdCondsAction';
import { useHistory } from "react-router-dom";
import TraiteDmdModal from '../../../ui/modals/TraiteDmdModal';

/* eslint-disable */

const DmdCondidats = (props) => {



    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [sel_item_data, set_sel_item_data] = useState(null);
    const [showP, setShowP] = useState(false);

    const history = useHistory();

    const traiter = () => {
        history.push("/dashb/chat");
    }

    const onItemDetailsClick = (data)=>{
        set_sel_item_data(data);
        setShowP(true);
        //traiter()
    }

    useEffect(() => {
           props.doGetDmdConds()

        return ()=>{
            props.doClearDmdConds()
        }
    }, [])

    useEffect(() => {
        set_lst_data(props.lst_data)
        set_lst_data_src(props.lst_data)
         
    }, [props.lst_data])




    return (

        <div className="row no-marg">
            <DmdCondidatToolBox dataCount={lst_data.length} />
            <DmdCondidatList lst_data={lst_data} loading={props.loading} onItemDetailsClick={onItemDetailsClick} />

            <TraiteDmdModal onHide ={e => setShowP(false)} show ={showP} selItem={sel_item_data} />

        </div>
    )

}

const mapStateToProps = state => {
    return {
        loading : state.dmd_conds.loading ,
        error : state.dmd_conds.error ,
        lst_data : state.dmd_conds.lst_data ,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doClearDmdConds : () => dispatch(clear_dmd_conds()) ,
        doGetDmdConds : () => dispatch(getDmdCondidatList()) ,
    }
}

export default connect(mapStateToProps ,mapDispatchToProps)(DmdCondidats)

