import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import ClientApiCall from '../../../services/api/ClientApiCall';
import UserApiCall from '../../../services/api/UserApiCall';
import img_default from '../../../assets/img/img_default.png';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import MdpUpdate from './MdpUpdate.container';



const ProfileClient = () => {

    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [data, set_data] = useState(false);
    const [edt_pass, set_edt_pass] = useState(false);

    const [showP , setShowP] = useState(false);

    const showEditPass = ()=>{
        setShowP(true)
    }

    const goEdit = () => {
        history.push("profile/edt")
    }



    const loadData = () => {
        set_loading(true);
        set_error("")
        ClientApiCall.getMyProfile().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_data(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doEditPass = (data) => {
        set_loading(true);
        set_edt_pass(false)
        set_error("")
        UserApiCall.editPass(data).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    set_edt_pass(true)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])


    return (
        <>
            <div className="row no-marg">
                <div className="col-md-12 bg-wt">
                    <div className="row no-marg pt-5 pb-3">


                        <div className="col-md-3 text-end">
                            {data && data.user && data.user.img ?
                                <img className="btn_img_pick_src position-relative" src={data.user.img} alt="" />
                                :
                                <img className="btn_img_pick_src position-relative" src={img_default} alt="" />

                            }

                        </div>

                        <div className="col-md-6">
                            <h5 className="txt-purple mb-0 mt-5">{data && data.nom_entreprise} </h5>
                            <b>Type : <span>{data && data.type_client} </span> </b>
                        </div>

                        <div className="col-md-3 position-relative">
                            <div className="position-absolute bottom-0 start-0">
                                <button className="btn_round" onClick={goEdit}> <FontAwesomeIcon icon={['fas', 'pencil-alt']} /> </button>
                                {/* <button className="btn_round ms-3"> <FontAwesomeIcon icon={['fas', 'image']} /> </button> */}
                                <button className="btn_round ms-3" onClick={showEditPass}> <FontAwesomeIcon icon={['fas', 'key']} /> </button>
                            </div>
                        </div>


                        <div className="col-md-10 offset-md-1 dvdr-t-gray pt-4 pb-4 mt-2">

                            <div className="row no-marg">
                                <div className="col-md-6 dvdr-r-gray">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Entreprise </b>
                                    <b className="d-block fnt-w5 txt-gray">date de creation <span className="txt_blk ps-3">{data && data.date_creation}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Secteur d'activité <span className="txt_blk ps-3">{data && data.secteur}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Site web <a href={`http://${data && data.site_web}`} target="_blank">{data && data.site_web}</a> </b>
                                    
                                </div>
                                <div className="col-md-6">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Compte </b>
                                    <b className="d-block fnt-w5 txt-gray">Nom <span className="txt_blk ps-3">{data && data.user && data.user.nom}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">Prénom <span className="txt_blk ps-3">{data && data.user && data.user.prenom}</span> </b>
                                    <b className="d-block fnt-w5 txt-gray">E-mail <span className="txt_blk ps-3">{data && data.user && data.user.email}</span> </b>

                                </div>

                                <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                    <b className="d-block fnt-w5 txt-purple mb-2">Présentation </b>
                                    <p className="d-block txt_blk">{data && data.resume} </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <MdpUpdate showP={showP} setShowP={setShowP} onDataSubmit={doEditPass} done={edt_pass} error={error}/>


        </>

    );
};

export default ProfileClient;