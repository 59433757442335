/* eslint-disable */
import React, { useState , useEffect} from 'react';
import LoginForm from '../../../ui/forms/Login.form';
import { login, clear_auth } from '../../../redux/actions/AuthActions';
import { connect } from "react-redux";
import {routes} from '../../../utils/helpers/routing.helper';
import { useHistory } from "react-router-dom";

const Login = (props) => {

    const history = useHistory();

    useEffect(()=>{

        return ()=>{
            props.doClear()
        }
    },[])

    useEffect(()=>{
        if(props.success_login ){
            if(props.user){
                console.log(props.user)
                if (props.user.profile_done == false) {
                    history.push("/register/cd/inf")
                } else if (props.user.formation_done == false && props.user.role == "condidat") {
                    history.push("/register/cd/educ" );
                } else if (props.user.exp_done == false && props.user.role == "condidat" ) {
                    history.push("/register/cd/exp");
                }else{
                    history.push(routes.dashb)
                }
            }
            
        }

    },[props.success_login , props.user])


    return (
        <LoginForm onDataSubmit={props.doLogin}  loading={props.loading} error={props.error} />
    )
}


const mapStateToProps = state =>{
    return {
        loading : state.auth.loading,
        error : state.auth.error ,
        success_login : state.auth.success_login,
        user : state.auth.user ,
        
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        doLogin : (email , password) => dispatch(login(email , password)),
        doClear : () => dispatch(clear_auth()),
    }
}



export default connect(
    mapStateToProps ,
    mapDispatchToProps ,
)(Login)






