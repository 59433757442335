import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import AdmApiCall from '../../services/api/AdmApiCall';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';

const EditMissionModal = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [net_succ, set_net_succ] = useState("")
    const [net_err, set_net_err] = useState("")

    const validationSchema = Yup.object().shape({
        tjm : Yup.number()
            .typeError('le TJM doit être un nombre en €')
            .required('Vous devez choisir le TJM !')
            .test(
                'TJM valid',
                'TJM non valid',
                value => (value + "").match(/^\d{1,9}?$/),
            ),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = (data) => {

        setLoading(true)

        AdmApiCall.setCondTjm({ ...data, id: props.condidat.id }).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("Modification TJM avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        window.location.reload(false);
                    }, 1000);

                } else {
                    setLoading(false)
                    set_net_err("Echec  !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err(message);
            }
        );
    }


    useEffect(()=>{
        if(props.condidat)
        setValue("tjm", props.condidat.tjm)

    },[props.condidat])


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter " className="txt-purple2">
                    Modifier TJM
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="">
                {net_succ &&
                    <div class="alert alert-success" role="alert">
                        {net_succ}
                    </div>
                }

                <form onSubmit={handleSubmit(onSubmit)} className="row no-marg pt-2">
                    <div className="col-md-12 form-group">
                        <label className="fnt-w5">TJM ( en € ) </label>
                        <input type="text"   {...register("tjm")} className={`form-control ${errors.tjm ? 'is-invalid' : 'form-control'}`} />
                        <p className="text-danger">{errors.tjm?.message}</p>
                    </div>

                    <Button variant="success" type="submit">
                        Confirmer
                    </Button>

                </form>


            </Modal.Body>

            <Modal.Footer>

                <Button variant="secondary" onClick={props.onHide}>
                    Annuler
                </Button>


            </Modal.Footer>
        </Modal>
    );

}

export default EditMissionModal;