import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientApiCall from '../../../services/api/ClientApiCall';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import CondidatItem from './CondidatItem';
import CondidatDetails from '../searchCondidat/CondidatDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';

const OffreDetails = (props) => {

    const history = useHistory();
    const [offre, setOffre] = useState();
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [id, setId] = useState(props.match.params.id);

    const [selTab, setSelTab] = useState(0);
    const [showP, setShowP] = useState(false);
    const [selItemData, setSelItemData] = useState(null);

    const [lst_fav, set_lst_fav] = useState([]);


    //Modal delete confirm
    const [md_title_del, setMd_title_del] = useState("");
    const [md_msg_del, setMd_msg_del] = useState("");
    const [modalShow_del, setModalShow_del] = useState(false);
    const handleClose_del = () => setModalShow_del(false);


    const goMyOffer = () => {
        history.push("/dashb/mesoffres")
    }
    const goEditOffer = () => {
        history.push("/dashb/offre-edt/" + id)
    }



    const loadData = () => {
        set_loading(true);
        ClientApiCall.getOffreDetails(id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    setOffre(res.data)

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const onDemande = (params) => {

        set_loading(true);
        ClientApiCall.newDmdCond(params).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    loadData()
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
                console.log(message)
            }
        );
    }


    const onAskDelete = () => {
        setMd_title_del("Annulation offre");
        setMd_msg_del("Voulez-vous supprimer cette offre ?")
        setModalShow_del(true)
    }

    const onDelete = () => {
        if (id) {
            set_loading(true);
            ClientApiCall.cancelOffre(id).then(
                (res) => {
                    set_loading(false);
                    console.log(res)
                    if (res.data.done) {
                        history.push("/dashb/mesoffres")
                    }
                }, (error) => {
                    set_loading(false);
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_error(message)
                    console.log(message)
                }
            );
        }
    }

    const loadFavorisRefs = () => {
        
        set_loading(true);
        ClientApiCall.getFavorisRefs().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                   set_lst_fav(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
                console.log(message)
            }
        );
    }

    const onFavoris = (id) => {
        
        set_loading(true);
        ClientApiCall.addToFavoris(id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    loadFavorisRefs();
                    loadData()
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
                console.log(message)
            }
        );
    }

    const checkCondFavs = (id) => {
        let found = lst_fav.find(elem => elem.condidatId == id )

        if(found )
        return true ;
        
        return false ;
    }


    useEffect(() => {
        
        if (selItemData && offre)
        offre.demande_offres.forEach(el => {
                if (el.condidat.id == selItemData.id)
                    setSelItemData(el.condidat);
            });
    }, [offre])


    useEffect(() => {

        loadFavorisRefs();
        if (id) {
            loadData()
        } else {
            goMyOffer()
        }

        return () => {

        }

    }, [])


    function replaceWithBr(txt) {
        return txt?.replace(/\n/g, "<br />")
      }








    return (
        <>
            <div className="row no-marg ">


                {/* -------------------Header--------------------------------------- */}

                <div className="col-md-4 pt-2 pb-2">
                    <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2" style={{ width: " 100px" }} onClick={goMyOffer}>
                        <FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} />
                    </button>
                </div>


                <div className="col-md-4 offset-md-4 pt-2 pb-2 text-end">
                    <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={goEditOffer}>
                        <span className="me-1"><FontAwesomeIcon icon={['fas', 'pencil-alt']} /> </span> Edit
                    </button>

                    <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block " style={{ width: " 100px" }} onClick={onAskDelete}>
                        <span className="me-1"><FontAwesomeIcon icon={['fas', 'trash-alt']} /> </span> Annuler
                    </button>
                </div>

                <div className="col-md-12 bg-wt p-3">
                    {offre &&
                        <>
                            <h3 className="d-block ">{offre.titre} <span class="badge bg-success fnt-w5 fnt-sm">{offre.etat}</span></h3>
                            <b className="fnt-w4 fnt-sm txt-gray">de {getStringOnlyDate(offre.date_debut)} jusqu'à {getStringOnlyDate(offre.date_fin)}</b>
                            <b className="float-end fnt-w5">{offre.budget} €</b>

                        </>
                    }

                </div>



                {/* -------------------body --------------------------------------- */}
                <div className="col-md-12 p-0">

                    <nav className="bg-gray">
                        <div className="nav nav-tabs nav-fill " id="nav-tab" role="tablist">
                            <button className={(selTab == 0) ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-home-tab" data-toggle="tab" role="tab" onClick={() => { setSelTab(0) }} >Proposition</button>
                            <button className={(selTab == 1) ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-profile-tab" data-toggle="tab" role="tab" onClick={() => { setSelTab(1) }}>Description</button>

                        </div>
                    </nav>

                    <div className="tab-content col-md-12" id="nav-tabContent">

                        <div className={(selTab == 0) ? "tab-pane fade show active col-md-12 bg-wt" : "tab-pane fade col-md-12"} id="nav-propos" role="tabpanel" aria-labelledby="nav-propos-tab">
                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                offre &&
                                (
                                    offre.demande_offres.length ?
                                        offre.demande_offres.map((s) => <CondidatItem data={s.condidat} showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} />)
                                        :
                                        <div className="col-md-12 text-center p-5 txt-gray ">
                                            <b className="fnt-w5">aucune postulation pour cette offre</b>
                                        </div>


                                )
                            }

                        </div>

                        <div className={(selTab == 1) ? "tab-pane fade show active col-md-12 p-4 bg-wt" : "tab-pane fade col-md-12"} id="nav-desc" role="tabpanel" aria-labelledby="nav-desc-tab">
                            <b className="fnt-w5 d-block">Technologies</b>

                            {offre && offre.tech.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)", minWidth: "50px" }} type="button" >{s} </button>)}
                            <br />

                            <b className="d-block fnt-w5 mt-2">Description</b>
                            {/* <p>{offre && offre.description}</p> */}
                            <p dangerouslySetInnerHTML={{__html: replaceWithBr(offre?.description)}} />
                        </div>





                    </div>

                </div>










            </div>
            <CondidatDetails showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} onDemande={onDemande} onFavoris = {onFavoris} checkCondFavs={checkCondFavs}/>
            <ConfirmModal title={md_title_del} msg={md_msg_del} onOk={onDelete} onHide={handleClose_del} show={modalShow_del} />
        </>

    );
};

export default OffreDetails;