import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img_default from '../../assets/img/img_default.png'

/* eslint-disable */

const UserChatItem = ({data , isSel , onItemClick}) => {
    return (

        <>
            <div className={isSel ? "row m-2 chat_user_item chat_user_item_sel" : "row m-2 chat_user_item "} onClick={e => onItemClick(data)}>

                <div className="col-md-4">
                    {data && data.img ?
                        <img src={data && data.img} className="user_img chat_user_item_img" alt="" />
                        :
                        <img src={img_default} className="user_img chat_user_item_img" alt="" />
                    }
                    {data.isConnected == true ? <span className="sp_connected"></span> : <span className="sp_deco"></span>}

                </div>
                <div className="col-md-8">
                    <b className="d-block txt-purple fnt-w5">{data && data.nom}  {data && data.prenom}</b>
                    <b className="d-block txt-gray fnt-w4">{data && data.role} - {data && data.etat}</b>
                    {data.new_msg.length > 0 &&
                        <span class="badge_msg badge rounded-pill bg-danger">
                            {data.new_msg.length}
                            <span class="visually-hidden">unread messages</span>
                        </span>
                    }
                </div>



            </div>

        </>
    )

}

export default UserChatItem;