import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../../assets/img/Logo-couleur.png';
import logo_bl from '../../../assets/img/logo_bl.png';
import { Route, NavLink } from 'react-router-dom';
import HomeContent from '../../../ui/static/Home';
import { routes } from '../../../utils/helpers/routing.helper';

/* eslint-disable */


const Home = ({ match }) => {


    const padd_menu = {
        padding: 0,
        paddingLeft: "10%",
        paddingRight: "10%"
    }

    const [isOpen, setIsopen] = useState(true);

    return (
        <>
            <nav id="header_nav" className="navbar navbar-expand-md navbar-light " style={isOpen ? padd_menu : { padding: "0" }}>
                <a className="navbar-brand" href="#"><img className="" src={logo} alt="" width="120px" /></a>
                <button onClick={() => setIsopen(!isOpen)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={isOpen ? "collapse navbar-collapse" : "collapse navbar-collapse show "} id="navbarCollapsemenu">
                    <ul className="navbar-nav ml-auto nav_ctr">
                        <li className="nav-item ">
                            <NavLink to={`${match.path}`} exact activeClassName="active" className="nav-link" >Accueil</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/login">Trouver une offre</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#box_service">A propos</a>
                        </li>
                        <li className="nav-item">
                            <a href="#box_service" className="nav-link" >Service</a>
                        </li>

                        <div style={{position:"absolute" , right :"6px"}}>
                            <li className="nav-item d-inline-block" >
                                <NavLink to={routes.login} activeClassName="active" className="nav-link btn-menu-login" >Connecter<span className="link_btm_arrow" ><FontAwesomeIcon icon={['fas', 'angle-up']} /></span></NavLink>
                            </li>
                            <li className="nav-item d-inline-block" style={{ marginLeft: "-30px" }}>
                                <NavLink to={routes.register} activeClassName="active" className="nav-link btn-menu-register" >S'inscrire<span className="link_btm_arrow" ><FontAwesomeIcon icon={['fas', 'angle-up']} /></span></NavLink>
                            </li>
                        </div> 
                    </ul>

                </div>
            </nav>




            <Route
                path={`${match.path}`}
                render={({ match: { url } }) => (
                    <>

                        <Route path={`${url}/`} component={props => <HomeContent {...props} />} />


                    </>
                )}
            />



            <footer id="footer" className="footer">
                <div className="container">
                    <div className="main_footer">
                        <div className="row pt-5" >
                            <div className="col-sm-4 col-xs-12 text-center">
                                <img src={logo_bl} className="footer_logo mb-3" width="50%" />
                                {/* <p className="fnt-sm" style={{ textAlign: "justify", paddingRight: "20px", paddingLeft: "20px" }}>Nous offrons une large gammes de compétences du 4 coins du monde, aux entreprises européennes au bon moment.</p> */}
                                <br />
                                <a className="footer_social me-3" href="https://www.facebook.com/Bridge.technologie" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>

                                <a className="footer_social me-3" href="https://www.linkedin.com/company/bridge-techno" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
                                <a className="footer_social me-3" href="https://www.youtube.com/channel/UCxpl3YYsy_EJi1YfjWt6Zzg" target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} /></a>
                                <a className="footer_social me-3" href="https://www.linkedin.com/company/bridge-techno" target="_blank"><FontAwesomeIcon icon={['fab', 'whatsapp-square']} /></a>
                            </div>
                            <div className="col-sm-4 col-xs-12 ps-5">
                                <b className="footer-title mb-3 fnt-w4">Navigation</b> <br />
                                <a href="/login" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Espace Client/ESN</a>
                                <a href="/login" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Espace Candidat </a>
                                <a href="#box_service" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Notre mission</a>
                                <a href="#box_why" className="footer_links"><FontAwesomeIcon icon={['fas', 'caret-right']} /> Pourquoi SMART BRIDGE ? </a>


                            </div>

                            <div className="col-sm-4 col-xs-12">
                                <b className="footer-title mb-3 fnt-w4">Contact</b><br />

                                <b className="d-block pb-3 fnt-w4 txt-wt"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'envelope']} /></span> contact@bridge-tech.fr</b>

                                <b className="d-block pb-3 fnt-w4 txt-wt"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'phone-alt']} /></span> +33 (0)647390198</b>

                                <b className="d-block pb-3 fnt-w4 txt-wt"> <span className="contact_icon"><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></span> 108 avenue du général Leclerc <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;92340 bourg la reine ,  France</b>




                            </div>


                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-xs-12 txt-ctr  mt-5">
                                <div className="copyright_text">
                                    <p className=" wow fadeInRight fnt-smlr" data-wow-duration="1s" >© Copyright 2021 SMART-BRIDGE.Powered by Smart-Bridge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    )
}

export default Home;