/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routes } from '../../../utils/helpers/routing.helper'
import CondidatFormEdit from '../../../ui/forms/CondidatFormEdit'
import Storage from '../../../services/Storage'
import CondApiCall from '../../../services/api/CondApiCall';




const RegisterCondFnE = ({ condidat, show, setShow }) => {

    const history = useHistory();
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);


    const onSubmit = (data) => {
        set_loading(true);
        set_error("")
        CondApiCall.updateCondInfo({...data , id : condidat.id}).then(
            (res) => {
                //set_loading(false);
                console.log(res)
                if (res.data.done) {
                    window.location.reload(false);
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }




    return (
        <div className={show ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-5 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" style={{ textAlign: "left", background: "#212b60" }} onClick={() => setShow(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>


            <div className="row no-marg bg-wt pb-5 bg-wt">
                <h4 className="txt-purple">Modifier mes info</h4>
                <div className="col-md-12 h-100 bg-wt pb-4 " style={{ overflowY: "auto" }}>
                    < CondidatFormEdit onDataSubmit={onSubmit} loading={loading} error={error} condidat={condidat}  />
                </div>

            </div>

        </div>
    )
}


export default RegisterCondFnE;


