import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormationItem from '../../../ui/items/FormationItem';
import ExperienceItem from '../../../ui/items/ExperienceItem';
import Storage from '../../../services/Storage';


/* eslint-disable */



const PackageDetails = (props) => {

    const [selTab, setSelTab] = useState(0);

    const [date_debut, set_date_debut] = useState(null);
    const [date_fin, set_date_fin] = useState(null);

    const [onForm, setOnForm] = useState(false);
    const [user, setuser] = useState(Storage.getUserData());
    const [demande, setDemande] = useState(false);



    const onSubmit = () => {

        if (date_debut && date_fin) {

            const params = {
                condidatId: props.selItemData.id,
                clientId: user.id,
                date_debut: date_debut,
                date_fin: date_fin,

            };

            props.onDemande(params);
        }
    }

    const onAddFavoris = () => {
        props.onFavoris(props.selItemData.id);
    }


    const checkDmd = () => {
        if (props.selItemData) {
            if (props.selItemData.demande_condidats) {
                let f = props.selItemData.demande_condidats.filter(el => el.clientId == user.id)
                setDemande(f.length > 0)
            }
        }

    }

    useEffect(() => {
        setOnForm(false)
        checkDmd()
    }, [props.selItemData])






    return (
        <div className={props.showP ? "col-md-5 bg-gray inf_box_pop_r" : "col-md-5 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" style={{ textAlign: "left", background: "#212b60" }} onClick={() => props.setShowP(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

            <div className="row no-marg bg-wt">
                <div className="col-md-12 no-padd" id="tabs">
                    <nav className="bg-gray">
                        <div className="nav nav-tabs nav-fill d-none" id="nav-tab" role="tablist">
                            <button className={(selTab == 0) ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-home-tab" data-toggle="tab" role="tab" onClick={() => { setSelTab(0) }} >General</button>
                            <button className={(selTab == 1) ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-profile-tab" data-toggle="tab" role="tab" onClick={() => { setSelTab(1) }}>Education</button>
                            <button className={(selTab == 2) ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-contact-tab" data-toggle="tab" role="tab" onClick={() => { setSelTab(2) }}>Expérience</button>

                        </div>
                    </nav>
                    <div className="tab-content col-md-12" id="nav-tabContent">

                        <div className={(selTab == 0) ? "tab-pane fade show active col-md-12" : "tab-pane fade col-md-12"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                            {props.selItemData ?
                                <div className="row">
                                    <div className="col-md-12  marg-5 ">

                                        {props.checkCondFavs(props.selItemData.id) == false ?
                                            <button type="button" onClick={onAddFavoris} className="btn btn-warning mt-0" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" , color :"#fff" }} ><FontAwesomeIcon icon={['fas', 'star']} /></button>
                                            :
                                            <><b className="fnt-w5 text-warning fnt-largr ms-3"><FontAwesomeIcon icon={['fas', 'star']} /></b> <br /></>
                                        }
                                        <div className="col-md-12 txt-ctr">
                                            <img src={props.selItemData.user.img} style={{ width: "120px", height: "120px" }} className="user_img cond_item_img" alt="" />
                                        </div>

                                        <div className="col-md-12 txt-ctr mt-3 txt-purple2">
                                            <b>{props.selItemData.titre}</b>
                                        </div>
                                        <div className="col-md-12 txt-ctr mt-1 cond_item_t2">
                                            <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{props.selItemData.niv_etude}</span> -  </b>
                                            <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{props.selItemData.niv_exp}</span> </b>
                                            <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {props.selItemData.dispo}</b>



                                            {!onForm ?

                                                demande ?
                                                    <b style={{ color: "#13b08c" }}>Demande envoyé <FontAwesomeIcon icon={['fas', 'check-circle']} /> <span></span></b>
                                                    :
                                                    <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={e => setOnForm(true)}>Demander</button>



                                                :
                                                <div className="col-md-12 p-2">
                                                    <div className="row no-marg ">
                                                        <div className="col-md-6 form-group">
                                                            <label className="txt-purple2 fnt-w5">Date de debut</label>
                                                            <input type="date" className="form-control" onChange={e => set_date_debut(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label className="txt-purple2 fnt-w5">Date de fin</label>
                                                            <input type="date" className="form-control" onChange={e => set_date_fin(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 offset-md-3">
                                                            <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={onSubmit}>Demander</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            }

                                        </div>

                                    </div>

                                    <div className="col-md-12 p-4">

                                        <b className="d-block fnt-w5">Présentation</b>
                                        <p>{props.selItemData.resume}</p>
                                    </div>

                                    <div className="col-md-12 p-4">

                                        <b className="d-block fnt-w5">Compétence et technologies</b>
                                        {props.selItemData.skills.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}

                                    </div>

                                    <div className="col-md-12 p-4">

                                        <b className="d-block fnt-w5">Education</b>
                                        {props.selItemData.formations.map((s) => <FormationItem data={s} />)}
                                    </div>

                                    <div className="col-md-12 p-4">

                                        <b className="d-block fnt-w5">Experience(s)</b>
                                        {props.selItemData.experiences.map((s) => <ExperienceItem data={s} />)}
                                    </div>



                                </div>
                                :
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={(selTab == 1) ? "tab-pane fade show active" : "tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

                            <br />


                        </div>

                        <div className={(selTab == 2) ? "tab-pane fade show active" : "tab-pane fade"} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

                            <br />
                        </div>


                    </div>

                </div>
            </div>



        </div>
    )
}

export default PackageDetails;