import {
    CLEAR_CLIENTS,
    LOAD_CLIENTS, LOAD_CLIENTS_SUCCESS, LOAD_CLIENTS_FAIL,
} from "../actions/ActionTypes";

const initialState = {
    loading: false,
    error: '',
    lst_data: [],
    sel_item: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LOAD_CLIENTS:

            return {
                ...state,
                loading: true,
                lst_data: [],
            }
        case LOAD_CLIENTS_SUCCESS:

            return {
                loading: false,
                lst_data : action.payload,
                error: '',
            }
        case LOAD_CLIENTS_FAIL:

            return {
                loading: false,
                lst_data: [],
                error: action.payload,
            }
        case CLEAR_CLIENTS:

            return {
                loading: false,
                error: '',
                lst_data: [],
                sel_item: null,
            }

        default:
            return state
    }
}
export default reducer;