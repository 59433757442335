import React, { useState, useEffect } from 'react';
import banner_client from '../../../assets/img/banner_client.png'
import talent_banner from '../../../assets/img/talent_banner.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import CondidatItem from './FavorisItem';
import CondidatDetails from './CondidatDetails';
import ClientApiCall from '../../../services/api/ClientApiCall';

/* eslint-disable */

const Favoris = (props) => {


    const [data, set_data] = useState([]);
   
    const [isLoading, setIsLoading] = useState(false);

    const [showP, setShowP] = useState(false);
    const [selItemData, setSelItemData] = useState(null);
    const [net_err, set_net_err] = useState("");

  



 

    const loadData = () => {
        
        setIsLoading(true);
        ClientApiCall.getFavoris().then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data) {
                   set_data(res.data)
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }

    const onDelFavoris = (id) => {
        
        setIsLoading(true);
        ClientApiCall.delFromFavoris(id).then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data.done) {
                    loadData();
                    
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }

    const onDemande = (params) => {
        console.log(params)
        setIsLoading(true);
        ClientApiCall.newDmdCond(params).then(
            (res) => {
                setIsLoading(false);
                console.log(res)
                if (res.data.done) {
                    loadData()
                }
            }, (error) => {
                setIsLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
                console.log(message)
            }
        );
    }


    useEffect(() => {
        
        if (selItemData && data)
            data.forEach(el => {
                if (el.condidat.id == selItemData.id)
                    setSelItemData(el.condidat);
            });
    }, [data])



    useEffect(() => {
        loadData()
    }, [])



    return (

        <>
            <div className="row no-marg  ht-inh ">
                <div className="col-md-12 banner_box no-padd">
                    <img src={banner_client} className="banner_box_img" alt="" />
                    <div className="col-md-12 banner_box_effect"></div>
                    <div className="col-md-12 banner_box_content" style={{ top: "3px" }}>
                        <div className="row pt-2 ">
                        <h2 className="txt-purple2 fnt-largr"><FontAwesomeIcon icon={['fas', 'star']} /> Favoris <span className="txt-purple2"> ({data.length})</span>  </h2>

                        </div>
                    </div>
                </div>

                <div className="row bg-wt" style={{ position: "absolute", top: "120px", margin: "0px" }}>

                    {/* <div class="alert alert-secondary pt-1 pb-1 mt-2" role="alert">
                        <b>Astuce :</b> Vous pouvez cliquer sur le bouton <b><FontAwesomeIcon icon={['fas', 'plus-square']} /></b> ou sur <b>Enter</b> pour ajouter des technologies
                    </div> */}

                    {isLoading ?
                        <div class="col-md-12 p-4 txt-ctr">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        data.map((s) => <CondidatItem data={s} showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} onDelFavoris ={onDelFavoris} />)
                    }


                </div>

            </div>

            <CondidatDetails showP={showP} setShowP={setShowP} selItemData={selItemData} setSelItemData={setSelItemData} onDemande={onDemande} />

        </>
    )

}

export default Favoris;