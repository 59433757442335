//_____________________________________________________________AUTH&Signup
export const LOGIN ="LOGIN";
export const LOGIN_SUCCESS ="LOGIN_SUCCESS";
export const LOGIN_FAIL ="LOGIN_FAIL";

export const CHECKUSER ="CHECKUSER";
export const CHECKUSER_SUCCESS ="CHECKUSER_SUCCESS";
export const CHECKUSER_FAIL ="CHECKUSER_FAIL";

export const REGISTER ="REGISTER";
export const REGISTER_SUCCESS ="REGISTER_SUCCESS";
export const REGISTER_FAIL ="REGISTER_FAIL";

export const RESEND_MAIL ="RESEND_MAIL";
export const RESEND_MAIL_SUCCESS ="RESEND_MAIL_SUCCESS";
export const RESEND_MAIL_FAIL ="RESEND_MAIL_FAIL";

export const VERIF_MAIL ="VERIF_MAIL";
export const VERIF_MAIL_SUCCESS ="VERIF_MAIL_SUCCESS";
export const VERIF_MAIL_FAIL ="VERIF_MAIL_FAIL";

export const CHECK_PROFILE ="CHECK_PROFILE";
export const CHECK_PROFILE_SUCCESS ="CHECK_PROFILE_SUCCESS";
export const CHECK_PROFILE_FAIL ="CHECK_PROFILE_FAIL";

export const REGISTER_PROFILE ="REGISTER_PROFILE";
export const REGISTER_PROFILE_SUCCESS ="REGISTER_PROFILE_SUCCESS";
export const REGISTER_PROFILE_FAIL ="REGISTER_PROFILE_FAIL";

export const REGISTER_FRM ="REGISTER_FRM";
export const REGISTER_FRM_SUCCESS ="REGISTER_FRM_SUCCESS";
export const REGISTER_FRM_FAIL ="REGISTER_FRM_FAIL";

export const REGISTER_EXP ="REGISTER_EXP";
export const REGISTER_EXP_SUCCESS ="REGISTER_EXP_SUCCESS";
export const REGISTER_EXP_FAIL ="REGISTER_EXP_FAIL";

export const UPLOAD_IMG ="UPLOAD_IMG";
export const UPLOAD_IMG_SUCCESS ="UPLOAD_IMG_SUCCESS";
export const UPLOAD_IMG_FAIL ="UPLOAD_IMG_FAIL";

export const LOGOUT ="LOGOUT";
export const CLEAR_AUTH ="CLEAR_AUTH";

export const LOAD_CLIENTS ="LOAD_CLIENTS";
export const LOAD_CLIENTS_SUCCESS ="LOAD_CLIENTS_SUCCESS";
export const LOAD_CLIENTS_FAIL ="LOAD_CLIENTS_FAIL";

export const CLEAR_CLIENTS ="CLEAR_CLIENTS";



export const LOAD_CONDS ="LOAD_CONDS";
export const LOAD_CONDS_SUCCESS ="LOAD_CONDS_SUCCESS";
export const LOAD_CONDS_FAIL ="LOAD_CONDS_FAIL";

export const CLEAR_CONDS ="CLEAR_CONDS";


export const LOAD_DMD_CONDS ="LOAD_DMD_CONDS";
export const LOAD_DMD_CONDS_SUCCESS ="LOAD_DMD_CONDS_SUCCESS";
export const LOAD_DMD_CONDS_FAIL ="LOAD_DMD_CONDS_FAIL";

export const CLEAR_DMD_CONDS ="CLEAR_DMD_CONDS";


