import {
    CLEAR_CONDS ,
    LOAD_CONDS , LOAD_CONDS_SUCCESS , LOAD_CONDS_FAIL ,
} from "../actions/ActionTypes";

const initialState = {
    loading: false,
    error: '',
    lst_data: [],
    sel_item: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LOAD_CONDS:

            return {
                ...state,
                loading: true,
                lst_data: [],
            }
        case LOAD_CONDS_SUCCESS:

            return {
                loading: false,
                lst_data : action.payload,
                error: '',
            }
        case LOAD_CONDS_FAIL:

            return {
                loading: false,
                lst_data: [],
                error: action.payload,
            }
        case CLEAR_CONDS:

            return {
                loading: false,
                error: '',
                lst_data: [],
                sel_item: null,
            }

        default:
            return state
    }
}
export default reducer;