import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import UserApiCall from '../../services/api/UserApiCall';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';


const CvModal = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [net_succ, set_net_succ] = useState("")
    const [net_err, set_net_err] = useState("")


    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);
    const [img_err, set_img_err] = useState("");


    const inpt_img = useRef(null);

    const onSubmit = () => {

        setLoading(true)
        const formData = new FormData();
        formData.append('file', sel_img);

        UserApiCall.uploadUserCv(formData).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("Upload Cv avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        window.location.reload(false);
                    }, 3000);

                } else {
                    setLoading(false)
                    set_net_err("Echec  !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err(message);
            }
        );
    }

    const pickClick = () => {
        inpt_img.current.click();
    }

    const onImgChange = (e) => {
        set_img_err("")
        console.log(e.target.files[0].size)
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));

        if (e.target.files[0].size >= 2000000) {
            set_img_err("Taille max de la photo 2MB !")
        }
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter " className="txt-purple2">
                    Mettez votre CV
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="">
                {net_succ &&
                    <div class="alert alert-success" role="alert">
                        {net_succ}
                    </div>
                }
                {net_err &&
                    <div class="alert alert-danger" role="alert">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {net_err}
                    </div>
                }

                <div className="row no-marg pt-2 text-center">
                    <label className="fnt-w5 ">CV (format doc ou pdf) </label>
                    <div className="col-md-12 txt-ctr mb-2">

                        <button className="position-relative w-50 py-5" type="button" onClick={pickClick} style={{ border: "#8592dc 2px dotted", borderRadius: 4, color: "#717dc2" }}>
                            {sel_img_tmp ?
                                <h4>{sel_img?.name}</h4>
                                :
                                <h4><FontAwesomeIcon icon={['fas', 'file-alt']} /></h4>
                            }

                        </button>

                        <div class="form-group d-none">
                            <input type="file" class="form-control-file" ref={inpt_img} onChange={onImgChange} />
                        </div>
                    </div>

                    <div className="col-md-12 text-center pt-4">
                        {
                            loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <Button variant="success" onClick={onSubmit} className="w-50">
                                    Confirmer
                                </Button>
                        }

                    </div>

                </div>


            </Modal.Body>

            <Modal.Footer>

                <Button variant="secondary" onClick={props.onHide}>
                    Annuler
                </Button>


            </Modal.Footer>
        </Modal>
    );

}

export default CvModal;