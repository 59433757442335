import {
    CLEAR_DMD_CONDS ,
    LOAD_DMD_CONDS , LOAD_DMD_CONDS_SUCCESS , LOAD_DMD_CONDS_FAIL ,
} from "./ActionTypes";

import service_adm from '../../services/api/AdmApiCall';



export const clear_dmd_conds = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DMD_CONDS,
        });
    }
}

export const getDmdCondidatList = () => {

    return (dispatch) => {
        dispatch({
            type: LOAD_DMD_CONDS,
        });

        service_adm.getAllDmd().then(
            (res) => {
                if(res.data)
                    dispatch({
                        type: LOAD_DMD_CONDS_SUCCESS,
                        payload: res.data
                    });
                else
                    dispatch({
                        type: LOAD_DMD_CONDS_FAIL,
                        payload: 'Echec de récupération de données !'
                    });
                

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: LOAD_DMD_CONDS_FAIL,
                    payload: message
                });
            }
        );
    }

}