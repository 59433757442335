import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmModal = (props) => {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter " className="txt-purple2">
                    <FontAwesomeIcon icon={['fas', 'info-circle']} /> {props.title}
                </Modal.Title>
                
            </Modal.Header>
            <Modal.Body className="txt-ctr">
                <h5>{props.msg}</h5>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Non
                </Button>
                <Button variant="primary" onClick={props.onOk}>
                    Oui
                </Button>


            </Modal.Footer>
        </Modal>
    );

}

export default ConfirmModal;