import React, { useState, useEffect, useRef, useContext } from 'react';
import ChatApiCall from '../../../services/api/ChatApiCall';
import ChatList from '../../../ui/Lists/Chat.list';
import ChatBoxHeader from '../../../ui/Layouts/ChatBoxHeader.layout';
import ChatUsersList from '../../../ui/Lists/ChatUsers.list';
import ChatUsersHeader from '../../../ui/Layouts/ChatUsersHeader.layout';
import Storage from '../../../services/Storage';
import { SocketContext } from '../../../services/api/Socket';

/* eslint-disable */

const ChatAdm = (props) => {



    const [lst_users, set_lst_users] = useState([]);
    const [lst_msg, set_lst_msg] = useState([]);
    const [lst_conected, set_lst_conected] = useState([]);
    const [sel_disscution, set_sel_disscution] = useState(null);
    const [new_msg, set_new_msg] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [net_err, set_net_err] = useState("");
    const user = Storage.getUser();
    const token = Storage.getToken();
    const btm_ref = useRef(null)

    const { socketManager } = useContext(SocketContext)

    const loadUsersChat = () => {
        set_net_err("")
        setIsLoading(true)
        ChatApiCall.getUsersChat().then(
            (res) => {
                console.log(res)
                setIsLoading(false)
                if (res.data) {
                    set_lst_users(res.data.map(el => ({ ...el, new_msg: [] })))
                    socketManager.socket.emit("get_connected_lst")

                }
            }, (error) => {
                setIsLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
            }
        );
    }

    const loadDisscution = () => {
        if (sel_disscution) {
            set_net_err("")
            setIsLoading(true)
            ChatApiCall.getMsgByUser(sel_disscution.id).then(
                (res) => {
                    console.log(res)
                    setIsLoading(false)
                    if (res.data) {
                        set_lst_msg(res.data)

                        btm_ref.current.scrollIntoView({ behavior: "smooth" })
                    }
                }, (error) => {
                    setIsLoading(false)
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_net_err(message)
                }
            );
        }
    }

    const sendMsg = (e) => {
        if (e.target.value != "" && sel_disscution) {
            set_net_err("")
            setIsLoading(true)
            ChatApiCall.sendMsg({ content: e.target.value, id_to: sel_disscution.id }).then(
                (res) => {
                    console.log(res)
                    setIsLoading(false)
                    if (res.data) {

                        if (lst_conected) {

                            if (lst_conected.get(sel_disscution.id.toString()))
                                socketManager.socket.emit("notify_client_msg", { data: res.data, user: sel_disscution.id })
                        }
                        e.target.value = ""
                        loadDisscution()


                    }
                }, (error) => {
                    setIsLoading(false)
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_net_err(message)
                }
            );
        }
    }

    const selDisscution = (usr) => {
        //console.log(usr)
        set_sel_disscution(usr)
    }



    useEffect(() => {
        loadUsersChat();

    }, [])

    useEffect(() => {
        loadDisscution();
        console.log("selected user change")
        console.log(sel_disscution)
    }, [sel_disscution])

    useEffect(() => {
        console.log(lst_msg)
    }, [lst_msg])


    useEffect(() => {
        console.log("socket changed" + !socketManager.socket)
        if (socketManager.socket) {
            socketManager.socket.on('connected_lst', (data) => {
                console.log("connected_lst response")
                console.log(data)

                if (data === Object(data)) {
                    console.log("cheking object true")
                    set_lst_conected(new Map(Object.entries(data)))
                }
            })

            socketManager.socket.on('new_msg', (data) => {
                console.log("new message ")
                console.log(data)

                set_new_msg(data)



            })
        }

    }, [socketManager.socket])


    useEffect(() => {
        if (lst_conected && lst_users) {
            let arr = lst_users.map(el => {
                if (lst_conected.get(el.id.toString()))
                    return { ...el, isConnected: true }
                else
                    return { ...el, isConnected: false }
            })

            arr.sort((x, y) => {
                return (x.isConnected === y.isConnected) ? 0 : x.isConnected ? -1 : 1;
            });

            set_lst_users(arr)
        }

    }, [lst_conected])


    useEffect(() => {
        if (lst_users.length) {
            if (!sel_disscution)
                set_sel_disscution(lst_users[0])
            else
                lst_users.forEach(el => {
                    if (el.id == sel_disscution.id)
                        set_sel_disscution(el)
                });
        }

    }, [lst_users])

    useEffect(() => {
        console.log("new msg listner call ")
        console.log(lst_users.length)

        if (lst_users.length && new_msg) {

            let arr = lst_users.map(el => {
                if (el.id == new_msg.user)
                    return { ...el, new_msg: [...el.new_msg, new_msg.msg] }
                else
                    return el
            })
            console.log(arr)
            set_lst_users(arr)
        }

    }, [new_msg])





    return (

        <div className="row no-marg h-100">


            <div id="users_box" className="col-md-4 chat_users_box">

                <ChatUsersHeader loading={isLoading} />

                <ChatUsersList lst_data={lst_users} sel_disscution={sel_disscution} onItemClick={selDisscution} />
            </div>

            <div id="disscussion_box" className="col-md-8 chat_dissc_box">

                <ChatBoxHeader sel_disscution={sel_disscution} loading={isLoading} />

                <ChatList lst_data={lst_msg} user={user} btm_ref={btm_ref} onSendMsg={sendMsg} />

            </div>

        </div>

    )

}

export default ChatAdm;