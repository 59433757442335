/* eslint-disable */
import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper'
import CondForm from '../../../ui/forms/CondidatForm'
import RegisterSideBanner from '../../../ui/static/RegisterSideBanner'
import RegisterStepper from '../../../ui/static/RegisterStepper'
import RegisterHeader from '../../../ui/static/RegisterHeader'
import Storage from '../../../services/Storage'

import { UploadProfileImg, CreateCondProfile, verifProfile, clear_auth } from '../../../redux/actions/AuthActions';
import { connect } from 'react-redux';


const RegisterCondProfile = (props) => {

    const [data, setData] = useState();
    const [user, setUser] = useState(null);
    const [allow, setAllow] = useState(false);
    const [token, setToken] = useState(Storage.getToken());
    const history = useHistory();

    const goDashb = () => {
        history.push(routes.dashb);
    }

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goFnE = () => {
        history.push(routes.register_cond_fne);
    }

    const onSubmit = (data, img) => {
        setData(data);
        const formData = new FormData();
        formData.append('file', img);
        props.doUploadImg(formData)
    }

    useEffect(() => {

        if (token) {
            Storage.setToken(token);
            props.doVerifProfile()
        }else{
            goHome()
        }
        return () => {
            props.doClear()
        }
    }, [])

    useEffect(() => {
        if (props.success_check_profile) {
            if (props.user) {
                setUser(props.user);
                Storage.setToken(token);
                Storage.setUser(props.user);

                if (props.user.profile_done == false) {
                    setAllow(true)
                } else if (props.user.formation_done == false) {
                    history.push("/register/cd/educ");
                } else if (props.user.exp_done == false) {
                    history.push("/register/cd/exp");
                }else{
                    goDashb()
                }
            }
        }
    }, [props.success_check_profile, props.user])

    useEffect(() => {
        if (props.success_upload_img) {
            props.doCreateProfile(data)
        }
    }, [props.success_upload_img])

    useEffect(() => {
        if (props.success_register_profile) {
            history.push("/register/cd/educ");
        }
    }, [props.success_register_profile])


    return (
        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100 bg-wt pb-4 login-container-form" style={{ overflowY: "auto" }}>
                < RegisterHeader goHome={goHome} />
                < RegisterStepper />
                < CondForm onDataSubmit={onSubmit} loading={props.loading} error={props.error} />
            </div>

            <RegisterSideBanner />
        </div>
    )
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        success_check_profile: state.auth.success_check_profile,
        success_upload_img: state.auth.success_upload_img,
        success_register_profile: state.auth.success_register_profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doVerifProfile : () => dispatch(verifProfile()),
        doUploadImg: (img) => dispatch(UploadProfileImg(img)),
        doCreateProfile: (user) => dispatch(CreateCondProfile(user)),
        doClear: () => dispatch(clear_auth()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterCondProfile)


