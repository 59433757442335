/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_wt from '../../assets/img/logo.svg'
import bg from '../../assets/img/programmer2.png'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { routes } from '../../utils/helpers/routing.helper';

const ForgetPass = ({ onDataSubmit, loading, error, send_done, OnResendMail }) => {


    const history = useHistory();

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Vous devez saisir votre E-mail !')
            .email('Email non valid !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data)
    }




    return (
        <div className="row no-marg login-container bg-wt">


            <div className="col-md-8 h-100">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>


                </div>

              {!send_done ?

                    <div className="col-md-8 offset-md-2 " >
                        <br /> <br />
                        <h2 className="padd-10">Mot de passe oublié</h2>
                        {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">


                            <div className="form-group col-md-12">
                                <label className="fnt-w5"> E-mail</label>
                                <input placeholder="E-mail"  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.email?.message}</p>
                            </div>

                           
                            <div className="col-md-12 txt-ctr" >
                                {loading ?
                                    <div class="col-md-12 p-4 txt-ctr">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                }
                            </div>
                            <br /> <br />



                            <br />
                        </form>




                    </div>

                    :

                    (loading ?
                        <div class="col-md-12 p-4 txt-ctr">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="col-md-8 offset-md-2 txt-ctr p-4" >
                            <h1 style={{ fontSize: "xxx-large" }} className="txt-purple pt-4 barcode_icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></h1>
                            <h3>Un email de réinitialisation de mot de passe à été envoyé</h3>
                            <h4 className="txt-gray">veuillez vérifier votre boîte de réception</h4>
                            <p className="txt-gray">Je n'ai pas reçu le mail vérification</p>
                            <button type="button" className="btn btn-primary btn-login mt-4" onClick={OnResendMail} >Renvoyer le mail</button>
                        </div>
                    )
                }
            </div>

            <div className="col-md-4 bg-grad-purple1 h-100" style={{ position: "relative" }}>
                <img src={logo_wt} className="avatar_logo" alt="" />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>
    )
}


export default ForgetPass;



