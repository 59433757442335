import React from 'react';
import MessageItem from '../items/MessageItem';

const ChatList = ({ loading, lst_data, user, btm_ref , onSendMsg }) => {
    return (
        <>
            <div className="col-md-12 chat_dissc_body">
                {
                    lst_data.map((s) => <MessageItem key={s.id} data={s} mine={user.id == s.id_from} />)
                }
                <div id="msg_btm" ref={btm_ref}></div>

            </div>

            <div className="col-md-12 txt-ctr pt-1">
                <input type="text" onKeyPress={(e) => { e.key === 'Enter' && onSendMsg(e); }} placeholder="Tapez votre message ...." className="txt-ctr form-control inpt_search shadow-none form-control mt-2 " />

            </div>
        </>
    );
};

export default ChatList;