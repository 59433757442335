/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_wt from '../../assets/img/logo.svg'
import bg from '../../assets/img/programmer2.png'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { routes } from '../../utils/helpers/routing.helper';

const ForgetPass = ({ onDataSubmit, loading, error, reset_done }) => {


    const history = useHistory();

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir votre mot de passe !'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !')
            .required('Vous devez confirmez votre mot de passe !'),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data)
    }




    return (
        <div className="row no-marg login-container bg-wt">


            <div className="col-md-8 h-100">

                <div className="row no-marg">
                    <div className="col-md-2 no-padd">
                        <button className="btn btn-primary btn_back_home" onClick={goHome}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
                    </div>


                </div>

                <div className="col-md-8 offset-md-2 " >
                    <br /> <br />
                    <h2 className="padd-10">Réinitialisation de mot de passe</h2>
                    {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                    {reset_done &&
                        <div class="alert alert-success text-center" role="alert">
                            <FontAwesomeIcon icon={['fas', 'check-circle']} /> Réinitialisation de mot de passe avec succés
                        </div>
                    }

                    <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">


                        <div className="row no-marg mt-5 pt-5">

                            <div className="col-md-12 form-group ">
                                <label className="fnt-w5"> Nouveau mot de passe </label>
                                <input type="password" placeholder="Nouveau mot de passe ..."  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.password?.message}</p>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="fnt-w5"> Confirmation du nouveau mot de passe </label>
                                <input type="password" placeholder="Confirmez du nouveau mot de passe ..."  {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.confirmPassword?.message}</p>
                            </div>
                        </div>


                        <div className="col-md-12 txt-ctr" >
                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                            }
                        </div>
                        <br /> <br />



                        <br />
                    </form>




                </div>


            </div>

            <div className="col-md-4 bg-grad-purple1 h-100" style={{ position: "relative" }}>
                <img src={logo_wt} className="avatar_logo" alt="" />
                <img src={bg} className="avatar_prog" alt="" />
            </div>
        </div>
    )
}


export default ForgetPass;



