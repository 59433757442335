/* eslint-disable */
import React, { useState, useRef , useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import SkillsPicker from './Skills.from';

const Register = ({ onDataSubmit, loading, error , condidat }) => {

    const [lst_skills, set_lst_skills] = useState([]);
    const inpt_img = useRef(null);





    const validationSchema = Yup.object().shape({

        nom: Yup.string()
            .required('Vous devez saisir votre nom !')
            .min(3, 'Nom non valid ( min 3 caractères ) !'),
        prenom: Yup.string()
            .required('Vous devez saisir votre prenom !')
            .min(3, 'Prenom non valid ( min 3 caractères ) !'),
        sex: Yup.string()
            .required('Vous devez choisir votre sex !'),
        niv_etude: Yup.string()
            .required('Vous devez choisir votre niveau d\'éducation !'),
        niv_exp: Yup.string()
            .required('Vous devez choisir votre niveau d\'éxperience !'),
        date_naiss: Yup.string()
            .required('Vous devez saisir la date de naissance!'),
        resume: Yup.string(),
        titre: Yup.string()
            .required('Vous devez saisir votre titre de profile !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        dispo: Yup.string()
            .required('Vous devez saisir la date de disponibilité !')


    });



    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState  , setValue} = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = data => {

        if (lst_skills.length > 0) {
            const data_inf = { ...data , skills: lst_skills }
            onDataSubmit(data_inf )
        }

    };

    useEffect(()=>{
        if(condidat){
            setValue("sex", condidat.sex);
            setValue("niv_etude", condidat.niv_etude);
            setValue("niv_exp", condidat.niv_exp);
            setValue("date_naiss", condidat.date_naissance);
            setValue("resume", condidat.resume);
            setValue("titre", condidat.titre);
            setValue("dispo", condidat.dispo);
            set_lst_skills(condidat.skills);
            

            if(condidat.user){
                setValue("nom", condidat.user.nom);
                setValue("prenom", condidat.user.prenom);
              
            }
        }

    },[condidat])


  



    return (
        <div className="row ">

            <div className="col-md-12 h-100 pt-4">


                <div className="col-md-12 p-0" >

                        <form key={1} onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                            {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}



                            <div className="row">

                                <div className="col-md-6 form-group ">
                                <label className="fnt-w5" >Nom</label>
                                    <input type="text" placeholder="Nom"  {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                    <p className="text-danger">{errors.nom?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                <label className="fnt-w5" >Prenom</label>
                                    <input type="text" placeholder="Prenom"  {...register("prenom")} className={`form-control ${errors.prenom ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                    <p className="text-danger">{errors.prenom?.message}</p>
                                </div>
                            </div>


                            <div className="row">

                                <div className="form-group col-md-6">
                                    <label className="fnt-w5">Sex</label>
                                    <Form.Control as="select"  {...register("sex")} className={`form-control ${errors.sex ? 'is-invalid' : 'force-apparence'}`}  >
                                        <option value="">Choisissez votre sex …</option>
                                        <option value="male">Homme</option>
                                        <option value="female">Femme</option>
                                    </Form.Control>
                                    <p className="text-danger">{errors.sex?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                    <label className="fnt-w5">Date de naissance</label>
                                    <input type="date" {...register("date_naiss")} className={`form-control ${errors.date_naiss ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                    <p className="text-danger">{errors.date_naiss?.message}</p>
                                </div>
                            </div>

                            <div className="form-group col-md-12">
                                <label className="fnt-w5">Présentation</label>
                                <textarea class="form-control" {...register("resume")} className={`form-control ${errors.resume ? 'is-invalid' : 'force-apparence'}`} placeholder="Présentez vous en quelque mots ..." rows="3"></textarea>
                                <p className="text-danger">{errors.resume?.message}</p>
                            </div>


                            <div className="row">

                                <div className="form-group col-md-6">
                                    <label className="fnt-w5" >Niveau d'étude</label>
                                    <Form.Control as="select"  {...register("niv_etude")} className={`form-control ${errors.niv_etude ? 'is-invalid' : 'force-apparence'}`}  >
                                        <option value="">Choisissez votre niv …</option>
                                        <option value="secondaire">secondaire</option>
                                        <option value="bac+3">bac+3 (Licence, licence professionnelle)</option>
                                        <option value="bac+4">bac+4 (Maîtrise, master 1)</option>
                                        <option value="bac+5">bac+5 (Master , ingénieur)</option>
                                        <option value="bac+8">bac+8 (Doctorat)</option>
                                    </Form.Control>
                                    <p className="text-danger">{errors.niv_etude?.message}</p>
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="fnt-w5" >Année(s) d'éxperience</label>
                                    <Form.Control as="select"  {...register("niv_exp")} className={`form-control ${errors.niv_exp ? 'is-invalid' : 'force-apparence'}`}  >
                                        <option value="">Choisissez votre Ann …</option>
                                        <option value="<1"> moins d'un d'an</option>
                                        <option value="1">1 an(s)</option>
                                        <option value="2">2 an(s)</option>
                                        <option value="3">3 an(s)</option>
                                        <option value="4">4 an(s)</option>
                                        <option value="5">5 an(s)</option>
                                        <option value="6">6 an(s)</option>
                                        <option value="7">7 an(s)</option>
                                        <option value="8">8 an(s)</option>
                                        <option value="9">9 an(s)</option>
                                        <option value="10">10 an(s)</option>
                                        <option value=">10">plus que 10 an(s)</option>


                                    </Form.Control>
                                    <p className="text-danger">{errors.niv_exp?.message}</p>
                                </div>


                            </div>


                            <div className="row">

                                <div className="col-md-12 form-group ">
                                    <label className="fnt-w5">Titre du profil</label>
                                    <input type="text" placeholder="titre ..."  {...register("titre")}
                                        className={`form-control ${errors.titre ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                    <p className="text-danger">{errors.titre?.message}</p>
                                </div>
                            </div>





                            <SkillsPicker lst_skills={lst_skills} set_lst_skills={set_lst_skills} />
                            {/* <div class="alert alert-secondary pt-1 pb-1 mt-2" role="alert">
                                <b>Astuce :</b> Vous pouvez aussi cliquer sur <b>Enter</b> pour ajouter une compétence
                            </div> */}

                            <div className="row mt-3">

                                <div className="col-md-12 form-group ">
                                    <label className="fnt-w5">Date de disponibilité</label>
                                    <input type="date" {...register("dispo")} className={`form-control ${errors.dispo ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.dispo?.message}</p>
                                </div>
                            </div>

                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :

                                <div className="col-md-12 txt-ctr" >
                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                </div>
                            }


                        </form>

                    


                </div>

            </div>
        </div>
    )
}

export default Register;


