import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterStepper = () => {

    return (
        <div className="row no-marg">
            <div className="col-md-12">
                <nav className="k-stepper">
                    <ol className="k-step-list">
                        <li className="k-step">
                            <a href="" className="k-step-link" tabindex="0" aria-current="step">
                                <span className="k-step-indic">
                                    <b className="k-icon"><FontAwesomeIcon icon={['fas', 'user-plus']} /></b>
                                </span>
                                <span className="k-label">
                                    <b className="fnt-w5">inscription</b>
                                </span>
                            </a>

                        </li>
                        <li className="k-step">
                            <a href="" className="k-step-link" tabindex="-1" >
                                <span className="k-step-indic ">
                                    <b className="k-icon"><FontAwesomeIcon icon={['fas', 'envelope-open-text']} /></b>
                                </span>
                                <span className="k-label">
                                    <b className="fnt-w5">validation mail</b>
                                </span>
                            </a>

                        </li>
                        <li className="k-step">
                            <a href="" className="k-step-link" tabindex="-1" >
                                <span className="k-step-indic ">
                                    <b className="k-icon"><FontAwesomeIcon icon={['fas', 'user']} /></b>
                                </span>
                                <span className="k-label">
                                    <b className="fnt-w5">profil</b>
                                </span>
                            </a>

                        </li>
                        <li className="k-step">
                            <a href="" className="k-step-link" tabindex="-1" >
                                <span className="k-step-indic not-done">
                                    <b className="k-icon"><FontAwesomeIcon icon={['fas', 'check']} /></b>
                                </span>
                                <span className="k-label">
                                    <b className="fnt-w5">commencer</b>
                                </span>
                            </a>

                        </li>
                    </ol>

                    <div className="k-stepper-line"></div>
                </nav>
            </div>


        </div>

    )
}

export default RegisterStepper;