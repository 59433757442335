import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CondApiCall from '../../../services/api/CondApiCall';
import OfferList from '../../../ui/Lists/OfferCond.list';
import OfferToolBox from '../../../ui/Lists/OfferCond.toolbox';
import { getStringDate } from '../../../utils/helpers/date.helper';
import offer_banner from '../../../assets/img/offer_banner.svg'

const MesDemandes = (props) => {

    const history = useHistory();
    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);

    const [search, setSearch] = useState("")


    const onFilter = (f) => {

    }

    const onItemDetailsClick = (id) => {
        history.push("/dashb/offre/" + id)
    }



    const loadData = () => {
        set_loading(true);
        CondApiCall.getMyDmdOffer().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_lst_data_src(res.data)
                    set_lst_data(res.data)

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }



    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])









    return (
        <div className="row no-marg ">


            {/* -------------------Header--------------------------------------- */}

            <div className="col-md-12 banner_box no-padd mb-3">
                <img src={offer_banner} className="banner_box_img" alt="" style={{ width: "20%", top: "-40%" }} />
                <div className="col-md-12 banner_box_effect"></div>
                <div className="col-md-12 banner_box_content">
                    <div className="row nom-marg">
                        <div className="col-md-12">
                            <h4 className="txt-purple2 fnt-larg"><FontAwesomeIcon icon={['fas', 'briefcase']} /> Mes propositions <span className="txt-purple2"> ({lst_data.length})</span>  </h4>

                        </div>

                    </div>

                </div>
            </div>






            {/* -------------------body--------------------------------------- */}

            <div className="col-md-12 bg-wt">

                {loading ?
                    <div class="col-md-12 p-4 txt-ctr">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    lst_data.map((data, index) =>
                        <div className="col-md-12 ps-3 pe-3" key={`ofr_.${index}`}>
                            <div className="row no-marg bg-wt item_box pt-1 pb-1">

                                <div className="col-md-1">
                                    <h3 className="icon"><FontAwesomeIcon icon={['fas', 'briefcase']} /></h3>
                                </div>

                                <div className="col-md-8">
                                    <b className="d-block fnt-larg">{data.offre.titre}</b>
                                    <b className="fnt-w4 fnt-sm txt-gray">de {getStringDate(data.offre.date_debut)} jusqu'à {getStringDate(data.offre.date_fin)}</b>
                                    {/* <b className="float-end fnt-w5">{data.budget} €</b> */}
                                    <div className="d-block">
                                        {data && data.offre.tech.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}
                                    </div>
                                </div>

                                <div className="col-md-2  dvdr-l-gray ">
                                    <b className="d-block fnt-sm">Etat  </b>
                                    <b className="d-block "><span class="badge bg-success fnt-w5">{data.offre.etat}</span> </b>


                                </div>

                                <div className="col-md-1">
                                    <button className="box_item_btn fnt-med btn_eff_purple" onClick={e => onItemDetailsClick(data?.offre?.id)}>
                                voir plus
                                    </button>
                                </div>
                            </div>

                        </div>



                    )
                }

            </div>




        </div>

    );
};

export default MesDemandes;