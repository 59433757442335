/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner_client from '../../../assets/img/banner_client.png'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import ClientApiCall from '../../../services/api/ClientApiCall';


const OffreForm = (props) => {

    const [net_err, set_net_err] = useState("");
    const [net_succ, set_net_succ] = useState("");
    const [loading, setLoading] = useState(false);
    const [lst_tech, set_lst_tech] = useState([]);

    const [id, setId] = useState(props.match.params.id);
    const [data_offre, set_data_offre] = useState();
    const history = useHistory();

    const [tech_value, set_tech_value] = useState("");




    const validationSchema = Yup.object().shape({
        titre: Yup.string()
            .required('Vous devez saisir le tittre !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        description: Yup.string()
            .required('Vous devez saisir la decription de l\'offre !')
            .min(10, 'non valid ( min 200 caractères ) !'),
        budget: Yup.number()
            .typeError('le budget doit être un nombre en €')
            .required('Vous devez choisir le TJM !')
            .test(
                'TJM valid',
                'TJM non valid',
                value => (value + "").match(/^\d{1,9}?$/),
            ),
        date_debut: Yup.string()
            .required('Vous devez saisir la date de debut!'),
        date_fin: Yup.string()
            .required('Vous devez saisir la date de fin!')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        set_net_err("")
        set_net_succ("")
        if (lst_tech.length) {

            if (props.editMode)
                save_info({ ...data, tech: lst_tech, id: id })
            else
                create_info({ ...data, tech: lst_tech })
        } else {
            set_net_err("Vous devez saisir les technologie(s) requis")
        }
    };

    const create_info = (data) => {
        setLoading(true)
        ClientApiCall.createOffre(data).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("Creation avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        history.push("/dashb/mesoffres");
                    }, 2000);

                } else {
                    setLoading(false)
                    set_net_err("Echec de la creation de l'offre !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err(message);
            }
        );
    }
    const save_info = (data) => {
        setLoading(true)
        ClientApiCall.saveOffre(data).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("votre modification a été enregistrée avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        history.push("/dashb/mesoffres/" + id);
                    }, 1000);

                } else {
                    setLoading(false)
                    set_net_err("Echec de la modification de l'offre !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err("Echec de la modification de l'offre !");
            }
        );
    }

    const onSkillItem = (item) => {
        set_lst_tech(lst_tech.filter(elm => (elm != item)));
    }

    const onSkillAdd = (e) => {
        e.preventDefault();
        if (e.target.value != "" && lst_tech.filter(elm => (elm == e.target.value)).length == 0) {
            set_lst_tech([...lst_tech, e.target.value]);
            set_tech_value("")
        }
    }

    const onAddClick = () => {

        if (tech_value != "" && lst_tech.filter(elm => (elm == tech_value)).length == 0) {
            set_lst_tech([...lst_tech, tech_value]);
            set_tech_value("")
        }
    }

    const loadData = () => {
        setLoading(true);
        ClientApiCall.getOffreDetails(id).then(
            (res) => {
                setLoading(false);
                console.log(res)
                if (res.data) {
                    set_data_offre(res.data)
                    // setValue([
                    //     {titre : res.data.titre} ,
                    //     {description : res.data.description} ,
                    //     {budget : res.data.budget} ,
                    //     {date_debut : res.data.date_debut} ,
                    //     {date_fin : res.data.date_fin} ,

                    // ])

                    setValue("titre", res.data.titre)
                    setValue("description", res.data.description)
                    setValue("budget", res.data.budget)
                    setValue("date_debut", res.data.date_debut)
                    setValue("date_fin", res.data.date_fin)

                    set_lst_tech(res.data.tech)

                }
            }, (error) => {
                setLoading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
            }
        );
    }


    useEffect(() => {
        console.log(data_offre);
        if (props.editMode && id) {
            loadData()
        }

    }, [])


    return (
        <div className="row no-marg bg-wt">

            <div className="col-md-12 banner_box no-padd" style={{ marginTop: "-4%" }}>
                <img src={banner_client} className="banner_box_img" alt="" />
                <div className="col-md-12 banner_box_effect"></div>
                <div className="col-md-12 banner_box_content">
                    {props.editMode ?
                        <h3>Edit Offre </h3>
                        :
                        <h3>Nouvelle Offre </h3>
                    }
                </div>
            </div>

            <div className="col-md-12 ">

                <form onSubmit={handleSubmit(onSubmit)} className="row no-marg pt-2">


                    <div className="col-md-6  text-end" >
                        {net_err && <p className="text-danger pl-4 fnt-w4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {net_err}</p>}
                        {net_succ &&
                            <div class="alert alert-success text-center" role="alert">
                                <FontAwesomeIcon icon={['fas', 'check-circle']} /> {net_succ}
                            </div>
                        }
                    </div>
                    <div className="col-md-6  text-end" >
                        {loading ?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>

                            :
                            <button type="submit" className="btn btn-primary btn-login " >Confirmer</button>
                        }
                    </div>

                    <div className="col-md-6">

                        <div className="row">

                            <div className="col-md-12 form-group ">
                                <label className="fnt-w5">Titre </label>
                                <input type="text" placeholder="Titre de l'offre"  {...register("titre")} className={`form-control ${errors.titre ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.titre?.message}</p>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="fnt-w5">TJM ( en € ) </label>
                                <input type="text"   {...register("budget")} className={`form-control ${errors.budget ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.budget?.message}</p>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6 form-group">
                                <label className="fnt-w5">Date de debut</label>
                                <input type="date" {...register("date_debut")} className={`form-control ${errors.date_debut ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_debut?.message}</p>
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="fnt-w5">Date de fin</label>
                                <input type="date" {...register("date_fin")} className={`form-control ${errors.date_fin ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_fin?.message}</p>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12 form-group ">
                                <label className="fnt-w5">Les technologie(s)</label>

                                <div className="col-md-12 skills_box  p-2">
                                    {lst_tech.map((s) => <button className="btn_skill_item" type="button" onClick={e => onSkillItem(s)}>{s} <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button>)}
                                </div>
                                <input type="text" placeholder="skill ..." value={tech_value} onChange={e => set_tech_value(e.target.value)} className="form-control skill_inpt" onKeyPress={(e) => { e.key === 'Enter' && onSkillAdd(e); }} />
                                <button className="btn btn-primary" type="button" onClick={onAddClick} style={{ margin: "0px", marginTop: "-4px", borderRadius: "0px", height: "37px" }} >
                                    <FontAwesomeIcon icon={['fas', 'plus']} />
                                </button>

                                <div class="alert alert-secondary pt-1 pb-1 mt-2" role="alert">
                                    <b>Astuce :</b> Tapez <b>Entrer</b> après la saisie de chaque compétence 
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="form-group col-md-6">
                        <label className="fnt-w5">Description</label>
                        <textarea class="form-control" {...register("description")} className={`form-control ${errors.description ? 'is-invalid' : 'force-apparence'}`} placeholder="description de l'offre ..." rows="16"></textarea>
                        <p className="text-danger">{errors.description?.message}</p>
                    </div>










                </form>




            </div>

        </div>

    )
}

export default OffreForm;


