import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FormationItemForm = (props) => {


    return (
        <div className="row bg-wt frm_box_item">


            <div className="col-md-12 ">
                <b className="ecole">{props.data.ecole}</b>
                <button type="button" onClick={e => props.onRemove(props.id)} className="btn btn-default btn_round_notif p-0 txt-purple btn_mini float-end">
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </button>
            </div>
            <div className="col-md-12">
                <b className="fnt-w5">{props.data.nom}</b>
            </div>
            <div className="col-md-12 ">
                <b className="date">{props.data.date_debut} - {props.data.date_fin}</b>
            </div>
        </div>
    )
}

export default FormationItemForm;


