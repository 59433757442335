/* eslint-disable */
import React, {  useState , useEffect} from 'react';
import {registerClient , registerCond , clear_auth , resendMail } from '../../../redux/actions/AuthActions';
import {connect} from 'react-redux';
import RegisterForm from '../../../ui/forms/Register.form';

const Register = (props) => {
    const [register_done, set_register_done] = useState(false);

    

    const onSubmit = data => {
        
        if (data.type_cmpt == "client") {

            props.doRegisterClient(data);

        } else {
            props.doRegisterCond(data)

        }

    }

  

    useEffect(()=>{
        return ()=>{
            props.doClear()
        }
    },[])

    useEffect(()=>{
        if(props.success_register){
            set_register_done(true);
        }
    },[props.success_register])

    useEffect(()=>{
        if(props.success_resend_mail){
            
        }
    },[props.success_resend_mail])




    return (
        <RegisterForm onDataSubmit={onSubmit} OnResendMail={props.doResendMail}  loading={props.loading} error={props.error} register_done={register_done} {...props} />
    )
}


const mapStateToProps = state => {
    return{
        loading : state.auth.loading ,
        error : state.auth.error ,
        success_register : state.auth.success_register,
        success_resend_mail : state.auth.success_resend_mail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doRegisterClient : (user)=> dispatch(registerClient(user)),
        doRegisterCond : (user)=> dispatch(registerCond(user)),
        doClear : ()=> dispatch(clear_auth()),
        doResendMail : ()=> dispatch(resendMail()),
    }
}

export default connect(
    mapStateToProps ,
    mapDispatchToProps
)(Register)




