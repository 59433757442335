import React, { useState, useEffect } from 'react';
import ClientList from '../../../ui/Lists/Clients.list';
import ClientToolBox from '../../../ui/Lists/Clients.toolbox';
import { connect } from 'react-redux';
import { clear_clients, getClientList } from '../../../redux/actions/ClientsAction';
import ClientDetails from './ClientDetails.container';
import AdmApiCall from '../../../services/api/AdmApiCall';
import ConfirmModal from '../../../ui/modals/ConfirmModal';

/* eslint-disable */

const ClientUsers = (props) => {



    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [sel_item_data, set_sel_item_data] = useState(null);
    const [showP, setShowP] = useState(false);
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);

    //Modal valider confirm
    const [md_title_val, setMd_title_val] = useState("");
    const [md_msg_val, setMd_msg_val] = useState("");
    const [modalShow_val, setModalShow_val] = useState(false);
    const handleClose_val = () => setModalShow_val(false);

    //Modal invalider confirm
    const [md_title_inv, setMd_title_inv] = useState("");
    const [md_msg_inv, setMd_msg_inv] = useState("");
    const [modalShow_inv, setModalShow_inv] = useState(false);
    const handleClose_inv = () => setModalShow_inv(false);


    const onItemDetailsClick = (data) => {
        set_sel_item_data(data);
        setShowP(true);
    }

    useEffect(() => {
        props.doGetClients()

        return () => {
            props.doClearClients()
        }
    }, [])

    useEffect(() => {
        set_lst_data(props.lst_data)
        set_lst_data_src(props.lst_data)

    }, [props.lst_data])


    const onAskValider = () => {
        setMd_title_val("Validation du compte client");
        setMd_msg_val("Voulez-vous valider ce client ?")
        setModalShow_val(true)
    }

    const onAskInvalider = () => {
        setMd_title_inv("Invalider le compte client");
        setMd_msg_inv("Voulez-vous invalider ce client ?")
        setModalShow_inv(true)
    }

    const doValidateUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.validateUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetClients()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }
    const doInValidateUser = () => {
        handleClose_inv()
        set_loading(true);
        AdmApiCall.invalidateUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetClients()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const onSearch = (s)=>{
        console.log("search for "+s)
        if(s == ""){
            set_lst_data(lst_data_src)
        }else{
            
            set_lst_data(lst_data_src.filter(elem => (elem.nom_entreprise.toLowerCase().includes(s.toLowerCase()) || elem.user.nom.toLowerCase().includes(s.toLowerCase()) || elem.user.prenom.toLowerCase().includes(s.toLowerCase()) ) ))
        }

    }

    const doBanUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.banUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetClients()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doUnBanUser = () => {
        handleClose_val()
        set_loading(true);
        AdmApiCall.unBanUser(sel_item_data.user.id).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    setShowP(false)
                    props.doGetClients()

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }




    return (
        <>
            <div className="row no-marg">
                <ClientToolBox dataCount={lst_data.length} onSearch={onSearch} />
                <ClientList lst_data={lst_data} loading={props.loading} onItemDetailsClick={onItemDetailsClick} />

            </div>

            <ClientDetails showP={showP} setShowP={setShowP} data={sel_item_data} loading={loading} onValider={onAskValider} onInvalider={onAskInvalider} onBan={doBanUser} onUnban={doUnBanUser} />
            <ConfirmModal title={md_title_val} msg={md_msg_val} onOk={doValidateUser} onHide={handleClose_val} show={modalShow_val} />
            <ConfirmModal title={md_title_inv} msg={md_msg_inv} onOk={doInValidateUser} onHide={handleClose_inv} show={modalShow_inv} />

        </>
    )

}

const mapStateToProps = state => {
    return {
        loading: state.clients.loading,
        error: state.clients.error,
        lst_data: state.clients.lst_data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doClearClients: () => dispatch(clear_clients()),
        doGetClients: () => dispatch(getClientList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientUsers)

