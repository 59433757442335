/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { SocketContext, socketManager } from '../../../services/api/Socket';
import logo from '../../../assets/img/logo_bl.png'
import staff_icon from '../../../assets/img/staff_icon.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Route, NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Storage from '../../../services/Storage';
import AdmApiCall from '../../../services/api/AdmApiCall';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'


import HomeAdm from './HomeAdm';
import ClientUsers from '../accounts/ClientUsers.container';
import CondidatUsers from '../accounts/CondidatsUsers.container';
import DemandeCond from '../demande_condidat/DmdCondidats.container';
import ChatAdm from '../chat/ChatAdm.container';
import Offre from '../offres/Offres.container';
import OffreDetails from '../offres/OffreDetails.container';
import Missions from '../missions/Missions.container';
import ContactUs from '../contactus/Contactus.container';
import notif_sfx from '../../../assets/sound/notif.ogg';

const DashboardAdm = ({ match }) => {

    const [isOpen, setIsopen] = useState(true);
    const user = Storage.getUser();
    const token = Storage.getToken();
    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(0);

    const notif_sfx_player = new Audio(notif_sfx);

    const logout = () => {

        Storage.logout();
        history.push("/login");
    }

    const loadNotifs = () => {
        set_loading(true);
        AdmApiCall.getMyNotif().then(
            (res) => {
                set_loading(false);
                if (res.data) {
                    set_lst_notif(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const setNotifRead = () => {
        set_loading(true);
        AdmApiCall.setNotifRead().then(
            (res) => {
                set_loading(false);
                loadNotifs()
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const renderNotifIcon = (type) => {
        if (type == "msg")
            return <b><FontAwesomeIcon icon={['fas', 'comment-dots']} /></b>
        else
            return <b><FontAwesomeIcon icon={['fas', 'bell']} /></b>
    }


    useEffect(() => {
        //console.log('check socket connection : '+socket.connected);
        socketManager.doConnect(token)

        socketManager.socket.on('notif', function (msg) {
            console.log(msg)
            loadNotifs()
        });

        loadNotifs()

        return () => {
            socketManager.socket.disconnect()
        }
    }, [])

    useEffect(()=>{

        if(lst_notif){
            let nbr = lst_notif.filter(el => el.read == false && el ).length ;
            set_nbr_notif_unread(nbr);

            if(nbr > 0){
                notif_sfx_player.play()
            }
        }

    } , [lst_notif])

    return (

        <>

            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" data-toggle="collapse" data-target=".main-sidebar" role="button"
                        onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>
                <div className="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px" }}>
                    <ul className="nav navbar-nav">

                        <li className="nav-item ">

                            <Dropdown drop="down" onToggle={setNotifRead} >
                                <Dropdown.Toggle variant="default txt-purple fnt-largr  no-arrow btn_round_notif p-0" id="dropdown-basic" >
                                    <FontAwesomeIcon icon={['fas', 'bell']} />
                                    {nbr_notif_unread > 0 &&
                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fnt-sm mt-2">
                                            {nbr_notif_unread}
                                    </span>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px" }}  style={{ width: "400px" , maxHeight : "300px" , overflowY : "scroll"}} className="blue_scrollb">

                                    {lst_notif.map((ntf) =>
                                        <>
                                            <Dropdown.Item href={ntf.url}>
                                                <div className="row no-marg">
                                                    <div className="col-md-1 txt-dark-bl fnt-largr p-0">
                                                        {renderNotifIcon(ntf.titre)}
                                                    </div>
                                                    <div className="col-md-10 p-0">
                                                        <p style={{ whiteSpace: "normal" }} className="mb-0">
                                                            <div dangerouslySetInnerHTML={{ __html: ntf.content }} />

                                                            <b className="d-block txt-gray fnt-sm fnt-w5"> {getStringOnlyDate(ntf.createdAt)}</b>
                                                        </p>
                                                    </div>
                                                </div>

                                            </Dropdown.Item>
                                            <NavDropdown.Divider />
                                        </>
                                    )

                                    }



                                </Dropdown.Menu>
                            </Dropdown>


                        </li>


                    </ul>
                </div>


            </nav>

            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12">
                        <a href={`${match.path}/`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={logo} alt="" />
                        </a>
                    </div>



                    <div className={isOpen ? "user-panel" : "user-panel hidebal"} >
                        <div className="row m-0 user-apnel-cont">

                            <div className="col-md-12 txt-ctr">
                                <img src={staff_icon} className="user_img" alt="" />

                            </div>
                            <div className="col-md-12 user-panel-usr-data">
                                <b>admin admin <FontAwesomeIcon icon={['fas', 'circle']} color="#4CAF50" size="xs" /></b>
                                <p> <b className="fnt-w5 d-block">admin@gmail.com</b>({user && user.role})  </p>
                            </div>
                        </div>

                    </div>

                    <ul className="sidebar-menu">
                        <li>
                            <NavLink to={`${match.path}/`} activeClassName="activel" exact ><FontAwesomeIcon icon={['fas', 'home']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Accueil
                                </span>

                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/dashb/profile" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'id-badge']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Profile
                                </span></NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/dashb/acc/cl" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'user-tie']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Client(s)
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/acc/cd" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'user-astronaut']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Candidat(s)
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/acc/dmd" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'briefcase']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Demandes Candidat(s)
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/mission" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'id-card-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Les mission(s)
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/offre" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'briefcase']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Les offres
                                </span></NavLink>
                        </li>



                        <li>
                            <NavLink to="/dashb/chat" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'comment-dots']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Chat
                                </span></NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/contact" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'comment-dots']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;contactez nous
                                </span></NavLink>
                        </li>

                        {/* <li>
                            <NavLink to="/dashb/myresume" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'cogs']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Paramétres
                                </span></NavLink>
                        </li> */}








                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={logout}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Déconnecter
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>


            <div className={isOpen ? "rw-cont bg-wt" : "rw-cont rw-cont-mini bg-wt"}>

                <SocketContext.Provider value={{ socketManager }}>
                    <Route
                        path={`${match.path}`}
                        render={({ match: { url } }) => (
                            <>

                                <Route path={`${url}/`} component={HomeAdm} exact />
                                <Route path={`${url}/acc/cl`} component={ClientUsers} exact />
                                <Route path={`${url}/acc/cd`} component={CondidatUsers} exact />
                                <Route path={`${url}/acc/dmd`} component={DemandeCond} exact />
                                <Route path={`${url}/chat`} component={ChatAdm} exact />
                                <Route path={`${url}/offre`} component={Offre} exact />
                                <Route path={`${url}/offre/:id`} component={OffreDetails} exact />
                                <Route path={`${url}/mission`} component={Missions} exact />
                                <Route path={`${url}/contact`} component={ContactUs} exact />


                            </>
                        )}
                    />
                </SocketContext.Provider>
            </div>

        </>
    )

}

export default DashboardAdm;