import React from 'react';
import img_default from '../../assets/img/img_default.png'

const ChatBoxHeader = ({ loading }) => {
    return (
        <div className="col-md-12 p-2 chat_users_box_header">
            <div className="row">
                <h4 className="col-md-11 txt_blk ">Chat(s)   </h4>
                {loading &&
                    <div className="col-md-1 txt-ctr">
                        <div className="spinner-border spinner-border-sm ms-auto" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
            </div>

            <input type="text" placeholder="Recherche ...." className="txt-ctr form-control inpt_search shadow-none form-control mt-2 " />

        </div>
    );
};

export default ChatBoxHeader;