import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = (props) => {
    const itemClicked = () => {
        props.setSelItemData(props.data.condidat);
        props.setShowP(true);
    }

    return (

        <>
            <div className="col-md-3 p-4">
                <div className="row cond_item p-2" >
                    <div className="col-md-12 text-end">
                    <button className="btn_round" onClick={e => props.onDelFavoris(props.data.id)}> <FontAwesomeIcon icon={['fas', 'trash-alt']} /> </button>

                    </div>
                    
                    <div className="col-md-12 txt-ctr">
                        <img src={props.data.condidat.user.img} className="user_img cond_item_img" alt="" />
                    </div>

                    <div className="col-md-12 txt-ctr mt-3 txt-purple2" style={{height:"50px" ,overflow:"hidden"}} data-toggle="tooltip" title={props.data.titre} >
                    
                        <b >{props.data.condidat.titre}</b>
                     
                    </div>
                    <div className="col-md-12 txt-ctr mt-1 cond_item_t2">
                        <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{props.data.condidat.niv_etude}</span> -  </b>
                        <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{props.data.condidat.niv_exp} an(s)</span> </b>
                        <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {props.data.condidat.dispo ? props.data.condidat.dispo :'-'}</b>

                    </div>

                    <div className="col-md-12 txt-ctr mt-3 dvdr-t-gray" >
                       
                       
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={itemClicked}>voir plus</button>

                        </div>
                    </div>

                </div>

            </div>

        </>
    )

}

export default Home;