/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper'
import ClientForm from '../../../ui/forms/Client.form'
import RegisterSideBanner from '../../../ui/static/RegisterSideBanner'
import RegisterStepper from '../../../ui/static/RegisterStepper'
import RegisterHeader from '../../../ui/static/RegisterHeader'
import Storage from '../../../services/Storage'

import { UploadProfileImg, CreateClientProfile, clear_auth, verifProfile } from '../../../redux/actions/AuthActions';
import { connect } from 'react-redux';


const RegisterClientProfile = (props) => {

    const [data, setData] = useState();
    const [user, setUser] = useState(null);
    const [allow, setAllow] = useState(false);
    const [token, setToken] = useState(Storage.getToken());
    const history = useHistory();

    const goDashb = () => {
        history.push(routes.dashb);
    }

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }

    const onSubmit = (data, img) => {
        setData(data);
        const formData = new FormData();
        formData.append('file', img);
        props.doUploadImg(formData)
    }

    useEffect(() => {

        if (token) {
            Storage.setToken(token);
            props.doVerifProfile()
        } else {
            goHome()
        }
        return () => {
            props.doClear()
        }
    }, [])

    useEffect(() => {
        if (props.success_check_profile) {
            if (props.user) {
                setUser(props.user);
                Storage.setToken(token);
                Storage.setUser(props.user);

                if (props.user.profile_done) {
                    goDashb();
                } else {
                    setAllow(true)
                }
            }
        }
    }, [props.success_check_profile, props.user])

    useEffect(() => {
        if (props.success_upload_img) {
            props.doCreateProfile(data)
        }
    }, [props.success_upload_img])

    useEffect(() => {
        if (props.success_register_profile) {
            goDashb()
        }
    }, [props.success_register_profile])


    return (
        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100 bg-wt pb-4 login-container-form" style={{ overflowY: "auto" }}>
                < RegisterHeader goHome={goHome} />
                < RegisterStepper />
                {allow &&
                    < ClientForm onDataSubmit={onSubmit} loading={props.loading} error={props.error} />
                }
                {!allow && props.error && 
                    <p className="text-danger">{props.error}</p>
                }
                {!allow && props.loading &&
                    <div class="col-md-12 p-4 txt-ctr">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                }
            </div>

            <RegisterSideBanner />
        </div>
    )
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        success_check_profile: state.auth.success_check_profile,
        success_upload_img: state.auth.success_upload_img,
        success_register_profile: state.auth.success_register_profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doVerifProfile: () => dispatch(verifProfile()),
        doUploadImg: (img) => dispatch(UploadProfileImg(img)),
        doCreateProfile: (user) => dispatch(CreateClientProfile(user)),
        doClear: () => dispatch(clear_auth()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterClientProfile)


