import React from 'react';
import MissionItem from '../items/MissionItem';

const MissionList = ({loading ,lst_data , onItemDetailsClick , onEdit , onDel}) => {
    return (
        <div className="col-md-12 bg-wt">

            {loading ?
                <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                :
                lst_data.map((s , index) => <MissionItem key={`miss_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} onEditClick={onEdit} onDelClick={onDel} />)
            }

        </div>
    );
};

export default MissionList;