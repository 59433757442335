import axios from "axios";
import api_url from '../Config';
import storage from '../Storage';

class UserService {



    newClientInf(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/newclinf`, data, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getClientByUser(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/getUserClientInf`, { id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    searchmatch(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/searchmatch`, data, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    newDmdCond(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/newDmdCond`, data, {
            headers: {
                'sb-tkn': token
            }
        });
    }



    createOffre(data) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/createOffre`, {...data , client_id : id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getMyOffre(data) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/getMyOffre`, { ...data, id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    searchMyOffre(data) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/searchMyOffre`, { ...data, id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getOffreDetails(id) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let cl_id = 0;
        if (clientData)
            cl_id = clientData.id;

        return axios.post(`${api_url}/api/cl/offreinfo`, { id: id, client_id: cl_id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getMyProfile() {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/myprofile`, { id: id  }, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    updateMyProfile(data) {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/edtmyprofile`, { ...data , id: id  }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    saveOffre(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/saveOffre`, data, {
            headers: {
                'sb-tkn': token
            }
        });
    }
    cancelOffre(id) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/cancelOffre`, { id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMyOverview() {
        let token = storage.getToken();
        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/getoverview`, { id: id }, {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMissions() {
        let token = storage.getToken();

        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/getMissions`, { id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    getFavorisRefs() {
        let token = storage.getToken();

        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/getFavorisRefs`, { id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    
    getFavoris() {
        let token = storage.getToken();

        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/getFavoris`, { id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    addToFavoris(id_cond) {
        let token = storage.getToken();

        let clientData = storage.getUserData()
        let id = 0;
        if (clientData)
            id = clientData.id;

        return axios.post(`${api_url}/api/cl/createFavoris`, { id_client : id  , id_cond : id_cond} , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    delFromFavoris(id) {
        let token = storage.getToken();

        return axios.post(`${api_url}/api/cl/deleteFavoris`, { id : id } , {
            headers: {
                'sb-tkn': token
            }
        });
    }

    getMyNotif() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/getMyNotif`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }
    setNotifRead() {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/cl/setNotifRead`, {} , {
            headers: {
                'sb-tkn': token
            }
        });
    }




}

export default new UserService();