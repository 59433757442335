import React, { useState, useEffect, useRef, useContext } from 'react';
import chat_banner from '../../../assets/img/chat_banner.svg'
import img_default from '../../../assets/img/img_default.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatApiCall from '../../../services/api/ChatApiCall';
import UserChatItem from '../../../ui/items/UserChatItem';
import MessageItem from '../../../ui/items/MessageItem';
import Storage from '../../../services/Storage';
import { SocketContext } from '../../../services/api/Socket';
import staff_icon from '../../../assets/img/staff_icon.png'

/* eslint-disable */

const ChatClient = (props) => {



    const [lst_users, set_lst_users] = useState([]);
    const [lst_msg, set_lst_msg] = useState([]);
    const [lst_conected, set_lst_conected] = useState([]);
    const [new_msg, set_new_msg] = useState(null);
    const [sel_disscution, set_sel_disscution] = useState(null);

    const [showP, setShowP] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [net_err, set_net_err] = useState("");
    const user = Storage.getUser();
    const token = Storage.getToken();
    const btm_ref = useRef(null)

    const { socketManager } = useContext(SocketContext)



    const loadDisscution = () => {

        set_net_err("")
        setIsLoading(true)
        ChatApiCall.getMyMsg().then(
            (res) => {
                console.log(res)
                setIsLoading(false)
                if (res.data) {
                    set_lst_msg(res.data)


                }
            }, (error) => {
                setIsLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_net_err(message)
            }
        );

    }

    const sendMsg = (e) => {
        if (e.target.value != "") {
            set_net_err("")
            setIsLoading(true)
            ChatApiCall.sendMsg({ content: e.target.value }).then(
                (res) => {
                    console.log(res)
                    setIsLoading(false)
                    if (res.data) {

                        loadDisscution()
                        socketManager.socket.emit('client_msg', e.target.value)
                        e.target.value = ""


                    }
                }, (error) => {
                    setIsLoading(false)
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_net_err(message)
                }
            );
        }
    }




    useEffect(() => {
        loadDisscution();
    }, [])

    useEffect(() => {
        console.log(lst_msg)
        if (btm_ref.current)
            btm_ref.current.scrollIntoView({ behavior: "smooth" })
    }, [lst_msg])

    useEffect(() => {
        if (new_msg) {
            set_lst_msg([...lst_msg, new_msg])
            set_new_msg(null)
        }
    }, [new_msg])


    useEffect(() => {
        console.log("socket changed" + !socketManager.socket)
        if (socketManager.socket) {
            socketManager.socket.on('connected_lst', (data) => {
                console.log("connected_lst response")
                console.log(data)

                if (data === Object(data)) {
                    console.log("cheking object true")
                    set_lst_conected(new Map(Object.entries(data)))
                }
            })
            socketManager.socket.on('new_msg_to_client', (data) => {
                console.log("new  msg from adm ")
                console.log(data)
                set_new_msg(data)
            })
        }

    }, [socketManager.socket])


    useEffect(() => {
        if (lst_conected && lst_users) {
            let arr = lst_users.map(el => {
                if (lst_conected.get(el.id.toString()))
                    return { ...el, isConnected: true }
                else
                    return { ...el, isConnected: false }
            })

            arr.sort((x, y) => {
                return (x.isConnected === y.isConnected) ? 0 : x.isConnected ? -1 : 1;
            });

            set_lst_users(arr)
        }

    }, [lst_conected])


    useEffect(() => {
        if (lst_users.length) {
            if (!sel_disscution)
                set_sel_disscution(lst_users[0])
            else
                lst_users.forEach(el => {
                    if (el.id == sel_disscution.id)
                        set_sel_disscution(el)
                });
        }

    }, [lst_users])





    return (

        <>
            <div className="row no-marg h-100">


                <div id="users_box" className="col-md-4 chat_users_box" style={{position:"relative"}}>

                    <div className="col-md-12 p-2 chat_users_box_header" style={{boxShadow :"none"}}>
                        <div className="row">
                            <h4 className="col-md-11 txt_blk ">Chat(s)   </h4>

                        </div>
                    </div>

                    <img src={chat_banner} alt="" style={{position:"absolute" , bottom :"0"}}/>




                </div>

                <div id="disscussion_box" className="col-md-8 chat_dissc_box">

                    <div className="col-md-12 chat_dissc_header">
                        <div className="row  chat_dissc_user" >

                            <div className="col-md-4">

                                <img src={staff_icon} className="user_img chat_user_item_img" alt="" />
                                <span className="sp_connected"></span>
                            </div>
                            <div className="col-md-7">
                                <b className="d-block txt-purple fnt-w5 pt-2">Equipe Smart-Bridge</b>
                                <b className="d-block txt-gray fnt-w4"></b>
                            </div>



                            {isLoading &&
                                <div className="col-md-1 txt-ctr">
                                    <div className="spinner-border spinner-border-sm ms-auto" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }



                        </div>
                    </div>

                    <div className="col-md-12 chat_dissc_body">
                        {
                            lst_msg.map((s) => <MessageItem key={s.id} data={s} mine={user.id == s.id_from} />)
                        }
                        <div id="msg_btm" ref={btm_ref}></div>

                    </div>

                    <div className="col-md-12 txt-ctr pt-1">
                        <input type="text" onKeyPress={(e) => { e.key === 'Enter' && sendMsg(e); }} placeholder="Tapez votre message ...." className="txt-ctr form-control inpt_search shadow-none form-control mt-2 " />

                    </div>

                </div>

            </div>

        </>
    )

}

export default ChatClient;