/* eslint-disable */
import React, { useState , useEffect} from 'react';
import ResetPassForm from '../../../ui/forms/ResetPass.form';
import { useHistory } from "react-router-dom";
import UserApiCall from '../../../services/api/UserApiCall';
import Storage from '../../../services/Storage';

const ForgetPass = (props) => {

    const history = useHistory();
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [send_done, set_send_done] = useState(false);
    const [token, setToken] = useState(props.match.params.id);

    const doResetPass = (data) => {
        set_loading(true);

        UserApiCall.resetPass(data).then(
            (res) => {
                set_loading(false)
                if(res.data.done){
                    set_send_done(true)
                    setTimeout(() => {
                        history.push("/login");
                    }, 1000);

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }


    useEffect(()=>{

        if (token) {
            Storage.setToken(token);

        } else {
            history.push("/");
        }

        
    },[])

   


    return (
        <ResetPassForm onDataSubmit={doResetPass}  loading={loading} error={error} reset_done={send_done} />
    )
}




export default ForgetPass ;






