/* eslint-disable */
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormationItem from '../items/FormationItemForm';

const Register = ({ onDataSubmit, loading, error, lst_frm, set_lst_frm }) => {



    const [step1_err, set_step1_err] = useState("");




    const validationSchema = Yup.object().shape({

        nom: Yup.string()
            .required('Vous devez saisir le nom de la formation !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        ecole: Yup.string()
            .required('Vous devez saisir le nom de l\'institue !')
            .min(3, 'non valid ( min 3 caractères ) !'),
        date_debut: Yup.string()
            .required('Vous devez saisir la date de debut!'),
        date_fin: Yup.string()
            .required('Vous devez saisir la date de fin!'),




    });



    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        set_step1_err("");
        set_lst_frm([data, ...lst_frm]);
        console.log(JSON.stringify(data, null, 4));
        //document.getElementById("form_frm").reset();
        reset()
        return false;

    };

    const saveData = () => {
        if (lst_frm.length > 0) {
            onDataSubmit({ frm: lst_frm })
        } else
            set_step1_err("vous devez saisir au moins une formation !")

    }

    const removeItem = (indx) => {
        let array = [...lst_frm];
        if (indx !== -1) {
            array.splice(indx, 1);
            set_lst_frm(array);
        }
    }




    return (
        <div className="row ">


            <div className="col-md-12 h-100 pt-4">
                {error && <p className="text-danger txt-ctr"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}
                {step1_err && <p className="text-danger txt-ctr"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {step1_err}</p>}




                <div className="col-md-12 p-0" >

                    <form key={1} id="form_frm" onSubmit={handleSubmit(onSubmit)} className="col-md-12">

                        <div className="row">
                            <div className="col-md-8 " >
                                <h5><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> Formation(s)</h5>
                            </div>

                            {loading ?
                                <div class="col-md-4 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :

                                <div className="col-md-4 txt-rt" >
                                    <button type="button" className="btn btn-primary btn-login mb-4" onClick={saveData} >Confirmer</button>
                                </div>
                            }

                        </div>

                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="text" placeholder="Diplôme ..."  {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors.nom?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="text" placeholder="Institut / École ..."  {...register("ecole")} className={`form-control ${errors.ecole ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors.ecole?.message}</p>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-md-6 form-group">
                                <label >Date de début</label>
                                <input type="date" {...register("date_debut")} className={`form-control ${errors.date_debut ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors.date_debut?.message}</p>
                            </div>
                            <div className="col-md-6 form-group">
                                <label >Date de fin</label>
                                <input type="date" {...register("date_fin")} className={`form-control ${errors.date_fin ? 'is-invalid' : 'form-control'}`} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                                <p className="text-danger">{errors.date_fin?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12 txt-ctr" style={{ height: "60px" }}>
                            <button type="submit" className="btn btn-primary btn-login" style={{ width: "15%", float: "right" }}>Ajouter</button>
                        </div>

                        <div className="col-md-12 txt-ctr frm_box" >
                            {lst_frm.length ?
                                lst_frm.map((s, index) => <FormationItem key={index} data={s} id={index} onRemove={removeItem} />)
                                :
                                <b className="frm_box_txt_emty" >Aucune formation ajouté</b>
                            }

                        </div>


                    </form>





                </div>

            </div>
        </div>
    )
}

export default Register;


