import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getStringDate} from '../../utils/helpers/date.helper';

const ClientItem = ({data , onItemDetailsClick}) => {


    const itemClicked = () => {
        onItemDetailsClick(data)
    }

    const renderEtat = (etat)=>{
            if( etat == "active")
            return <span class="badge bg-success">Actif</span> ;
            else if( etat == "pending")
            return <span class="badge bg-warning text-dark">En attente</span> ;
            else
            return <span class="badge bg-secondary">{etat}</span>
    }

    return (

        <>
            <div className="col-md-12 p-4">
                <div className="row cond_item p-2" >

                    <div className="col-md-1 txt-ctr">
                        <img src={data.user.img} className="user_img mt-1" alt="" />
                    </div>


                    <div className="col-md-5">

                        <div className="col-md-12   txt-purple2 " data-toggle="tooltip" title={data.titre} >

                            <b className="d-block txt_blk text-capitalize">{data.nom_entreprise} ({data.type_client})</b>
                            <b className="d-block txt-purple2 fnt-w5"> <span className="txt-blk">Secteur</span> ({data.secteur})</b>
                            <b className="txt-gray fnt-sm fnt-w5 pe-4"> <span className="txt-gray ">Crée le </span> ({data.date_creation})</b>
                            <a className="txt-purple2 " href={`http://${data.site_web}`} target="_blank"> <FontAwesomeIcon icon={['fas', 'link']} /> Site web </a>

                        </div>




                    </div>

                    <div className="col-md-3 text-end">
                        <b className="d-block txt-gray fnt-w5 fnt-sm">{getStringDate(data.createdAt)} <FontAwesomeIcon icon={['fas', 'clock']} /></b>
                        {renderEtat(data.user.etat)}
                    </div>


                    <div className="col-md-3 txt-ctr dvdr-l-gray" >

                        <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={itemClicked}>voir plus</button>


                    </div>





                </div>

            </div>

        </>
    )

}

export default ClientItem;