import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './domaines/public/home/Home.container';
import Login from './domaines/public/login/Login.container';
import Register from './domaines/public/register/Register.container';
import RegisterClientInfo from './domaines/public/register/SetClientProfile.container';
import RegisterCondidatInfo from './domaines/public/register/SetCondProfile.container';
import RegisterCondidatFormation from './domaines/public/register/SetCondFormation.container';
import RegisterCondidatExp from './domaines/public/register/SetCondExp.container';
import VerifClient from './domaines/public/register/VerifClient.container';
import VerifCond from './domaines/public/register/VerifCond.container';
import ForgetPass from './domaines/public/login/ForgetPass.container';
import ResetPass from './domaines/public/login/ResetPass.container';
import PrivateRoute from './domaines/middlewares/PrivateRoute';






const App = (props) => {



  return (
    <Router >
      <>

        <Switch>

          <Route path="/" exact >
            <Redirect to='/home' />
          </Route>

          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} exact/>
          <Route path="/forget-pass" component={ForgetPass} exact/>
          <Route path="/register" component={Register} exact/>
          <Route path="/acc/rst-pass/:id" component={ResetPass} />

          <Route path="/register/vfcl/:id" component={VerifClient} />
          <Route path="/register/vfcd/:id" component={VerifCond} />

          <Route path="/register/cl/inf" component={RegisterClientInfo} />
          <Route path="/register/cd/inf" component={RegisterCondidatInfo} />
          <Route path="/register/cd/educ" component={RegisterCondidatFormation} />
          <Route path="/register/cd/exp" component={RegisterCondidatExp} />
          <PrivateRoute  path="/dashb" />

          {/* <Route path="/home" component={props => <Home_base {...props} />} /> */}
          {/* <Route path="/login" component={props => <Login {...props} />} /> */}
          
          
          {/* <Route path="/register/cl/inf/:id" component={props => <RegisterClientInfo {...props} exact/>} /> */}
          {/* <Route path="/register/cl/inf/:id" render={(props) => <RegisterClientInfo {...props} />} /> */}
          

          

          {/* <Route path="/dashb" component={props => <MenubarClient {...props} />} />
          <Route path="/dashbcd" component={props => <MenubarCond {...props} />} /> */}
          {/* <Route path="/home" render={props => <Home_base {...props} />} /> */}
          
          {/* 
          <Route path="/login" exact component={Login} />
          <PrivateRoute component={Menubar} component2={MenubarClient} path="/dashb" />
          <Route path="/404" component={PnotFound} />
          <Route path="/mobile/app" component={DownloadApp} exact/>
          <Redirect to="/404" />

          {user ?
            user.role === "admin" ?
              <Route path="/dashb" component={Menubar} />
              :
              <Route path="/dashb" component={MenubarClient} />
            :
            <Redirect to='/login' />
          } */}

        </Switch>
      </>
    </Router>

  );


}

export default App;
