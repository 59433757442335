import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdmApiCall from '../../../services/api/AdmApiCall';
import offer_banner from '../../../assets/img/offer_banner.svg'
import {getStringOnlyDate} from '../../../utils/helpers/date.helper';

const ContactusContainer = () => {

    const [lst_data, set_lst_data] = useState([]);

    function replaceWithBr(txt) {
        return txt?.replace(/\n/g, "<br />")
      }

    useEffect(()=> {
        AdmApiCall.getContactus().then(res => {
            if(Array.isArray(res.data))
            set_lst_data(res?.data)
        })
    },[])
    return (
        <>
            <div className="row no-marg ">


                {/* -------------------Header--------------------------------------- */}
                <div className="col-md-12 banner_box no-padd mb-3">
                    <img src={offer_banner} className="banner_box_img" alt="" style={{ width: "20%", top: "-40%" }} />
                    <div className="col-md-12 banner_box_effect"></div>
                    <div className="col-md-12 banner_box_content">
                        <div className="row nom-marg">
                            <div className="col-md-12">
                                <h4 className="txt-purple2 fnt-larg"><FontAwesomeIcon icon={['fas', 'comment-dots']} /> Les Messages  <span className="txt-purple2"> ({lst_data.length})</span>  </h4>
                            </div>

                        </div>

                    </div>
                </div>
                {/* -------------------body--------------------------------------- */}

                <div className='col-md-12'>
                {
                    Array.isArray(lst_data) &&
                    lst_data.map(el => <div className='col-md-12 cond_item p-2 my-2'>
                        <b className='d-block'> <span className='text-muted'>Nom & Prénom :</span> <span>{el.fullname}</span></b>
                        <b className='d-block'> <span className='text-muted'>Sujet :</span> <span>{el.subject}</span></b>
                        <b className='d-block'> <span className='text-muted'>E-mail :</span> <span>{el.email}</span></b>
                        <b className='d-block'> <span className='text-muted'>Message :</span> <span>{el.fullname}</span></b>
                        <b className='d-block'> <span className='text-muted'>{getStringOnlyDate(el?.createdAt)}</span></b>
                        
                        <p dangerouslySetInnerHTML={{__html: replaceWithBr(el?.content)}} />
                    </div> )
                }
                </div>




            </div>
        </>
    );
};

export default ContactusContainer;