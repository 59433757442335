import React from 'react';

const FormationItem = (props) => {


    return (
        <div className="row bg-wt frm_box_item">
            <div className="col-md-12 ">
                <b className="ecole">{props.data.ecole}</b>
            </div>
            <div className="col-md-12">
                <b className="fnt-w5">{props.data.nom}</b>
            </div>
            <div className="col-md-12 ">
                <b className="date">{props.data.date_debut} - {props.data.date_fin}</b>
            </div>
        </div>
    )
}

export default FormationItem;


