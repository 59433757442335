import React, {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from '../../utils/helpers/date.helper';
import {DragSwitch, ToggleSwitch} from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'
import CondApiCall from "../../services/api/CondApiCall";
import AdmApiCall from "../../services/api/AdmApiCall";

const CondItem = ({ data, onItemDetailsClick }) => {


    const itemClicked = () => {
        onItemDetailsClick(data)
    }

    const renderEtat = (etat) => {
        if (etat == "active")
            return <span class="badge bg-success">Actif</span>;
        else if (etat == "pending")
            return <span class="badge bg-warning text-dark">En attente</span>;
        else
            return <span class="badge bg-secondary">{etat}</span>
    }
    console.log(data.user)

    const [checked, setChecked] = useState(Boolean(data && data.user.tele))
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    return (

        <div className="col-md-3 p-4">
            <div className="row cond_item p-2" >
                <div className="col-md-12 txt-ctr">
                    <img src={data.user.img} className="user_img cond_item_img" alt="" />
                </div>

                <div className="col-md-12 txt-ctr mt-3 txt-purple2" style={{ height: "50px", overflow: "hidden" }} data-toggle="tooltip" title={data.titre} >

                    <b >{data.titre}</b>

                </div>
                <div className="col-md-12 txt-ctr mt-1 cond_item_t2">
                    <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{data.niv_etude}</span> -  </b>
                    <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{data.niv_exp} an(s)</span> </b>
                    <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {data.dispo ? data.dispo : '-'}</b>

                </div>

                    <div className="col-md-12 txt-ctr mt-3 dvdr-t-gray" >
                        <b className="cond_item_txt_good">{data.tjm} €</b>
                        <b className="d-block txt-gray fnt-w5 fnt-sm">{getStringDate(data.createdAt)} <FontAwesomeIcon icon={['fas', 'clock']} /></b>
                        {data && data.user && renderEtat(data.user.etat)}
                        <div><span style={{color: 'black'}} className='badge'>full remote</span></div>
                        <h6  className="txt-purple mb-0 mt-1" >      <ToggleSwitch checked={Boolean(data && data.user.tele)}
                                                                                   onChange={(checked) => {
                                                                                       AdmApiCall.updateUseInfoA({tele:checked , id : data && data.userId}).then(
                                                                                           (res) => {
                                                                                               //set_loading(false);
                                                                                               console.log(res)
                                                                                               if (res.data.done) {
                                                                                                   window.location.reload(false);
                                                                                               }
                                                                                           }, (error) => {
                                                                                               set_loading(false);
                                                                                               const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                                                                                               set_error(message)
                                                                                           }
                                                                                       );

                                                                                   }}

                        /></h6>

                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={itemClicked}>voir plus</button>

                        </div>
                    </div>

            </div>

        </div>



        // <div className="col-md-12 p-2">
        //     <div className="row cond_item p-2" >

        //         <div className="col-md-1 txt-ctr">
        //             <img src={data.user.img} className="user_img mt-1" alt="" />
        //         </div>


        //         <div className="col-md-6">

        //             <div className="col-md-12   txt-purple2 " data-toggle="tooltip" title={data.titre} >
        //                 <b className="d-block fnt-w5 txt_blk text-capitalize fs-5">{data.user.nom} {data.user.prenom} ({data.titre})</b>
        //                 <b><FontAwesomeIcon icon={['fas', 'graduation-cap']} /> <span>{data.niv_etude}</span> -  </b>
        //                 <b className="pl-2 d-inline-block"><FontAwesomeIcon icon={['fas', 'flask']} /> <span>{data.niv_exp} an(s)</span> </b>
        //                 <b className="pl-2 d-block txt-gray fnt-sm fnt-w4"> dispo le {data.dispo ? data.dispo : '-'}</b>

        //             </div>




        //         </div>

        //         <div className="col-md-2 text-end">
        //             <b className="d-block txt-gray fnt-w5 fnt-sm">{getStringDate(data.createdAt)} <FontAwesomeIcon icon={['fas', 'clock']} /></b>
        //             {data && data.user && renderEtat(data.user.etat)}
        //         </div>


        //         <div className="col-md-3 txt-ctr dvdr-l-gray" >

        //             <button type="button" className="btn btn-primary btn-login mt-4" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} onClick={itemClicked}>voir plus</button>


        //         </div>





        //     </div>

        // </div>


    )

}

export default CondItem;