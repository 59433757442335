import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import ClientApiCall from '../../../services/api/ClientApiCall';
import ClientEditForm from '../../../ui/forms/ClientEdit.form';



const ProfileUpdate = () => {

    const history = useHistory();

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [data, set_data] = useState(false);



    const loadData = () => {
        set_loading(true);
        ClientApiCall.getMyProfile().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_data(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doUpdateProfile = (data )=>{
        set_loading(true);
        ClientApiCall.updateMyProfile(data).then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data.done) {
                    history.push("/dashb/profile")
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])


    return (
        <ClientEditForm client={data} onDataSubmit={doUpdateProfile} loading={loading} error={error} />
    );
};

export default ProfileUpdate;