/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper'
import ClientForm from '../../../ui/forms/Client.form'
import RegisterSideBanner from '../../../ui/static/RegisterSideBanner'
import RegisterStepper from '../../../ui/static/RegisterStepper'
import RegisterHeader from '../../../ui/static/RegisterHeader'
import Storage from '../../../services/Storage'

import {  clear_auth, verifMail } from '../../../redux/actions/AuthActions';
import { connect } from 'react-redux';


const RegisterClientProfile = (props) => {

    const [data, setData] = useState();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(props.match.params.id);
    const history = useHistory();

    const goDashb = () => {
        history.push(routes.dashb);
    }

    const goHome = () => {
        history.push(routes.home_public);
    }

    const goLogin = () => {
        history.push(routes.login);
    }


    useEffect(() => {

        if (token) {
            Storage.setToken(token);
            props.doVerifMail()
        } else {
            goHome()
        }
        return () => {
            props.doClear()
        }
    }, [])

    useEffect(() => {
        if (props.success_valid_mail) {
            if (props.user) {
                setUser(props.user);
                Storage.setToken(token);
                Storage.setUser(props.user);

                if (props.user.profile_done == false) {
                    history.push("/register/cd/inf")
                } else if (props.user.formation_done == false) {
                    history.push("/register/cd/educ");
                } else if (props.user.exp_done == false) {
                    history.push("/register/cd/exp");
                }else{
                    goDashb()
                }
            }
        }
    }, [props.success_valid_mail, props.user])


    return (
        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100 bg-wt pb-4 login-container-form" style={{ overflowY: "auto" }}>
                < RegisterHeader goHome={goHome} />
                <h3>Validation du e-mail...</h3>
                {props.error && 
                    <p className="text-danger">{props.error}</p>
                }
                {props.loading &&
                    <div class="col-md-12 p-4 txt-ctr">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                }
            </div>

            <RegisterSideBanner />
        </div>
    )
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        success_valid_mail: state.auth.success_valid_mail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doVerifMail: () => dispatch(verifMail()),
        doClear: () => dispatch(clear_auth()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterClientProfile)


