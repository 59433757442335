import React from 'react';
import logo_wt from '../../assets/img/logo.svg'
import bg from '../../assets/img/programmer2.png'

const RegisterSideBanner = () => {

    return (
        <div className="col-md-4 bg-grad-purple1 h-100" style={{ position: "relative" }}>
            <img src={logo_wt} className="avatar_logo" alt="" />
            <img src={bg} className="avatar_prog" alt="" />
        </div>
    )
}

export default RegisterSideBanner;