import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ClientsReducer from "./ClientsReducer";
import CondsReducer from "./CondsReducer";
import DmdCondsReducer from "./DmdCondsReducer";

export default combineReducers({
    auth :AuthReducer,
    clients : ClientsReducer ,
    conds : CondsReducer ,
    dmd_conds : DmdCondsReducer ,
});