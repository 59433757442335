import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SkillsFrom = ({ lst_skills, set_lst_skills }) => {

    const [skill_value, set_skill_value] = useState("");

    const onSkillItem = (item) => {
        set_lst_skills(lst_skills.filter(elm => (elm != item)));
    }

    const onSkillAdd = (e) => {
        e.preventDefault();
        if (e.target.value != "" && lst_skills.filter(elm => (elm == e.target.value)).length == 0) {
            set_lst_skills([...lst_skills, e.target.value]);
            set_skill_value("")
        }
    }

    const onAddClick = () => {

        if (skill_value != "" && lst_skills.filter(elm => (elm == skill_value)).length == 0) {
            set_lst_skills([...lst_skills, skill_value]);
            set_skill_value("")
        }
    }


    return (
        <div className="row">
            <div className="col-md-12 form-group ">
                <label className="fnt-w5">Compétences et technologies</label>

                <div className="col-md-12 skills_box mt-4 p-2">
                    {lst_skills.map((s) => <button className="btn_skill_item" type="button" onClick={e => onSkillItem(s)}>{s} <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button>)}
                </div>
                <input type="text" placeholder="skill ..." value={skill_value} onChange={e => set_skill_value(e.target.value)} className="form-control skill_inpt" style={{width : "93% !important"}} onKeyPress={(e) => { e.key === 'Enter' && onSkillAdd(e); }} />
                <button className="btn btn-primary " type="button" onClick={onAddClick} style={{margin:"0px" , marginTop:"-4px" , borderRadius : "0px" , height :"37px" , width : "6%"}} >
                    <FontAwesomeIcon icon={['fas', 'plus']} />
                </button>
            </div>
        </div>
    );
};

export default SkillsFrom;