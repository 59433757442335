import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdmApiCall from '../../../services/api/AdmApiCall';
import MissionList from '../../../ui/Lists/Mission.list';
import MissionToolBox from '../../../ui/Lists/Mission.toolbox';
import Pagination from 'react-bootstrap/Pagination'
import EditMissionModal from '../../../ui/modals/EditMissionModal'
import ConfirmModal from '../../../ui/modals/ConfirmModal'

const MesOffres = (props) => {

    const history = useHistory();
    const [lst_data, set_lst_data] = useState([]);
    const [lst_data_src, set_lst_data_src] = useState([]);
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);


    // paginations params 
    const [page, setPage] = useState(0);
    const [last_page, set_last_page] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [items_p, setItems_p] = useState([]);

    const [search, setSearch] = useState("")

    const [sel_item_data, set_sel_item_data] = useState(null);
    const [show_edit, set_show_edit] = useState(false);

    const [show_cancel, set_show_cancel] = useState(false);

    const onItemEditClick = (data)=>{
        set_sel_item_data(data);
        set_show_edit(true);
        //traiter()
    }
    const onItemDelClick = (data)=>{
        set_sel_item_data(data);
        set_show_cancel(true);
        //traiter()
    }



    const paginationClick = (i) => {
        set_last_page(page);
        setPage(i);
    }

    const renderPagination = () => {
        let items = [];
        let pg_start = 0;
        let pg_end = 0;


        //tot_pg = 3;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            pg_start = 1;
            pg_end = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if ((page + 1) <= 6) {
                pg_start = 1;
                pg_end = 10;
            } else if ((page + 1) + 4 >= totalPages) {
                pg_start = totalPages - 9;
                pg_end = totalPages;
            } else {
                pg_start = (page + 1) - 5;
                pg_end = (page + 1) + 4;
            }
        }


        for (let number = pg_start; number <= pg_end; number++) {
            if (number == pg_start) {
                items.push(
                    <Pagination.Item key={`pgt_FRST+${number}`} disabled={(page + 1) === 1} onClick={() => paginationClick(0)}>
                        Premier
                    </Pagination.Item>,
                );
                items.push(
                    <Pagination.Item key={`pgt_PREV+${number}`} disabled={(page + 1) === 1} onClick={() => paginationClick(page - 1)}>
                        <b><FontAwesomeIcon icon={['fas', 'chevron-left']} /></b>
                    </Pagination.Item>,
                );
            }
            items.push(
                <Pagination.Item key={`pgt_+${number}`} active={number === (page + 1)} onClick={() => paginationClick(number - 1)}>
                    {number}
                </Pagination.Item>,
            );

            if (number == pg_end) {
                items.push(
                    <Pagination.Item key={`pgt_NXT+${number}`} disabled={(page + 1) === totalPages} onClick={() => paginationClick(page + 1)}>
                        <b><FontAwesomeIcon icon={['fas', 'chevron-right']} /></b>
                    </Pagination.Item>,
                );
                items.push(
                    <Pagination.Item key={`pgt_LAST+${number}`} disabled={(page + 1) === totalPages} onClick={() => paginationClick(totalPages - 1)}>
                        Dernier
                    </Pagination.Item>,
                );
            }
        }

        setItems_p(items);
    }

    const onFilter = (f) => {

    }

    const onItemDetailsClick = (data) => {
        history.push("/dashb/offre/" + data.id)
    }



    const loadData = () => {
        set_loading(true);
        AdmApiCall.getMissions().then(
            (res) => {
                set_loading(false);
                console.log(res)
                if (res.data) {
                    set_lst_data_src(res.data)
                    set_lst_data(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const onSearch = (s) => {
        setSearch(s);

        if (s && s != "") {
            set_loading(true);
            AdmApiCall.searchOffre({ page: page, size: size, search: s }).then(
                (res) => {
                    set_loading(false);
                    console.log(res)
                    if (res.data) {
                        set_lst_data_src(res.data)
                        set_lst_data(res.data)

                    }
                }, (error) => {
                    set_loading(false);
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_error(message)
                }
            );
        } else {
            loadData()
        }
    }


    const onDelete = (s) => {

        if (sel_item_data) {

            set_loading(true);
            AdmApiCall.delMission(sel_item_data.id).then(
                (res) => {
                    set_loading(false);
                    console.log(res)
                    if (res.data) {
                        loadData()
                        set_show_cancel(false)

                    }
                }, (error) => {
                    set_loading(false);
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    set_error(message)
                }
            );
        } else {
            loadData()
        }
    }



    useEffect(() => {
        loadData()

        return () => {

        }

    }, [])

    // useEffect(() => {
    //     renderPagination();
    // }, [totalPages]);

    // useEffect(() => {
    //     renderPagination();

    //     if (search && search != "")
    //         onSearch(search)
    //     else
    //         loadData();

    // }, [page]);







    return (
        <>
            <div className="row no-marg ">


                {/* -------------------Header--------------------------------------- */}
                <MissionToolBox dataCount={lst_data.length} onSearch={onSearch} onFilter={onFilter} />



                {/* -------------------Pagination--------------------------------------- */}

                {/* <div className="row no-marg">
                <div className="col-md-8 ps-0">
                    <Pagination className="m-2 pagination-sm">{items_p}</Pagination>
                </div>
                <div className="col-md-4 mt-2  fnt-w5 fnt-sm txt-blue-dark text-end">
                    <span className=" d-inline-block pt-2">({page + 1} / {totalPages} pages - {size} par page)</span>
                   
                </div>
            </div> */}

                {/* -------------------body--------------------------------------- */}

                <MissionList lst_data={lst_data} loading={loading} onItemDetailsClick={onItemDetailsClick} onEdit = {onItemEditClick} onDel={onItemDelClick} />




            </div>

            <EditMissionModal selItem={sel_item_data} onHide ={e => set_show_edit(false)} show ={show_edit}   />
            <ConfirmModal title={"Annuler mission"} msg={"Voulez-vous annuler la mission ?"} onHide ={e => set_show_cancel(false)} show ={show_cancel} onOk={onDelete}   />
        </>

    );
};

export default MesOffres;