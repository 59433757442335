/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';


const Mission = ({ onSubmitData, mission, loading, error }) => {

    const history = useHistory();

    const validationSchema = Yup.object().shape({
        tjm: Yup.number()
            .typeError('le TJM doit être un nombre Exp: 3600.500 €')
            .required('Vous devez choisir le TJM !')
            .test(
                'is-decimal',
                'invalid decimal',
                value => (value + "").match(/^\d+(\.\d{1,3})?$/),
            ),
        date_debut: Yup.string()
            .required('Vous devez saisir la date de debut!'),
        date_fin: Yup.string()
            .required('Vous devez saisir la date de fin!') ,
        etat: Yup.string()
            .required('Vous devez choisir une etat !')

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, unregister, handleSubmit, reset, formState } = useForm(formOptions);

    const { errors } = formState;


    const onSubmitOk = data => {
        onSubmitData(data);
    };





    return (
        <div className="row ">


            <div className="col-md-12 h-100 pt-4">





                <div className="col-md-8 offset-md-2 " >

                    {error &&
                        <div class="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}
                        </div>
                    }


                    <form onSubmit={handleSubmit(onSubmitOk)} className="col-md-12">
                        <div className="row">

                            <h5 className="txt-purple2">Mission</h5>

                            <div className="form-group col-md-12">
                                <label className="fnt-w5">Etat</label>
                                <Form.Control as="select" {...register("etat")} defaultValue={mission && mission.etat}  className="force-apparence"  >
                                    <option value="en_attente">En attent</option>
                                    <option value="en_cours">En cours </option>
                                    <option value="termine">Terminé</option>
                                </Form.Control>
                                <p className="text-danger">{errors.etat?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="fnt-w5">Date de début</label>
                                <input type="date" {...register("date_debut")} defaultValue={mission && mission.date_debut} className={`form-control ${errors.date_debut ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_debut?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="fnt-w5">Date de fin</label>
                                <input type="date" {...register("date_fin")} defaultValue={mission && mission.date_fin} className={`form-control ${errors.date_fin ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_fin?.message}</p>
                            </div>

                        </div>

                        <div className="col-md-12 form-group">
                            <label className="fnt-w5">TJM ( en € ) </label>
                            <input type="text"   {...register("tjm")} defaultValue={mission && mission.tjm} className={`form-control ${errors.tjm ? 'is-invalid' : 'form-control'}`} />
                            <p className="text-danger">{errors.tjm?.message}</p>
                        </div>



                        {loading ?
                            <div class="col-md-12 p-4 txt-ctr">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            :

                            <div className="col-md-12 txt-ctr" >

                                <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                            </div>
                        }


                    </form>





                </div>

            </div>
        </div>
    )
}

export default Mission;


