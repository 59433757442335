import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import img_default from '../../../assets/img/img_default.png';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import FormationItem from '../../../ui/items/FormationItem';
import ExperienceItem from '../../../ui/items/ExperienceItem';



const ClientDetails = ({ showP, setShowP, data, error, loading, onValider, onInvalider, onTjm , onBan, onUnban  }) => {


    const renderEtat = (etat) => {
        if (etat == "active")
            return <span class="badge bg-success">Active</span>;
        else if (etat == "pending")
            return <span class="badge bg-warning text-dark">En attente</span>;
        else
            return <span class="badge bg-secondary">{etat}</span>
    }






    return (
        <>
            <div className={showP ? "col-md-5 bg-gray inf_box_pop_r" : "col-md-5 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
                <div className="row no-padd no-marg bg-gray">
                    <button className="btn_close" style={{ textAlign: "left", background: "#212b60" }} onClick={() => setShowP(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
                </div>

                <div className="row no-marg">
                    <div className="col-md-12 bg-wt">
                        <div className="row no-marg pt-5 pb-3">


                            <div className="col-md-2 text-end">
                                {data && data.user && data.user.img ?
                                    <img className="btn_img_pick_src position-relative" src={data.user.img} style={{ width: "100px", height: "100px" }} alt="" />
                                    :
                                    <img className="btn_img_pick_src position-relative" src={img_default} style={{ width: "100px", height: "100px" }} alt="" />

                                }

                            </div>

                            <div className="col-md-6 ps-5">
                                <h5 className="txt-purple mb-0 mt-2 ">{data && data.user.nom} {data && data.user.prenom}</h5>
                                <h5 className="txt-purple mb-0 mt-0 txt-purple2">{data && data.titre}</h5>
                                <b>Etat  {data && data.user && renderEtat(data.user.etat)} </b>
                                <b className="d-block">TJM  <span style={{ color: "#198754" }}>{data && data.tjm} €</span> </b>

                            </div>

                            <div className="col-md-4 text-end">
                                {
                                    data && data.user && data.user.etat != "active" ?
                                        <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={onValider}>
                                            <span className="me-1"><FontAwesomeIcon icon={['fas', 'check-circle']} /> </span> Valider
                                        </button>
                                        :

                                        <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={onInvalider}>
                                            <span className="me-1"><FontAwesomeIcon icon={['fas', 'times-circle']} /> </span> Invalider
                                        </button>

                                }

                                <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={onTjm}>
                                    <span className="me-1"><FontAwesomeIcon icon={['fas', 'euro-sign']} /> </span> Modifier TJM
                                </button>

                                {
                                    data && data.user && data.user.etat == "blocked" ?
                                        <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={onUnban}>
                                            <span className="me-1"> </span> Activer
                                        </button>
                                        :

                                        <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2 d-inline-block" style={{ width: " 100px" }} onClick={onBan}>
                                            <span className="me-1"></span>  Désactiver
                                        </button>

                                }


                            </div>



                            <div className="col-md-12 dvdr-t-gray pt-2 mt-4 pb-2">
                                <b className="d-inline-block fnt-w5 txt-purple mb-2">Upload CV ( {data?.user?.cv ? <span className='text-success'>OK</span> : <span className='text-danger'>Pas encore</span>} ) </b>
                                {data?.user?.cv &&
                                    <>
                                        <a className="btn btn-success w-auto float-end" href={data?.user?.cv} target="_blank">
                                            Télécharger
                                        </a>
                                    </>
                                }
                            </div>

                            <div className="col-md-12 dvdr-t-gray pt-4 pb-4 mt-2">

                                <div className="row no-marg">
                                    <div className="col-md-12 ">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Info du condidat </b>
                                        <b className="d-block fnt-w5 txt-gray">date de naissance <span className="txt_blk ps-3">{data && data.date_naissance}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Sex <span className="txt_blk ps-3">{data && data.sex}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Niveau d'étude <span className="txt_blk ps-3">{data && data.niv_etude}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Année(s) d'expérience <span className="txt_blk ps-3">{data && data.niv_exp} an(s)</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Disponible depuis le <span className="txt_blk ps-3">{data && data.dispo}</span> </b>


                                    </div>
                                    <div className="col-md-12">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Info du compte </b>
                                        <b className="d-block fnt-w5 txt-gray">Nom <span className="txt_blk ps-3">{data && data.user && data.user.nom}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Prénom <span className="txt_blk ps-3">{data && data.user && data.user.prenom}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">E-mail <span className="txt_blk ps-3">{data && data.user && data.user.email}</span> </b>
                                        <b className="d-block fnt-w5 txt-gray">Tel <span className="txt_blk ps-3">{data && data.user && data.user.tel}</span> </b>

                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Présentation </b>
                                        <p className="d-block txt_blk">{data && data.resume} </p>
                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Technologies et compétences </b>
                                        {data && data.skills.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}

                                    </div>

                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Formation(s) </b>
                                        {data && data.formations &&
                                            data.formations.map(f => <FormationItem data={f} />)
                                        }
                                    </div>
                                    <div className="col-md-12 dvdr-t-gray pt-2 mt-4">
                                        <b className="d-block fnt-w5 txt-purple mb-2">Experience(s) </b>
                                        {data && data.experiences &&
                                            data.experiences.map(e => <ExperienceItem data={e} />)
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </>

    );
};

export default ClientDetails;