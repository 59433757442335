import {
    LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
    CHECKUSER , CHECKUSER_SUCCESS , CHECKUSER_FAIL ,
    REGISTER, REGISTER_SUCCESS, REGISTER_FAIL,
    RESEND_MAIL , RESEND_MAIL_SUCCESS , RESEND_MAIL_FAIL ,
    VERIF_MAIL , VERIF_MAIL_SUCCESS ,VERIF_MAIL_FAIL ,
    CHECK_PROFILE ,CHECK_PROFILE_SUCCESS , CHECK_PROFILE_FAIL ,
    UPLOAD_IMG , UPLOAD_IMG_SUCCESS , UPLOAD_IMG_FAIL ,
    REGISTER_PROFILE, REGISTER_PROFILE_SUCCESS, REGISTER_PROFILE_FAIL,
    REGISTER_FRM , REGISTER_FRM_SUCCESS , REGISTER_FRM_FAIL ,
    REGISTER_EXP , REGISTER_EXP_SUCCESS , REGISTER_EXP_FAIL ,
    LOGOUT, CLEAR_AUTH
} from "./ActionTypes";

import service_user from '../../services/api/UserApiCall';
import service_client from '../../services/api/ClientApiCall';
import service_cond from '../../services/api/CondApiCall';

export const login = (email, password) => {

    return (dispatch) => {
        dispatch({
            type: LOGIN,
        });

        service_user.login(email, password).then(
            (data) => {
                if (data.success)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data.user
                    });
                else
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: "Contactez nous afin de valider votre compte SVP"
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: LOGIN_FAIL,
                    payload: message
                });
            }
        );
    }
}

export const clear_auth = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_AUTH,
        });
    }
}

export const logout = () => {

    service_user.logout();

    return (dispatch) => {
        dispatch({
            type: CLEAR_AUTH,
        });
    }
}

export const registerClient = (user) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER,
        });

        service_user.registerClient(user).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_FAIL,
                        payload: "Echec d'inscription ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_FAIL,
                    payload: message
                });
            }
        );
    }
}
export const registerCond = (user) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER,
        });

        service_user.registerCondidat(user).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_FAIL,
                        payload: "Echec d'inscription ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_FAIL,
                    payload: message
                });
            }
        );
    }
}
export const resendMail = () => {

    return (dispatch) => {
        dispatch({
            type: RESEND_MAIL,
        });

        service_user.resendVerifMail().then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: RESEND_MAIL_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: RESEND_MAIL_FAIL,
                        payload: "Echec de renvoyer le mail ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: RESEND_MAIL_FAIL,
                    payload: message
                });
            }
        );
    }
}

export const UploadProfileImg = (data) => {

    return (dispatch) => {
        dispatch({
            type: UPLOAD_IMG,
        });

        service_user.uploadUserImg(data).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: UPLOAD_IMG_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: UPLOAD_IMG_FAIL,
                        payload: "Echec upload image ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: UPLOAD_IMG_FAIL,
                    payload: message
                });
            }
        );
    }

}
export const CreateClientProfile = (data) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER_PROFILE,
        });

        service_client.newClientInf(data).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_PROFILE_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_PROFILE_FAIL,
                        payload: "Echec de création de profile ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_PROFILE_FAIL,
                    payload: message
                });
            }
        );
    }

}
export const CreateCondProfile = (data) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER_PROFILE,
        });

        service_cond.newCondInf(data).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_PROFILE_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_PROFILE_FAIL,
                        payload: "Echec de création de profile ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_PROFILE_FAIL,
                    payload: message
                });
            }
        );
    }

}
export const CreateCondFormation = (data) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER_FRM,
        });

        service_cond.setCondFormations(data).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_FRM_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_FRM_FAIL,
                        payload: "Echec de sauvegarde de données  ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_FRM_FAIL,
                    payload: message
                });
            }
        );
    }

}
export const CreateCondExp = (data) => {

    return (dispatch) => {
        dispatch({
            type: REGISTER_EXP,
        });

        service_cond.setCondExp(data).then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: REGISTER_EXP_SUCCESS,
                        payload: res.data.data
                    });
                else
                    dispatch({
                        type: REGISTER_EXP_FAIL,
                        payload: "Echec de sauvegarde de données  ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: REGISTER_EXP_FAIL,
                    payload: message
                });
            }
        );
    }

}



export const checkUser = (token) => {

    return (dispatch) => {
        dispatch({
            type: CHECKUSER,
        });

        service_user.checkUser(token).then(
            (res) => {
                if(res.data)
                    dispatch({
                        type: CHECKUSER_SUCCESS,
                        payload: res.data
                    });
                else
                    dispatch({
                        type: CHECKUSER_FAIL,
                        payload: 'Utilisateur unvalide !'
                    });
                

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: CHECKUSER_FAIL,
                    payload: message
                });
            }
        );
    }

}

export const verifMail = () => {

    return (dispatch) => {
        dispatch({
            type: VERIF_MAIL,
        });

        service_user.verifMail().then(
            (res) => {
                if (res.data.done)
                    dispatch({
                        type: VERIF_MAIL_SUCCESS,
                        payload: res.data.user
                    });
                else
                    dispatch({
                        type: VERIF_MAIL_FAIL,
                        payload: "Echec de validation du  mail ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: VERIF_MAIL_FAIL,
                    payload: message
                });
            }
        );
    }
}
export const verifProfile = () => {

    return (dispatch) => {
        dispatch({
            type: CHECK_PROFILE,
        });

        service_user.checkProfile().then(
            (res) => {
                if (res.data)
                    dispatch({
                        type: CHECK_PROFILE_SUCCESS,
                        payload: res.data.user
                    });
                else
                    dispatch({
                        type: CHECK_PROFILE_FAIL,
                        payload: "Echec de vérification du profile ! "
                    });

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: CHECK_PROFILE_FAIL,
                    payload: message
                });
            }
        );
    }
}