import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from '../../utils/helpers/date.helper';

const OfferItem = ({ data, onItemDetailsClick }) => {


    const itemClicked = () => {
        onItemDetailsClick(data)
    }

    return (

        <div className="col-md-12 ps-3 pe-3">
            <div className="row no-marg bg-wt item_box pt-1 pb-1">

                <div className="col-md-1">
                    <h3 className="icon"><FontAwesomeIcon icon={['fas', 'briefcase']} /></h3>
                </div>

                <div className="col-md-6">
                    <b className="d-block fnt-larg">{data.titre}</b>
                    <b className="fnt-w4 fnt-sm txt-gray">de {getStringDate(data.date_debut)} jusqu'à {getStringDate(data.date_fin)}</b>
                    <b className="float-end fnt-w5">{data.budget && Number(data.budget).toFixed(0)} €</b>
                </div>

                <div className="col-md-2  dvdr-l-gray ">
                    <b className="d-block fnt-sm">Etat  </b>
                    <b className=""><span class="badge bg-success fnt-w5">{data.etat}</span> </b>
                    {data?.isblocked == true &&
                        <b className=""><span class="badge bg-danger fnt-w5 ms-2">Bloquée</span> </b>
                    }


                </div>

                <div className="col-md-2 dvdr-r-gray dvdr-l-gray">
                    <b className="d-block fnt-sm">Proposition </b>

                    <b className="d-block"><span class="badge bg-success fnt-w5">{data.demande_offres.length}  <FontAwesomeIcon icon={['fas', 'user-tie']} /></span> </b>


                </div>

                <div className="col-md-1">
                    <button className="box_item_btn fnt-med btn_eff_purple" onClick={itemClicked}>
                        voir plus
                    </button>
                </div>
            </div>

        </div>


    )

}

export default OfferItem;