/* eslint-disable */
import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { routes } from '../../../utils/helpers/routing.helper'
import CondidatFormation from '../../../ui/forms/CondidatFormation.form'
import RegisterSideBanner from '../../../ui/static/RegisterSideBanner'
import RegisterStepper from '../../../ui/static/RegisterStepper'
import RegisterHeader from '../../../ui/static/RegisterHeader'
import Storage from '../../../services/Storage'

import { CreateCondFormation , checkUser, clear_auth } from '../../../redux/actions/AuthActions';
import { connect } from 'react-redux';


const RegisterCondFnE = (props) => {

    const [token, setToken] = useState(Storage.getToken);
    const history = useHistory();

    const goExpForm = () => {
        history.push("/register/cd/exp");
    }

    const goHome = () => {
        history.push(routes.home_public);
    }


    const onSubmit = (data) => {
        props.doCreateFormation(data)
    }

    useEffect(() => {

        if (token) {
            props.doCheckUser(token)
        }else{
            goHome()
        }
        return () => {
            props.doClear()
        }
    }, [])

    useEffect(() => {
        if (props.success_check_user) {
            if (props.user) {
                Storage.setToken(token);
                Storage.setUser(props.user);
            }
        }
    }, [props.success_check_user, props.user])

    

    useEffect(() => {
        if (props.success_register_frm) {
            goExpForm()
        }
    }, [props.success_register_frm])


    return (
        <div className="row no-marg login-container bg-wt">

            <div className="col-md-8 h-100 bg-wt pb-4 login-container-form" style={{ overflowY: "auto" }}>
                < RegisterHeader goHome={goHome} />
                < RegisterStepper />
                < CondidatFormation onDataSubmit={onSubmit} loading={props.loading} error={props.error} />
            </div>

            <RegisterSideBanner />
        </div>
    )
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        user: state.auth.user,
        success_check_user: state.auth.success_check_user,
        success_register_frm: state.auth.success_register_frm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doCheckUser: (token) => dispatch(checkUser(token)),
        doCreateFormation: (data) => dispatch(CreateCondFormation(data)),
        doClear: () => dispatch(clear_auth()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterCondFnE)


