import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getStringDate} from '../../utils/helpers/date.helper';

/* eslint-disable */

const MessageItem = ({mine , data}) => {
    return (

        <>
            <div className="col-md-12 text-center fnt-sm txt-gray">{getStringDate(data.createdAt)}</div>
            <div className={mine ? "col-md-12 text-end" : "col-md-12"}>
                <b className={mine ?"d-inline-block bull_msg_user_me m-2 fnt-w4" : "d-block bull_msg_user_co m-2 fnt-w4"}>
                    {data.content}
                </b>

            </div>

        </>
    )

}

export default MessageItem;