/* eslint-disable */
import React, { useState, useEffect } from 'react';
import logo from '../../../assets/img/logo_bl.png'
import img_default from '../../../assets/img/img_default.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, NavLink, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import HomeCondidat from './HomeCondidat';
import ProfileCond from '../profile/ProfileCond.container';
import SearchOffer from '../searchOffers/Offres.container';
import OffreDetails from '../searchOffers/OffreDetails.container';
import ChatCond from '../chat/ChatCond';
import MesDemandes from '../searchOffers/MesDemandes.container';
import Storage from '../../../services/Storage';
import { SocketContext, socketManager } from '../../../services/api/Socket';
import CondApiCall from '../../../services/api/CondApiCall';
import UserApiCall from '../../../services/api/UserApiCall';
import { getStringOnlyDate } from '../../../utils/helpers/date.helper';
import notif_sfx from '../../../assets/sound/notif.ogg';
import CvModal from '../../../ui/modals/CvModal';

const MenubarClient = ({ match, ...props }) => {

    const [isOpen, setIsopen] = useState(true);
    const user = Storage.getUserData();
    const history = useHistory();
    const token = Storage.getToken();

    const notif_sfx_player = new Audio(notif_sfx);

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [lst_notif, set_lst_notif] = useState([]);
    const [nbr_notif_unread, set_nbr_notif_unread] = useState(0);

    const [show_cv_modal, set_show_cv_modal] = useState(false);

    const logout = () => {
        Storage.logout();
        history.push("/login");
    }

    const loadNotifs = () => {
        set_loading(true);
        CondApiCall.getMyNotif().then(
            (res) => {
                set_loading(false);
                if (res.data) {
                    set_lst_notif(res.data)
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const setNotifRead = () => {
        set_loading(true);
        CondApiCall.setNotifRead().then(
            (res) => {
                set_loading(false);
                loadNotifs()
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const doRefreshSession = () => {
        set_loading(true);
        UserApiCall.refreshToken().then(
            (res) => {
                if (res.data.success) {
                    Storage.setToken(res.data.token);
                    window.location.reload(false);
                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    const renderNotifIcon = (type) => {
        if (type == "msg")
            return <b><FontAwesomeIcon icon={['fas', 'comment-dots']} /></b>
        else if (type == "valid_cmpt")
            return <b><FontAwesomeIcon icon={['fas', 'check-circle']} /></b>
        else if (type == "postul")
            return <b><FontAwesomeIcon icon={['fas', 'user-tie']} /></b>
        else
            return <b><FontAwesomeIcon icon={['fas', 'bell']} /></b>
    }

    useEffect(() => {
        //   console.log('check socket connection : '+socket.connected);
        socketManager.doConnect(token)

        socketManager.socket.on('notif', function (msg) {
            console.log(msg)
            loadNotifs()
        });

        socketManager.socket.on('notif_valid_cmpt', function (msg) {
            loadNotifs()
            doRefreshSession();
        });

        loadNotifs()

        UserApiCall.checkCv().then(res => {
            if (res.data?.done == false) {
                set_show_cv_modal(true)
            }
        }).catch(err => {
            console.log(err)
        })


        history.listen(() => {
            UserApiCall.checkCv().then(res => {
                if (res.data?.done == false) {
                    set_show_cv_modal(true)
                }
            }).catch(err => {
                console.log(err)
            })
        })

        return () => {
            socketManager.socket.disconnect()
        }
    }, [])

    useEffect(() => {

        if (lst_notif) {
            let nbr = lst_notif.filter(el => el.read == false && el).length;
            set_nbr_notif_unread(nbr);

            if (nbr > 0) {
                notif_sfx_player.play()
            }
        }

    }, [lst_notif])



    return (

        <>

            <nav className={isOpen ? "hd-navbar fixed-top" : "hd-navbar fixed-top hd-navbar-mini"}>
                <button className="sidebar-toggle" data-toggle="collapse" data-target=".main-sidebar" role="button"
                    onClick={() => setIsopen(!isOpen)}>
                    <FontAwesomeIcon icon={['fas', 'bars']} />
                </button>
                <div class="navbar-custom-menu pr-4" style={{ float: "right", marginRight: "24px" }}>
                    <ul class="nav navbar-nav">

                        <li class="nav-item ">

                            <Dropdown drop="down" onToggle={setNotifRead} >
                                <Dropdown.Toggle variant="default txt-purple fnt-largr  no-arrow btn_round_notif p-0" id="dropdown-basic" >
                                    <FontAwesomeIcon icon={['fas', 'bell']} />
                                    {nbr_notif_unread > 0 &&
                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fnt-sm mt-2">
                                            {nbr_notif_unread}
                                        </span>
                                    }
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ width: "400px", maxHeight: "300px", overflowY: "scroll" }} className="blue_scrollb" >

                                    {lst_notif.map((ntf) =>
                                        <>
                                            <Dropdown.Item href={ntf.url}>
                                                <div className="row no-marg">
                                                    <div className="col-md-1 txt-dark-bl fnt-largr p-0">
                                                        {renderNotifIcon(ntf.titre)}
                                                    </div>
                                                    <div className="col-md-10 p-0">
                                                        <p style={{ whiteSpace: "normal" }} className="mb-0">
                                                            <div dangerouslySetInnerHTML={{ __html: ntf.content }} />

                                                            <b className="d-block txt-gray fnt-sm fnt-w5"> {getStringOnlyDate(ntf.createdAt)}</b>
                                                        </p>
                                                    </div>
                                                </div>

                                            </Dropdown.Item>
                                            <NavDropdown.Divider />
                                        </>
                                    )

                                    }



                                </Dropdown.Menu>
                            </Dropdown>


                        </li>


                    </ul>
                </div>


            </nav>
            <aside className={isOpen ? "main-sidebar" : "main-sidebar mini-sidebar"}>
                <section className="sidebar">

                    <div className="col-md-12">
                        <a href={`${match.path}/`} className={isOpen ? "sidebar_logo" : "sidebar_logo sidebar_logo_mini"}>
                            <img src={logo} alt="" />
                        </a>
                    </div>



                    <div className={isOpen ? "user-panel" : "user-panel hidebal"} >
                        <div className="row m-0 user-apnel-cont">

                            <div className="col-md-12 txt-ctr">
                                {user && user.user.img ?
                                    <img src={user.user.img} className="user_img" alt="" />
                                    :
                                    <img src={img_default} className="user_img" alt="" />
                                }
                            </div>
                            <div className="col-md-12 user-panel-usr-data">
                                <b>{user && user.user.nom} {user && user.user.prenom} <FontAwesomeIcon icon={['fas', 'circle']} color="#4CAF50" size="xs" /></b>
                                <p> <b className="fnt-w5 d-block">{user && user.user.email}</b>(Candidat)  </p>
                            </div>
                        </div>

                    </div>

                    <ul className="sidebar-menu">
                        <li>
                            <NavLink to={`${match.path}/`} activeClassName="activel" exact ><FontAwesomeIcon icon={['fas', 'home']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Accueil
                                </span>

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/profile" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'id-badge']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Profil
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/offre" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'search']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Trouver une offre
                                </span></NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashb/tech" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'vial']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Test technique
                                </span></NavLink>
                        </li>

                        <li>
                            <NavLink to="/dashb/proposition" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'briefcase']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Mes propositions
                                </span></NavLink>
                        </li>


                        <li>
                            <NavLink to="/dashb/chat" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'comment-dots']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Chat
                                </span></NavLink>
                        </li>

                        {/* <li>
                            <NavLink to="/dashb/myresume" activeClassName="activel"><FontAwesomeIcon icon={['fas', 'cogs']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Paramétres
                                </span></NavLink>
                        </li> */}








                    </ul>

                    <ul>
                        <li className="cont_log_out">
                            <button className="btn_log_out" onClick={logout}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                <span className={isOpen ? "" : "hidebal"}>
                                    &nbsp;&nbsp;Déconnecter
                                </span></button>
                        </li>
                    </ul>

                </section>
            </aside>


            <div className={isOpen ? "rw-cont bg-wt" : "rw-cont rw-cont-mini bg-wt"}>

                <SocketContext.Provider value={{ socketManager }}>
                    <Route
                        path={`${match.path}`}
                        render={({ match: { url } }) => (
                            <>

                                <Route path={`${url}/`} component={HomeCondidat} exact />
                                <Route path={`${url}/profile`} component={ProfileCond} exact />
                                <Route path={`${url}/offre`} component={SearchOffer} exact />
                                <Route path={`${url}/offre/:id`} component={OffreDetails} exact />
                                <Route path={`${url}/chat`} component={ChatCond} exact />
                                <Route path={`${url}/proposition`} component={MesDemandes} exact />




                            </>
                        )}
                    />
                </SocketContext.Provider>
            </div>
            <CvModal show={show_cv_modal} onHide={e => set_show_cv_modal(false)} />
        </>
    )

}

export default withRouter(MenubarClient);