import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ExperienceItemForm = (props) => {


    return (
        <div className="row bg-wt frm_box_item">
            <div className="col-md-12 ">
                <b className="ecole">{props.data.fonction} - {props.data.domaine}</b>
                <button type="button" onClick={e => props.onRemove(props.id)} className="btn btn-default btn_round_notif p-0 txt-purple btn_mini float-end">
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </button>
            </div>
            <div className="col-md-12">
                <b className="fnt-w5">{props.data.entreprise}</b>
            </div>
            <div className="col-md-12 ">
                <b className="date">{props.data.lieu} .{props.data.date_debut} - {props.data.date_fin}</b>
            </div>
            <div className="col-md-12 ">
                <div className="col-md-12 p-4">

                    {props.data.tech.map((s) => <button className="btn_skill_item" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} type="button" >{s} </button>)}

                </div>
            </div>

            <div className="col-md-12 ">
                <p className="date">{props.data.desciprion}</p>
            </div>
        </div>
    )
}

export default ExperienceItemForm;


