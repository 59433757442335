import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import TraitDmdForm from '../forms/TraitDmd.form';
import AdmApiCall from '../../services/api/AdmApiCall';

const TraiteDmdModal = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [net_succ, set_net_succ] = useState("")
    const [net_err, set_net_err] = useState("")


    const setCloseDmdSucc = (data) => {
        setLoading(true)

        AdmApiCall.closeDmdSuccess({ ...data, id_dmd: props.selItem.id, clientId: props.selItem.clientId, condidatId: props.selItem.condidatId }).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("Demande traité avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        history.push("/dashb/mission/");
                    }, 2000);

                } else {
                    setLoading(false)
                    set_net_err("Echec  !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err(message);
            }
        );
    }
    const setCloseDmdFail = (data) => {
        setLoading(true)

        AdmApiCall.closeDmdFail({ ...data, id_dmd: props.selItem.id }).then(
            (res) => {
                console.log(res);
                if (res.data.done) {
                    set_net_succ("Demande traité avec succès ");
                    setTimeout(() => {
                        setLoading(false)
                        history.push("/dashb/mission/");
                    }, 2000);

                } else {
                    setLoading(false)
                    set_net_err("Echec  !");
                }
            }, (error) => {
                setLoading(false)
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                set_net_err(message);
            }
        );
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter " className="txt-purple2">
                    Traiter demande de condidat
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="">
                {net_succ &&
                    <div class="alert alert-success" role="alert">
                        {net_succ}
                    </div>
                }

                <TraitDmdForm onCloseSucc={setCloseDmdSucc} onCloseFail={setCloseDmdFail} error={net_err} loading={loading} />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Annuler
                </Button>


            </Modal.Footer>
        </Modal>
    );

}

export default TraiteDmdModal;