/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserApiCall from '../../services/api/UserApiCall';
import ClientApiCall from '../../services/api/ClientApiCall';
import CondApiCall from '../../services/api/CondApiCall';
import Storage from '../../services/Storage';


import MenubarClient from '../client/home/MenubarClient';
import MenubarCond from '../condidat/home/MenubarCond';
import MenubarAdm from '../admin/home/Dashboard_Adm';




const PrivateRoute = ({ ...rest }) => {
    const [user, setUser] = useState(null);
    const [checked, setChecked] = useState(false);
    const [token, setToken] = useState(Storage.getToken());
    

    useEffect(() => {

        UserApiCall.checkUser(token).then(
            (res) => {
                console.log(res);
                if (res.data) {
                    Storage.setUser(res.data);
                    setUser(res.data);
           
                    if (res.data.role == "admin" || res.data.role == "root") {
                        setChecked(true);
                    } else if (res.data.role == "client") {
                        getUserClientData(res.data)
                    } if (res.data.role == "condidat") {
                        getUserCondData(res.data)
                    }
                } else {
                    Storage.logout()
                    setUser(null)
                    setChecked(true);
                }


            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                Storage.logout()
                setUser(null)
                setChecked(true);
            }
        );


    }, [])

    const getUserClientData = (u) => {
        ClientApiCall.getClientByUser(u.id).then(
            (res) => {
                console.log(res);
                if (res.data) {
                    Storage.setUserData(res.data);
                } else {
                    Storage.logout()
                    setUser(null)
                }
                setChecked(true);

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                Storage.logout()
                setUser(null)
                setChecked(true);
            }
        );
    }

    const getUserCondData = (u) => {
        CondApiCall.getCondByUser(u.id).then(
            (res) => {
                console.log(res);
                if (res.data) {
                    Storage.setUserData(res.data);
                } else {
                    Storage.logout()
                    setUser(null)
                }
                setChecked(true);

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(message);
                Storage.logout()
                setUser(null)
                setChecked(true);
            }
        );
    }

    const checkComponentForRole = (props) => {
        switch(user.role) {
  
          case "root":   return <MenubarAdm {...props}/>;
          case "admin":   return <MenubarAdm {...props}/>;
          case "client": return <MenubarClient {...props}/>;
          case "condidat":  return <MenubarCond {...props}/>;
  
          default:      return <Redirect to="/login" />
        }
      }

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        checked &&
        <Route {...rest} render={props => (
            user ?
                checkComponentForRole(props)
                : <Redirect to="/login" />
        )} />


    );
};

export default PrivateRoute;