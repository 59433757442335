import React from 'react';
import OfferItem from '../items/OfferCondItem';

const OfferCondList = ({loading ,lst_data , onItemDetailsClick}) => {
    return (
        <div className="col-md-12 bg-wt">

            {loading ?
                <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                :
                lst_data.map((s , index) => <OfferItem key={`ofr_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} />)
            }

        </div>
    );
};

export default OfferCondList;