/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';


const Register = ({ onDataSubmit, loading, error }) => {

    const [sel_img, set_sel_img] = useState(null);
    const [sel_img_tmp, set_sel_img_tmp] = useState(null);
    const [img_err, set_img_err] = useState("");


    const inpt_img = useRef(null);
    const history = useHistory();

    const goDashb = () => {
        history.push("/dashb");
    }

    const goHome = () => {
        history.push("/");
    }

    const pickClick = () => {
        //console.log(formState);
        inpt_img.current.click();
    }

    const onImgChange = (e) => {
        set_img_err("")
        console.log(e.target.files[0].size)
        set_sel_img(e.target.files[0]);
        set_sel_img_tmp(URL.createObjectURL(e.target.files[0]));

        if (e.target.files[0].size >= 2000000) {
            set_img_err("Taille max de la photo 2MB !")
        }
    }




    const validationSchema = Yup.object().shape({
        nom: Yup.string()
            .required('Vous devez saisir votre nom !')
            .min(3, 'Nom non valid ( min 3 caractères ) !'),
        prenom: Yup.string()
            .required('Vous devez saisir votre prenom !')
            .min(3, 'Prenom non valid ( min 3 caractères ) !'),
        type_client: Yup.string()
            .required('Vous devez choisir un type de client !'),
        nom_ent: Yup.string()
            .required('Vous devez saisir le nom de votre entreprise !')
            .min(2, 'non valid ( min 2 caractères ) !'),
        date_creation: Yup.string()
            .required('Vous devez saisir la date de création!'),
        resume: Yup.string(),
        secteur: Yup.string()
            .required('Vous devez décrire votre secteur d\'activité!')
            .min(4, 'non valid ( min 4 caractères ) !'),
        site_web: Yup.string(),
        tel: Yup.string()
            .min(8, 'numéro tél non valide !')
            .required('Vous devez saisir votre num tél !')
            ,

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data, sel_img);
    };



    return (
        <div className="row ">


            <div className="col-md-12 h-100 pt-4">





                <div className="col-md-8 offset-md-2 " >

                    <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">

                        {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                        {img_err &&
                            <div class="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {img_err}
                            </div>
                        }

                        <div className="col-md-12 txt-ctr mb-2">

                            <button className="btn_img_pick position-relative" type="button" onClick={pickClick}>
                                {sel_img_tmp ?
                                    <img className="btn_img_pick_src" src={sel_img_tmp} alt="" />
                                    :
                                    <FontAwesomeIcon icon={['fas', 'camera']} />
                                }

                            </button>
                            <p className="text-danger">{errors.img?.message}</p>

                            <div class="form-group d-none">
                                <input type="file" class="form-control-file" ref={inpt_img} onChange={onImgChange} />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="text" placeholder="Nom"  {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.nom?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="text" placeholder="Prenom"  {...register("prenom")} className={`form-control ${errors.prenom ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.prenom?.message}</p>
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <label className="fnt-w5">Type de client</label>
                            <Form.Control as="select"  {...register("type_client")} className={`form-control ${errors.type_client ? 'is-invalid' : 'force-apparence'}`}  >
                                <option value="">Choisissez un type de client …</option>
                                <option value="esn">ESN (entreprise de service numérique )</option>
                                <option value="client">Client final</option>
                            </Form.Control>
                            <p className="text-danger">{errors.type_client?.message}</p>
                        </div>



                        <div className="row">

                            <div className="col-md-6 form-group ">
                            <label  className="fnt-w5" >Nom de l'entreprise</label>
                                <input type="text" placeholder="nom de l'entreprise"  {...register("nom_ent")} className={`form-control ${errors.nom_ent ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.nom_ent?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                            <label  className="fnt-w5" >Date de création de l'entreprise </label>
                                <input type="date" {...register("date_creation")} className={`form-control ${errors.date_creation ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_creation?.message}</p>
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <label className="">Tél</label>
                            <input placeholder="+216 ..."  {...register("tel")} className={`form-control ${errors.tel ? 'is-invalid form-control-sm' : 'form-control form-control-sm'}`} />
                            <p className="text-danger">{errors.tel?.message}</p>

                        </div>

                        <div className="form-group col-md-12">
                            <label className="fnt-w5">Présentation</label>
                            <textarea class="form-control" {...register("resume")} className={`form-control ${errors.resume ? 'is-invalid' : 'force-apparence'}`} placeholder="desciption de l'entreprise ..." rows="3"></textarea>
                            <p className="text-danger">{errors.resume?.message}</p>
                        </div>


                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="text" placeholder="http:// Site web"  {...register("site_web")} className={`form-control ${errors.site_web ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.site_web?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="text" placeholder="Secteur d'activité" {...register("secteur")} className={`form-control ${errors.secteur ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.secteur?.message}</p>
                            </div>
                        </div>

                        {loading ?
                            <div class="col-md-12 p-4 txt-ctr">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            :

                            <div className="col-md-12 txt-ctr" >

                                <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                            </div>
                        }


                    </form>




                </div>

            </div>
        </div>
    )
}

export default Register;


