/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';


const Register = ({ onDataSubmit, loading, error, client }) => {


    const history = useHistory();

    const goDashb = () => {
        history.push("/dashb");
    }

    const goHome = () => {
        history.push("/");
    }
    const goMyProfile = () => {
        history.push("/dashb/profile");
    }






    const validationSchema = Yup.object().shape({
        nom: Yup.string()
            .required('Vous devez saisir votre nom !')
            .min(3, 'Nom non valid ( min 3 caractères ) !'),
        prenom: Yup.string()
            .required('Vous devez saisir votre prenom !')
            .min(3, 'Prenom non valid ( min 3 caractères ) !'),
        type_client: Yup.string()
            .required('Vous devez choisir un type de client !'),
        nom_ent: Yup.string()
            .required('Vous devez saisir le nom de votre entreprise !')
            .min(2, 'non valid ( min 2 caractères ) !'),
        date_creation: Yup.string()
            .required('Vous devez saisir la date de création!'),
        resume: Yup.string(),
        secteur: Yup.string()
            .required('Vous devez décrire votre secteur d\'activité!')
            .min(4, 'non valid ( min 4 caractères ) !'),
        site_web: Yup.string()

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, unregister, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data);
    };

    useEffect(() => {
        if (client) {
            setValue("type_client", client.type_client);
            setValue("nom_ent", client.nom_entreprise);
            setValue("date_creation", client.date_creation);
            setValue("resume", client.resume);
            setValue("secteur", client.secteur);
            setValue("site_web", client.site_web);

            if (client.user) {
                setValue("nom", client.user.nom);
                setValue("prenom", client.user.prenom);

            }
        }

    }, [client])



    return (
        <div className="row no-marg bg-wt">

            <div className="col-md-12 pt-2">
                <button className="box_item_btn fnt-med btn_eff_purple  mt-0 mb-1 ms-2" style={{ width: " 100px" }} onClick={goMyProfile}>
                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} />
                </button>
            </div>


            <div className="col-md-12  pt-4">





                <div className="col-md-8 offset-md-2 " >

                    <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">

                        {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}


                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <label  className="fnt-w5" >Nom </label>
                                <input type="text" placeholder="Nom"  {...register("nom")} className={`form-control ${errors.nom ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.nom?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label  className="fnt-w5" >Prénom</label>
                                <input type="text" placeholder="Prenom"  {...register("prenom")} className={`form-control ${errors.prenom ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.prenom?.message}</p>
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <label  className="fnt-w5" >Type de client</label>
                            <Form.Control as="select"  {...register("type_client")} className={`form-control ${errors.type_client ? 'is-invalid' : 'force-apparence'}`}  >
                                <option value="">Choisissez un type de client …</option>
                                <option value="esn">ESN (entreprise de service numérique )</option>
                                <option value="client">Client final</option>
                            </Form.Control>
                            <p className="text-danger">{errors.type_client?.message}</p>
                        </div>



                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <label  className="fnt-w5" >Nom de l'entreprise</label>
                                <input type="text" placeholder="nom de l'entreprise"  {...register("nom_ent")} className={`form-control ${errors.nom_ent ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.nom_ent?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label  className="fnt-w5" >Date de création de l'entreprise</label>
                                <input type="date" {...register("date_creation")} className={`form-control ${errors.date_creation ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.date_creation?.message}</p>
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <label  className="fnt-w5" >Présentation</label>
                            <textarea class="form-control" {...register("resume")} className={`form-control ${errors.resume ? 'is-invalid' : 'force-apparence'}`} placeholder="desciption de l'entreprise ..." rows="3"></textarea>
                            <p className="text-danger">{errors.resume?.message}</p>
                        </div>


                        <div className="row">
                            <div className="col-md-6 form-group ">
                                <label  className="fnt-w5" >Site web</label>
                                <input type="text" placeholder="http:// Site web"  {...register("site_web")} className={`form-control ${errors.site_web ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.site_web?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="fnt-w5" >Secteur d'activité</label>
                                <input type="text" placeholder="Secteur d'activité" {...register("secteur")} className={`form-control ${errors.secteur ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.secteur?.message}</p>
                            </div>
                        </div>

                        {loading ?
                            <div class="col-md-12 p-4 txt-ctr">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            :

                            <div className="col-md-12 txt-ctr" >

                                <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                            </div>
                        }


                    </form>




                </div>

            </div>
        </div>
    )
}

export default Register;


