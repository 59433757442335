import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import offer_banner from '../../assets/img/offer_banner.svg'
import Form from 'react-bootstrap/Form';

const MissionToolbox = ({ dataCount, onSearch, onFilter }) => {

    const [search, setSearch] = useState("")
    const [last_search, set_last_search] = useState("")

    const onClear = () => {
        setSearch("");
        set_last_search("")
        onSearch("");
    }

    const onSubmit = (s) => {
        set_last_search(s)
        onSearch(s)
        setSearch("")
    }

   

    return (
        <div className="col-md-12 banner_box no-padd mb-3">
            <img src={offer_banner} className="banner_box_img" alt="" style={{ width: "20%", top: "-40%" }} />
            <div className="col-md-12 banner_box_effect"></div>
            <div className="col-md-12 banner_box_content">
                <div className="row nom-marg">
                    <div className="col-md-12">
                        <h4 className="txt-purple2 fnt-larg"><FontAwesomeIcon icon={['fas', 'briefcase']} /> Les Missions <span className="txt-purple2"> ({dataCount})</span>  </h4>
                        
                    </div>
                    <div className="col-md-5 offset-md-1  mt-4 ">
{/* 
                        <input type="text" onChange={e => setSearch(e.target.value)} value={search} style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }} placeholder="Recherche ...." className="txt-ctr form-control inpt_search form-control bg-wt" onKeyPress={(e) => { e.key === 'Enter' && onSubmit(e.target.value); }} />
                        <button className="btn btn_search" onClick={e => onSubmit(search)}><FontAwesomeIcon icon={['fas', 'search']} /> </button>
                    */}
                    </div>
                    <div className="col-md-5   mb-4  dvdr-l-gray">
                        {/* <label className="no-marg small-label txt_blk"><FontAwesomeIcon className="txt-gray" icon={['fas', 'filter']} /> Etat</label>

                        <Form.Control onChange={e => { }} size="sm" as="select" style={{ boxShadow: "-1px 0px 5px rgb(0 0 0 / 23%)" }}>
                            <option value="">Tous</option>
                            <option value="ouvert">Ouvert</option>
                            <option value="en_cours">En cours</option>
                            <option value="ferme">Fermé</option>

                        </Form.Control> */}
                    </div>

                    <div className="col-md-12">
                        {/* {last_search &&
                            <button className="btn_skill_item" type="button" onClick={e => onClear()}>{last_search} <span><FontAwesomeIcon icon={['fas', 'times']} /></span></button>
                        } */}
                    </div>
                </div>

            </div>
        </div>


    );
};

export default MissionToolbox;