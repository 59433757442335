/* eslint-disable */
import React, { useState, useEffect } from 'react';
import banner_client from '../../../assets/img/banner_client.png'
import side_shape from '../../../assets/img/side_shape.svg'
import offer_banner from '../../../assets/img/offer_banner.svg'
import talent_banner from '../../../assets/img/talent_banner.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Storage from '../../../services/Storage';
import { useHistory } from "react-router-dom";
import ClientApiCall from '../../../services/api/ClientApiCall';



const Home = ({ match }) => {

    const user = Storage.getUserData();
    const user_etat = Storage.getUser();

    const history = useHistory();

    const [nbr_offre, set_nbr_offre] = useState(0);
    const [nbr_msg, set_nbr_msg] = useState(0);
    const [nbr_dmd, set_nbr_dmd] = useState(0);
    const [nbr_prop, set_nbr_prop] = useState(0);

    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);

    const goChat = () => {
        history.push("/dashb/chat");
    }

    const goCreateOffer = () => {
        history.push("/dashb/offre-crt");
    }

    const goMyoffers = () => {
        history.push("/dashb/mesoffres");
    }

    const goMyDmd = () => {
        history.push("/dashb/mesoffres");
    }

    const goSearchCond = () => {
        history.push("/dashb/srcd");
    }
    const goMission = () => {
        history.push("/dashb/mission");
    }


    const loadData = () => {
        set_loading(true);
        ClientApiCall.getMyOverview().then(
            (res) => {
                set_loading(false);
                if (res.data) {
                    set_nbr_offre(res.data.nbr_offre)
                    set_nbr_msg(res.data.nbr_msg)
                    set_nbr_dmd(res.data.nbr_dmd)
                    set_nbr_prop(res.data.nbr_mission)

                }
            }, (error) => {
                set_loading(false);
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                set_error(message)
            }
        );
    }

    useEffect(() => {
        loadData()

    }, [])


    return (

        <>
            <div className="row no-marg ">
                <div className="col-md-12 banner_box no-padd mb-3">
                    <img src={banner_client} className="banner_box_img" alt="" />
                    <div className="col-md-12 banner_box_effect"></div>
                    <div className="col-md-12 banner_box_content">
                        <h3>Bonjour , {user && user.user.prenom}  </h3>
                    </div>
                </div>

                {user_etat && user_etat.etat != "active" &&

                    <div className="col-md-12 ">
                        <div class="alert alert-danger" role="alert">
                            <b className="me-3"><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} /></b>
                        votre compte n'est pas encore validé  ,
                        Vous pouvez utiliser le <a href="/dashb/chat" className="alert-link">chat</a> pour nous contacter à ce propos

                    </div>
                    </div>

                }

                <div className="col-md-12">

                    <div className="row no-marg">

                        <div className="col-md-3 p-2 ">
                            <div className="row no-marg home_info_box">
                                <div className="col-md-6 home_info_box_ctn_icon">
                                    <div className=""> <b className="home_info_box_icon"><FontAwesomeIcon icon={['fas', 'comments']} /></b> </div>
                                    <div className="home_info_box_shape_ctn">
                                        <img src={side_shape} className="home_info_box_shape" alt="" />
                                    </div>

                                </div>

                                <div className="col-md-6 pt-4 fnt-larg">
                                    <b className="d-block txt-ctr fnt-w5">Messages</b>
                                    <b className="d-block txt-ctr fnt-largr home_info_box_txt_value">0{nbr_msg}</b>
                                    <button className="btn btn-primary btn-login p-0 d-block full-width mb-2" onClick={e => goChat()}> voir</button>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-3 p-2 ">
                            <div className="row no-marg home_info_box">
                                <div className="col-md-6 home_info_box_ctn_icon">
                                    <div className=""> <b className="home_info_box_icon"><FontAwesomeIcon icon={['fas', 'briefcase']} /></b> </div>
                                    <div className="home_info_box_shape_ctn">
                                        <img src={side_shape} className="home_info_box_shape" alt="" />
                                    </div>

                                </div>

                                <div className="col-md-6 pt-4 fnt-larg">
                                    <b className="d-block txt-ctr fnt-w5">Mes offres</b>
                                    <b className="d-block txt-ctr fnt-largr home_info_box_txt_value">0{nbr_offre}</b>
                                    <button className="btn btn-primary btn-login p-0 d-block full-width mb-2" onClick={e => goMyoffers()}> voir</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 p-2 ">
                            <div className="row no-marg home_info_box">
                                <div className="col-md-6 home_info_box_ctn_icon">
                                    <div className=""> <b className="home_info_box_icon"><FontAwesomeIcon icon={['fas', 'hands-helping']} /></b> </div>
                                    <div className="home_info_box_shape_ctn">
                                        <img src={side_shape} className="home_info_box_shape" alt="" />
                                    </div>

                                </div>

                                <div className="col-md-6 pt-4 fnt-larg">
                                    <b className="d-block txt-ctr fnt-w5">Mes demandes</b>
                                    <b className="d-block txt-ctr fnt-largr home_info_box_txt_value">0{nbr_dmd}</b>
                                    <button className="btn btn-primary btn-login p-0 d-block full-width mb-2" onClick={e => goMyDmd()}> voir</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 p-2 ">
                            <div className="row no-marg home_info_box">
                                <div className="col-md-6 home_info_box_ctn_icon">
                                    <div className=""> <b className="home_info_box_icon"><FontAwesomeIcon icon={['fas', 'user-tie']} /></b> </div>
                                    <div className="home_info_box_shape_ctn">
                                        <img src={side_shape} className="home_info_box_shape" alt="" />
                                    </div>

                                </div>

                                <div className="col-md-6 pt-4 fnt-larg">
                                    <b className="d-block txt-ctr fnt-w5">Missions</b>
                                    <b className="d-block txt-ctr fnt-largr home_info_box_txt_value">0{nbr_prop}</b>
                                    <button className="btn btn-primary btn-login p-0 d-block full-width mb-2" onClick={e => goMission()}> voir</button>
                                </div>
                            </div>
                        </div>




                    </div>


                </div>


                <div className="col-md-12 mt-2">
                    <div className="row no-marg">
                        <div className="col-md-6  p-2 ">
                            <div className="col-md-12 p-2 home_info_box position-relative">
                                <h4>Publiez vos offres d'emploi </h4>
                                <button className="btn btn-primary btn_banner_home" onClick={e => goCreateOffer()}> voir plus</button>
                                <img src={offer_banner} width="220" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6  p-2 ">
                            <div className="col-md-12 p-2 home_info_box position-relative">
                                <h4>Trouvez un talent pour votre projet </h4>
                                <button className="btn btn-primary btn_banner_home" onClick={e => goSearchCond()}> voir plus</button>
                                <img src={talent_banner} width="220" alt="" />
                            </div>
                        </div>


                    </div>
                </div>




            </div>

        </>
    )

}

export default Home;