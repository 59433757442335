/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';


const TraitDmd = ({ onCloseSucc, onCloseFail, loading, error }) => {

    const [show_form_succ, set_show_form_succ] = useState(false);
    const [show_form_fail, set_show_form_fail] = useState(false);

    const history = useHistory();

    const validationSchema = Yup.object().shape({
        tjm: Yup.number()
            .typeError('le TJM doit être un nombre Exp: 3600.500 €')
            .required('Vous devez choisir le TJM !')
            .test(
                'is-decimal',
                'invalid decimal',
                value => (value + "").match(/^\d+(\.\d{1,3})?$/),
            ),
        date_debut: Yup.string()
            .required('Vous devez saisir la date de debut!'),
        date_fin: Yup.string()
            .required('Vous devez saisir la date de fin!')

    });

    const validationSchema2 = Yup.object().shape({
        observation: Yup.string()

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const formOptions2 = { resolver: yupResolver(validationSchema2) };

    const { register, unregister, handleSubmit, reset, formState } = useForm(formOptions);
    const { register: register2, unregister: unregister2, handleSubmit: handleSubmit2, reset: reset2, formState: formState2 } = useForm(formOptions2);

    const { errors } = formState;
    const { errors: errors2 } = formState2;


    const onSubmitOk = data => {
        onCloseSucc(data);
    };
    const onSubmitFail = data => {
        onCloseFail(data);
    };

    const onDecisionChange = v => {
        if (v == "") {
            set_show_form_succ(false)
            set_show_form_fail(false)
        } else if (v == "t_succ") {
            set_show_form_succ(true)
            set_show_form_fail(false)
        } else {
            set_show_form_fail(true)
            set_show_form_succ(false)
        }
    }



    return (
        <div className="row ">


            <div className="col-md-12 h-100 pt-4">





                <div className="col-md-8 offset-md-2 " >

                    <div className="form-group col-md-12">
                        <label className="fnt-w5">Décision</label>
                        <Form.Control as="select" onChange={e => onDecisionChange(e.target.value)} className="force-apparence"  >
                            <option value="">Choisissez une décision …</option>
                            <option value="t_succ">Traité avec succés</option>
                            <option value="t_fail">Traité avec echec</option>
                        </Form.Control>
                        <p className="text-danger">{errors.type_client?.message}</p>
                    </div>

                    {error &&
                        <div class="alert alert-danger" role="alert">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}
                        </div>
                    }

                    {show_form_succ &&
                        <form onSubmit={handleSubmit(onSubmitOk)} className="col-md-12">
                            <div className="row">

                                <h5 className="txt-purple2">Mission</h5>

                                <div className="col-md-6 form-group">
                                    <label className="fnt-w5">Date de début</label>
                                    <input type="date" {...register("date_debut")} className={`form-control ${errors.date_debut ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.date_debut?.message}</p>
                                </div>

                                <div className="col-md-6 form-group">
                                    <label className="fnt-w5">Date de fin</label>
                                    <input type="date" {...register("date_fin")} className={`form-control ${errors.date_fin ? 'is-invalid' : 'form-control'}`} />
                                    <p className="text-danger">{errors.date_fin?.message}</p>
                                </div>

                            </div>

                            <div className="col-md-12 form-group">
                                <label className="fnt-w5">TJM ( en € ) </label>
                                <input type="text"   {...register("tjm")} className={`form-control ${errors.tjm ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.tjm?.message}</p>
                            </div>



                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :

                                <div className="col-md-12 txt-ctr" >

                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                </div>
                            }


                        </form>
                    }

                    {show_form_fail &&
                        <form onSubmit={handleSubmit2(onSubmitFail)} className="col-md-12">

                            <div className="form-group col-md-12">
                                <label className="fnt-w5">Observation</label>
                                <textarea class="form-control" {...register2("observation")} className={`form-control ${errors2.observation ? 'is-invalid' : 'force-apparence'}`} placeholder="Observation ..." rows="4"></textarea>
                                <p className="text-danger">{errors2.observation?.message}</p>
                            </div>



                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :

                                <div className="col-md-12 txt-ctr" >

                                    <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                                </div>
                            }


                        </form>
                    }



                </div>

            </div>
        </div>
    )
}

export default TraitDmd;


