import React from 'react';
import ClientItem from '../items/ClientItem';

const ClientsList = ({loading ,lst_data , onItemDetailsClick}) => {
    return (
        <div className="col-md-12 bg-wt">

            {loading ?
                <div class="col-md-12 p-4 txt-ctr">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                :
                lst_data.map((s , index) => <ClientItem key={`clitm_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} />)
            }

        </div>
    );
};

export default ClientsList;