import {
    CLEAR_CLIENTS ,
    LOAD_CLIENTS , LOAD_CLIENTS_SUCCESS , LOAD_CLIENTS_FAIL ,
} from "./ActionTypes";

import service_adm from '../../services/api/AdmApiCall';



export const clear_clients = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CLIENTS,
        });
    }
}

export const getClientList = () => {

    return (dispatch) => {
        dispatch({
            type: LOAD_CLIENTS,
        });

        service_adm.getAllClient().then(
            (res) => {
                if(res.data)
                    dispatch({
                        type: LOAD_CLIENTS_SUCCESS,
                        payload: res.data
                    });
                else
                    dispatch({
                        type: LOAD_CLIENTS_FAIL,
                        payload: 'Echec de récupération de données !'
                    });
                

            }, (error) => {
                const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch({
                    type: LOAD_CLIENTS_FAIL,
                    payload: message
                });
            }
        );
    }

}