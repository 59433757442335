import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterHeader = ({goHome}) => {

    return (
        <div className="row no-marg">
            <div className="col-md-2 no-padd">
                <button className="btn btn-primary btn_back_home" onClick={goHome}><FontAwesomeIcon icon={['fas', 'chevron-left']} /></button>
            </div>
        </div>
    )
}

export default RegisterHeader;