import React from 'react';
import UserChatItem from '../items/UserChatItem';

const ChatUsersList = ({ lst_data,  sel_disscution, onItemClick }) => {
    return (
        <div className="col-md-12 chat_users_box_content">
            {
                lst_data.map((s) => <UserChatItem key={s.id} onItemClick={onItemClick} data={s} isSel={sel_disscution && sel_disscution.id == s.id} />)
            }
        </div>
    );
};

export default ChatUsersList;