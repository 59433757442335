import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';

const MdpUpdate = ({ showP, setShowP, loading , error ,  onDataSubmit , done }) => {

    const validationSchema = Yup.object().shape({
        last_password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir votre ancien mot de passe !'),
        password: Yup.string()
            .min(6, 'Mot de passe non valid ( min 6 caractères ) !')
            .required('Vous devez saisir votre mot de passe !'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mot de passe ne sont identiques !')
            .required('Vous devez confirmez votre mot de passe !'),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = data => {
        onDataSubmit(data)
    }


    useEffect(()=>{
        if(done){
            reset();
        }

    },[done])


    return (
        <div className={showP ? "col-md-5 bg-gray inf_box_pop_r" : "col-md-5 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
            <div className="row no-padd no-marg bg-gray">
                <button className="btn_close" style={{ textAlign: "left", background: "#212b60" }} onClick={() => setShowP(false)}><FontAwesomeIcon icon={['fas', 'chevron-right']} /></button>
            </div>

            <div className="row no-marg bg-wt pb-5">

                <div className="col-md-12">
                    <h4 className="padd-10">Changer mot de passe </h4>
                    {error && <p className="text-danger pl-4"><FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> {error}</p>}

                    {done && <div class="alert alert-success text-center" role="alert">
                                <FontAwesomeIcon icon={['fas', 'check-circle']} /> Changement de mot de passe avec succés
                            </div> }
                    <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="password" placeholder="Ancien Mot de passe"  {...register("last_password")} className={`form-control ${errors.last_password ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.last_password?.message}</p>
                            </div>


                        </div>
                        <div className="row">

                            <div className="col-md-6 form-group ">
                                <input type="password" placeholder="nouveau Mot de passe"  {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.password?.message}</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <input type="password" placeholder="Confirmez nouveau mot de passe"  {...register("confirmPassword")} className={`form-control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`} />
                                <p className="text-danger">{errors.confirmPassword?.message}</p>
                            </div>
                        </div>

                        <div className="col-md-12 txt-ctr" >
                            {loading ?
                                <div class="col-md-12 p-4 txt-ctr">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <button type="submit" className="btn btn-primary btn-login mt-4" >Confirmer</button>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default MdpUpdate;